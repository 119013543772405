import { Box, Button, CircularProgress, Grid, Skeleton, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material"
import { NavBar } from "../components/NavBar"
import { useParams } from "react-router-dom";
import { useState } from "react";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { InputChoice } from "../components/FormularioDinamico/InpultChoice";
import useFetchGetForm from "../queries/buscarForm";
import errorImg from "../assets/error.gif"
import { Carregando } from "../components/Carregando";
import useFormulario from "../hooks/useFormulario";
import { processObject } from "../utils/processObject";
import { deleteKeys } from "../utils/deleteKeys";

export const Formulario = () => {
    const { id } = useParams();
    const { data, isLoading, isError } = useFetchGetForm(id)

    const formEmpty = data?.map(grupo => grupo.campo_formulario.length > 0 && !grupo.campo_formulario.every(campo => campo.activated === false)).filter(item => item !== false).length === 0

    return isLoading ? <LoadingForm /> : isError ? <ErrorForm /> : formEmpty ? <FormEmpty /> : <ViewForm form_id={id} data={data} />
}

function LoadingForm() {
    return (
        <Box>
            <NavBar />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ mt: 3, px: 4 }}
                spacing={3}
            >
                <Grid item xs={2}>
                    <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                </Grid>

                <Grid item lg={12}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{ border: 1, borderColor: "#D8D8D8", mb: 4, minHeight: 500, pt: 4, bgcolor: "#fff", borderRadius: 1 }}
                    >
                        <CircularProgress />
                        <Typography sx={{ color: "#9e9e9e" }} variant="subtitle2">Carregando dados do formulário...</Typography>
                    </Stack>

                </Grid>

            </Grid>
        </Box>
    )
}

function ViewForm({ form_id, data }) {
    const [activeStep, setActiveStep] = useState(0);
    const [carregando, setCarregando] = useState(false);

    const {
        isLoading,
        saveEndereceAndReclamada,
        saveEndereceAndReclamante,
        getCamposObrigatorios,
        getAllCampos,
        getvalidationSchema,
        verificarErroDoPasso,
        getIsLocal,
        getSteps,
        sendForm
    } = useFormulario()

    const initialValues = getAllCampos(data)
    const camposObrigatorios = getCamposObrigatorios(data)
    const validationSchema = getvalidationSchema(data)

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object(validationSchema),
        onSubmit: async (values) => {
            try {
                const processValues = processObject(values)
                const formattedValues = deleteKeys(processValues, ['idEnderecoReclamada', 'idReclamante', 'idEmpresaReclamada', 'idEnderecoLocalTrabalho', 'idEnderecoReclamante'])
                sendForm(form_id, formattedValues, setCarregando)
            } catch (error) {
                console.log(error)
            }
        },
    });

    const isLocal = getIsLocal(formik, data)
    const steps = getSteps(isLocal)

    function handleBack() {
        setActiveStep(prevStep => prevStep - 1)
    }

    function handleSubmit() {
        console.log(formik.errors)
        formik.handleSubmit()
    }

    async function handleNext(nome) {
        if (nome === "DADOS_RECLAMANTE") {
            await saveEndereceAndReclamante(formik, setActiveStep)
        } else if (nome === "DADOS_RECLAMANDA") {
            await saveEndereceAndReclamada(formik, setActiveStep)
        } else {
            setActiveStep(prevStep => prevStep + 1)
        }

    }


    const dataFiltrada = isLocal.filter(item => {
        if (item.campo_formulario.length === 0) {
            return false;
        } else {
            const algumAtivado = item.campo_formulario.some(campo => campo.activated === true);
            return algumAtivado;
        }
    })


    return (
        <Box>
            <NavBar />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ mt: 3, px: 4 }}
                spacing={3}
            >
                <Grid item xs={2}>
                    <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
                        {data[0]?.campo_formulario[0]?.formulario.descricao || "Novo"}
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((rotulo, indice) => (
                            <Step key={rotulo}>
                                <StepLabel error={verificarErroDoPasso(formik.errors, formik.touched, camposObrigatorios[`error${indice}`] || [])}>
                                    {rotulo}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>

                {dataFiltrada.map((grupos, index) => {
                    const isFirstPage = index === 0;
                    const isLastPage = index === dataFiltrada.length - 1;

                    return (
                        index === activeStep && grupos.campo_formulario.length > 0 && (
                            <CardView
                                key={index}
                                handleNext={handleNext}
                                handleBack={handleBack}
                                handleSubmit={handleSubmit}
                                isFirstPage={isFirstPage}
                                isLastPage={isLastPage}
                                nome={grupos.nome}
                                isLoading={isLoading}
                            >
                                {grupos.campo_formulario.map((campos, indexCampos) => {
                                    const activated = campos.activated
                                    const value_depends = campos.campo.value_depends
                                    const depends_on = campos.campo.depends_on

                                    if (activated) {
                                        if (depends_on) {
                                            const value_depends_formatted = value_depends === 'true' ? true
                                                : value_depends === 'false' ? false
                                                    : value_depends;
                                            const showField = formik.values[depends_on] === value_depends_formatted
                                            if (showField) {
                                                return (
                                                    <Grid key={indexCampos} item lg={campos.tamanho_label > 12 ? 12 : campos.tamanho_label}>
                                                        <InputChoice formik={formik} campo={{ ...campos.campo, result_search: campos.result_search, nome_id: campos.nome_id }} />
                                                    </Grid>
                                                )
                                            }
                                        } else {
                                            return (
                                                <Grid key={indexCampos} item lg={campos.tamanho_label > 12 ? 12 : campos.tamanho_label}>
                                                    <InputChoice formik={formik} campo={{ ...campos.campo, result_search: campos.result_search, nome_id: campos.nome_id }} />
                                                </Grid>
                                            )
                                        }
                                    }
                                })}
                            </CardView>
                        )
                    )

                })}

            </Grid>
            <Carregando isLoading={Boolean(carregando)} />
        </Box>
    )
}

function CardView({ handleNext, handleBack, handleSubmit, children, isFirstPage, isLastPage, isLoading, nome }) {

    const showNextButton = !isLastPage;
    const showBackButton = !isFirstPage;
    const showhandleSubmitButton = isLastPage;

    function handleClickNext() {
        handleNext(nome)
    }

    return (
        <Grid item lg={12} xs={12} sm={12} md={12}>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                sx={{ border: 1, borderColor: "#D8D8D8", mb: 4, minHeight: 500, pt: 4, bgcolor: "#fff", borderRadius: 1 }}
            >
                <Grid item lg={10} xs={12} sm={12} md={12} sx={{ px: 4 }}>
                    <Grid container spacing={3} direction="row" alignItems="flex-end">
                        {children}
                    </Grid>
                </Grid>
                <Grid item lg={2}>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                        sx={{ p: 2 }}
                    >
                        {showBackButton && <Button onClick={handleBack} startIcon={<NavigateBeforeIcon />} disabled={isLoading} variant="outlined">Voltar</Button>}
                        {showNextButton && <Button onClick={handleClickNext} endIcon={<NavigateNextIcon />} disabled={isLoading} variant="contained">Próximo</Button>}
                        {showhandleSubmitButton && <Button onClick={handleSubmit} endIcon={<SaveAltIcon />} disabled={isLoading} variant="contained">Salvar</Button>}
                    </Stack>
                </Grid>
            </Grid>
            <Carregando isLoading={Boolean(isLoading)} />
        </Grid >
    )
}

function ErrorForm() {
    return (
        <Box>
            <NavBar />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ mt: 3, px: 4 }}
                spacing={3}
            >
                <Grid item xs={2}>
                    <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                </Grid>

                <Grid item lg={12}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ border: 1, borderColor: "#D8D8D8", mb: 4, minHeight: 500, pt: 4, bgcolor: "#fff", borderRadius: 1 }}
                    >
                        <img src={errorImg} alt="error" style={{ height: 180 }} />
                        <Box mt={-4}>
                            <Typography sx={{ color: "#f44336" }} variant="subtitle2" textAlign="center">Desculpe. Algo deu errado.<br />Tente novamente mais tarde.</Typography>
                        </Box>
                    </Stack>

                </Grid>

            </Grid>
        </Box>
    )
}

function FormEmpty() {
    return (
        <Box>
            <NavBar />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ mt: 3, px: 4 }}
                spacing={3}
            >
                <Grid item xs={2}>
                    <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                </Grid>

                <Grid item lg={12}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ border: 1, borderColor: "#D8D8D8", mb: 4, minHeight: 500, pt: 4, bgcolor: "#fff", borderRadius: 1 }}
                    >
                        <img src={errorImg} alt="error" style={{ height: 180 }} />
                        <Box mt={-4}>
                            <Typography sx={{ color: "#f44336" }} variant="subtitle2" textAlign="center">Este formulário está vazio.<br />Tente novamente mais tarde.</Typography>
                        </Box>
                    </Stack>

                </Grid>

            </Grid>
        </Box>
    )
}