import React, { useRef } from 'react';
import { Box, Typography, TextField, Tooltip, Select, MenuItem } from '@mui/material';

export const InputFormSelect = ({ id, label, type, maxLength, minLength = 0, formik, disabled = false, options }) => {
  const inputRef = useRef(null);

  const handleTypographyClick = () => {
    inputRef.current?.focus();
  };

  return (
    <Box>
      <Typography
        sx={{ fontWeight: 300, fontSize: 19, cursor: "default", color: Boolean(formik.touched[id] && formik.errors[id]) ? "#e74c3c" : "#000" }}
        onClick={handleTypographyClick}
        component="div"
        role="button"
        tabIndex={-1}
      >
        {label}
      </Typography>
      <Tooltip arrow title={(formik.touched[id] && formik.errors[id]) || (minLength && formik.values[id].length < minLength ? `Insira pelo menos ${minLength} caracteres.` : '')}>
        <Select
          fullWidth
          size='small'
          variant="outlined"
          id={id}
          name={id}
          type={type ? type : "text"}
          value={formik.values[id]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={Boolean(formik.touched[id] && formik.errors[id])}
          disabled={disabled}
          inputRef={inputRef}
        >
          {options && options.map((item, index) => (
            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </Tooltip>
    </Box>
  );
};
