import { Typography } from "@mui/material";

export const GroupTitle = ({ title }) => {
    return (
        <Typography
            sx={{ color: "#121212", fontWeight: 600, fontSize: 16, cursor: "default" }}>
            {title}
        </Typography>
    );
}
