import React, { useRef } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import api from '../service/api';
import NumberFormat from "react-number-format";
import Swal from 'sweetalert2';

export const InputFormSearchIcon = ({ id, label, formik, setIsLoading, disabled = false }) => {
  const inputRef = useRef(null);

  const handleTypographyClick = () => {
    inputRef.current?.focus();
  };

  const handleSearch = () => {
    searchApi();
  };

  const searchApi = async () => {
    try {
      setIsLoading(true);
      const endpoint = id === "cpfReclamante"
        ? `/api/clienteFindByCpf/${formik.values[id].replace(/[^0-9]/g, '')}`
        : `/api/enderecoFindByCep/${formik.values[id].replace(/[^0-9]/g, '')}`;

      const response = await api.get(endpoint);

      if (id === "cpfReclamante") {
        formCliente(response.data);
      } else {
        formEndereco(response.data);
      }

      if(!response.data) {
        Swal.fire({
          icon: 'warning',
          title: 'Aviso',
          text: "Registro não encontrado.",
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: 'warning',
        title: 'Aviso',
        text: "Registro não encontrado.",
      });
    }
  };

  function extrairData(dataISO) {
    const regex = /^(\d{4}-\d{2}-\d{2}).*/;
    const match = dataISO.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return null
  }

  const formCliente = (data) => {
    if (data) {
      const {
        nome,
        dataNascimento,
        email,
        estadoCivil,
        ddiCelular,
        dddCelular,
        telefoneCelular,
        numeroCasa,
        complementoEndereco,
        id: idReclamante,
        profissao,
        idProfissao,
        idEndereco,
        endereco
      } = data;

      formik.setFieldValue('nomeReclamante', nome || "");
      formik.setFieldValue('dataNascimento',  extrairData(dataNascimento) || "");
      formik.setFieldValue('emailReclamante', email || "");
      formik.setFieldValue('estadoCivilReclamante', estadoCivil || "");
      formik.setFieldValue('ddiCelular', ddiCelular || "");
      formik.setFieldValue('dddCelular', dddCelular || "");
      formik.setFieldValue('telefoneCelularReclamante', telefoneCelular || "");
      formik.setFieldValue('numeroCasaReclamante', numeroCasa || "");
      formik.setFieldValue('complementoEnderecoReclamante', complementoEndereco || "");
      formik.setFieldValue('idReclamante', idReclamante || "");
      if (endereco) {
        formik.setFieldValue('idEnderecoReclamante', idEndereco);
        formEndereco([endereco])
      }
      if (profissao) {
        formik.setFieldValue('idProfissao', idProfissao);
        formik.setFieldValue('profissao', profissao);
      }
      formik.setFieldValue('clienteEncontrado', true);
    }
  };

  const formEndereco = (data) => {
    if (data && data.length > 0) {
      const { estado, cidade, bairro, rua, id: idEnderecoReclamante, cep } = data[0];

      formik.setFieldValue('cepReclamante', cep || "");
      formik.setFieldValue('estadoReclamante', estado || "");
      formik.setFieldValue('cidadeReclamante', cidade || "");
      formik.setFieldValue('bairroReclamante', bairro || "");
      formik.setFieldValue('ruaReclamante', rua || "");
      formik.setFieldValue('idEnderecoReclamante', idEnderecoReclamante || "");
      formik.setFieldValue('enderecoEncontrado', true);
    }
  };

  const inputFormat = id === "cpfReclamante" ? "###.###.###-##" : "#####-###";
  const placeholder = id === "cpfReclamante" ? "Digite o CPF para pesquisar." : "Digite o CEP para pesquisar.";

  return (
    <Box>
      <Typography
        sx={{ fontWeight: 300, fontSize: 19, cursor: 'default' }}
        onClick={handleTypographyClick}
        component="div"
        role="button"
        tabIndex={-1}
      >
        {label}
      </Typography>
      <NumberFormat
        placeholder={placeholder}
        format={inputFormat}
        customInput={TextField}
        mask="_"
        disabled={disabled}
        fullWidth
        size="small"
        variant="outlined"
        id={id}
        name={id}
        inputRef={inputRef}
        value={formik.values[id]}
        onBlur={(e) => {
          formik.handleBlur(e);
          handleSearch();
        }}
        onChange={formik.handleChange}
        error={Boolean(formik.touched[id] && formik.errors[id])}
        helperText={formik.touched[id] && formik.errors[id]}
        InputProps={{
          endAdornment: disabled ? null : (
            <SearchIcon
              sx={{ cursor: 'pointer' }}
              onClick={handleSearch}
              color="primary"
            />
          ),
        }}
      />
    </Box>
  );
};
