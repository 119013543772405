import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { IconButton } from '@mui/material';
import useToken from '../providers/useToken';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import useUser from '../providers/useUser';


import { DWParceiro } from '../components/Drawers/DWParceiro';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function MenuNavBar({ navigate, anchorEl, handleOpenMenu, handleCloseMenu }) {
  const open = Boolean(anchorEl);
  const closeUser = useToken((state) => state.closeUser)
  const podeVisualizarComponente = useUser((state) => state.podeVisualizarComponente)
  const user = useToken((state) => state.user);
  const [viewData, setViewData] = React.useState(null);
  const [openEditar, setOpenEditar] = React.useState(false);

  function handleSair() {
    closeUser(navigate)
  }

  const toggleDrawer = (isOpen) => {
    setOpenEditar(isOpen);
  };

  const handleOpenEditar = () => {
    setOpenEditar(true)
    setViewData(user)
    handleCloseMenu()
  }

  return (
    <div>
      <IconButton
        size="small"
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleOpenMenu}
      >
        <ExpandMoreIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        {/* <MenuItem onClick={handleCloseMenu} disableRipple>
          <PersonIcon />
          Perfil
        </MenuItem> */}
        {podeVisualizarComponente(["advogado"]) &&
          <MenuItem onClick={handleOpenEditar} disableRipple>
            <ManageAccountsIcon />
            Editar
          </MenuItem>
        }
        {/* <Divider sx={{ my: 0.5 }} /> */}
        <MenuItem onClick={handleSair} disableRipple>
          <ExitToAppIcon />
          Sair
        </MenuItem>
      </StyledMenu>
      <DWParceiro open={openEditar} toggleDrawer={toggleDrawer} title={"Editar"} viewData={viewData} setViewData={setViewData} />

    </div>
  );
}
