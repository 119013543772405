import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function ControlledCheckbox({ id, label, formik, disabled = false }) {
    return (
        <FormControlLabel
            disabled={disabled}
            control={
                <Switch
                    id={id}
                    name={id}
                    onChange={formik.handleChange}
                    checked={formik.values[id]}
                />
            }
            label={label}
        />
    );
}
