import { Chip, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

export function TiposCampos({ tiposCampo = [] }) {
    return (
        <List
            dense
            sx={{
                borderRadius: '12px',
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                MaxHeight: 180,
                height: 180,
                '& ul': { padding: 0, margin: 0 },
            }}
        >
            {tiposCampo.map(tipo => (
                <ListItem key={tipo.id}>
                    <ListItemIcon>
                        <Chip size='small' sx={{ fontSize: 8, textTransform: 'uppercase', m: 0, p: 0, height: 15 }} label={tipo.tipo} />
                    </ListItemIcon>
                    <ListItemText sx={{
                        '& .MuiTypography-root': {
                            fontSize: 13,
                            fontWeight: 400,
                            textTransform: 'uppercase'
                        },
                    }} primary={tipo.nome} />
                </ListItem>
            ))}
        </List>
    )
}