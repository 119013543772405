import { useQuery } from "react-query";
import api from "../../service/api";

async function getParceiroById(ctx) {
  const [, id] = ctx.queryKey
  const { data } = await api.get(`/api/advogado/${id}`)
  return data
}

export default function useFetchGetParceiroById(id) {
    return useQuery(['peticaoById', id], getParceiroById, { refetchOnWindowFocus: false})
}