import * as React from "react";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  List,
  ListSubheader,
  Pagination,
  Skeleton,
  Stack,
} from "@mui/material";
import { TitlePage } from "../../TitlePage";
import usePlataforma from "../../../hooks/usePlataforma";
import { FormAddCep } from "../../FormAddCep";
import { ListEditDetalheEndAtendimento } from "../../ListEditDetalheEndAtendimento";
import useFetchGetConfigEnderecoFilial from "../../../queries/configEnderecoFilial";

export const EnderecoFilial = () => {
  const { deleteEnderecoFilial, addEnderecoFilial } = usePlataforma()
  const [page, setPage] = React.useState(1);
  
  const { data, isLoading } = useFetchGetConfigEnderecoFilial(page)

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <React.Fragment>
      {isLoading ? (
       <Box
       display="flex"
       justifyContent="center"
       alignItems="center"
       width="100%"
       height="100%"
     >
       <Skeleton variant="rounded" width="100%" height="100%" />
       <CircularProgress style={{ position: 'absolute' }} />
     </Box>
      ) : (
        <Card sx={{ height: "100%" }}>
          <TitlePage title="Endereço Filial" />
          <Box py={1}>
            <Divider />
          </Box>
          <FormAddCep funcaoCriar={addEnderecoFilial} />
          <List
            sx={{ width: "100%", bgcolor: "#bcd7f330" }}
            subheader={<ListSubheader>Opções</ListSubheader>}
          >
            {data && data.data.map((current, index, arr) => (
              <ListEditDetalheEndAtendimento
                key={current.id}
                id={current.id}
                title={`${current.endereco.cidade} - ${current.endereco.estado}`}
                borderBottom={index === arr.length - 1 ? 0 : 1}
                funcaoExcluir={deleteEnderecoFilial}
              />
            ))}
          </List>
          <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        my={2}
      >
        {!isLoading && data && data.totalPages > 1 && <Pagination color="primary" count={data.totalPages} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} />}
      </Stack>
        </Card>
      )}
    </React.Fragment>
  );
};
