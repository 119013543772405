import * as React from "react";
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Pagination,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";
import { TitlePage } from "../../TitlePage";
import usePlataforma from "../../../hooks/usePlataforma";
import useFetchGetConfigEnderecoFilial from "../../../queries/configEnderecoFilial";
import useFetchGetStaugetSatusPeticao from "../../../queries/getSatusPeticao";
import { FormAddCor } from "../../FormAddCor";
import { ListEditDetalheStatusPeticao } from "../../ListEditDetalheStatusPeticao";

export const StatusPeticao = () => {
  const { addStatusColor } = usePlataforma()
  const [page, setPage] = React.useState(1);

  const { data, isLoading } = useFetchGetStaugetSatusPeticao(page, 20)


  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Skeleton variant="rounded" width="100%" height="100%" />
          <CircularProgress style={{ position: 'absolute' }} />
        </Box>
      ) : (
        <Card sx={{ height: "100%" }}>
          <TitlePage title="Status" />
          <Box py={1}>
            <Divider />
          </Box>
          <FormAddCor funcaoCriar={addStatusColor} />
          <ListStatus dataStatusPeticao={data} />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            my={2}
          >
            {!isLoading && data && data.totalPages > 1 && <Pagination color="primary" count={data.totalPages} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} />}
          </Stack>
        </Card>
      )}
    </React.Fragment>
  );
};


function ListStatus({ dataStatusPeticao }) {
  const { deleteStatusPeticao, atualizarStatusPeticao } = usePlataforma()

  return (
    <List
      sx={{ width: "100%", bgcolor: "#cbd9e630" }}
      subheader={<ListSubheader>Opções</ListSubheader>}
    >
      {dataStatusPeticao && dataStatusPeticao.data.map((current, index, arr) => (
        <ListEditDetalheStatusPeticao
          key={current.id}
          id={current.id}
          title={current.nome}
          borderBottom={index === arr.length - 1 ? 0 : 1}
          color={current.color}
          funcaoExcluir={deleteStatusPeticao}
          funcaoAtualizar={atualizarStatusPeticao}
        />
      ))}
    </List>
  )
}
