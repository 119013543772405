import * as React from "react";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  List,
  ListSubheader,
  Pagination,
  Skeleton,
  Stack,
} from "@mui/material";
import { TitlePage } from "../../TitlePage";
import { FormAddOpcao } from "../../FormAddOpcao";
import usePlataforma from "../../../hooks/usePlataforma";
import useFetchGetConfigGrupoPermissoes from "../../../queries/configGrupoPermissoes";
import { ModalPermissaoGrupo } from "../ModalPermissaoGrupo";
import { ListEditDetalheGrupoPermissao } from "../../ListEditDetalheGrupoPermissao";

export const GrupoPermissoes = () => {
  const { excluirGrupoPermissoes, criarGrupoPermissoes, atualizarGrupoPermissoes, addPermissaoGrupo, deletePermissaoGrupo } = usePlataforma()
  const [page, setPage] = React.useState(1);
  const [modalPermissao, setModalPermissao] = React.useState(false);
  const [userIDPermissao, setUserIDPermissao] = React.useState(null);

  const { data, isLoading } = useFetchGetConfigGrupoPermissoes(page)

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  function handleFecharModalPermissao() {
    setModalPermissao(false)
    setUserIDPermissao(null)
  }

  function handleAbrirModalPermissao(id) {
    setModalPermissao(true)
    setUserIDPermissao(id)
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Skeleton variant="rounded" width="100%" height="100%" />
        <CircularProgress style={{ position: 'absolute' }} />
      </Box>
      ) : (
        <Card sx={{ height: "100%" }}>
          <TitlePage title="Grupos de permissões" />
          <Box py={1}>
            <Divider />
          </Box>
          <FormAddOpcao funcaoCriar={criarGrupoPermissoes} />
          <List
            sx={{ width: "100%", bgcolor: "#bcd7f330" }}
            subheader={<ListSubheader>Lista</ListSubheader>}
          >
            {data && data.data.map((current, index, arr) => (
              <ListEditDetalheGrupoPermissao
                key={current.id}
                id={current.id}
                title={current.nome_grupo}
                borderBottom={index === arr.length - 1 ? 0 : 1}
                funcaoExcluir={excluirGrupoPermissoes}
                funcaoAtualizar={atualizarGrupoPermissoes}
                handleAbrirModalPermissao={handleAbrirModalPermissao}
              />
            ))}
          </List>
          <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        my={2}
      >
        {!isLoading && data && data.totalPages > 1 && <Pagination color="primary" count={data.totalPages} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} />}
      </Stack>
        </Card>
      )}

    {userIDPermissao && (
     <ModalPermissaoGrupo
     grupoID={userIDPermissao}
     open={modalPermissao}
     funcaoHandleClose={handleFecharModalPermissao} 
     addPermissaoUser={addPermissaoGrupo}
     deletePermissaoUser={deletePermissaoGrupo}
     /> 
    )}

    </React.Fragment>
  );
};
