import { Button, Grid, CardContent, Card, Stack, Typography, Box, Divider } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { InputForm } from '../InputForm';
import { InputFormSearchIcon } from '../InputFormSearchIcon';
import usePlataforma from '../../hooks/usePlataforma';
import InpultProfissao from '../InpultProfissao';
import { Carregando } from '../Carregando';
import { useState } from 'react';
import Swal from 'sweetalert2';

const inputs = [
    { label: 'CPF', name: 'cpfReclamante', tamanho: 3, search: true, type: "text", maxLength: 11 },
    { label: 'Nome completo', name: 'nomeReclamante', tamanho: 4, search: false, type: "text" },
    { label: 'Data de Nascimento', name: 'dataNascimento', tamanho: 2, search: false, type: "date" },
    { label: 'Email', name: 'emailReclamante', tamanho: 3, search: false, type: "email" },
    { label: 'Estado civil', name: 'estadoCivilReclamante', tamanho: 3, search: false, type: "text" },
    { label: 'DDI', name: 'ddiCelular', tamanho: 1, search: false, type: "text", maxLength: 2 },
    { label: 'DDD', name: 'dddCelular', tamanho: 1, search: false, type: "text", maxLength: 2 },
    { label: 'Telefone', name: 'telefoneCelularReclamante', tamanho: 4, search: false, type: "text", maxLength: 9 },
    { label: 'CEP', name: 'cepReclamante', tamanho: 3, search: true, type: "text", maxLength: 8 },
    { label: 'Rua', name: 'ruaReclamante', tamanho: 3, search: false, type: "text" },
    { label: 'Número', name: 'numeroCasaReclamante', tamanho: 1, search: false, type: "text" },
    { label: 'Bairro', name: 'bairroReclamante', tamanho: 2, search: false, type: "text" },
    { label: 'Complemento', name: 'complementoEnderecoReclamante', tamanho: 4, search: false, type: "text" },
    { label: 'Cidade', name: 'cidadeReclamante', tamanho: 3, search: false, type: "text" },
    { label: 'Estado', name: 'estadoReclamante', tamanho: 3, search: false, type: "text" },
]


export const DadosDoReclamante = ({ handleNext, formik, viewData }) => {
    const { addCliente, addEnderecoCliente, verificarIsLocal } = usePlataforma()
    const [isLoading, setIsLoading] = useState(false);


    let clienteEncontrado = formik.values.clienteEncontrado
    let enderecoEncontrado = formik.values.enderecoEncontrado

    async function saveEndereceAndCliente() {
        setIsLoading(true);
        formik.setFieldTouched("cpfReclamante", true, true)
        formik.setFieldTouched("cepReclamante", true, true)

        if (viewData) {
            setIsLoading(false);
            handleNext();
            return
        }

        try {
            var idEnderecoReclamante = formik.values.idEnderecoReclamante

            if (!enderecoEncontrado) {
                const responseEndereco = await addEnderecoCliente(formik.values);
                idEnderecoReclamante = responseEndereco.id
                formik.setFieldValue('idEnderecoReclamante', responseEndereco.id);
                formik.setFieldValue('enderecoEncontrado', true);
            }

            if (!idEnderecoReclamante) {
                Swal.fire({
                    icon: 'error',
                    title: 'Atenção',
                    text: 'Tente preencher as informações corretamente.',
                });
            } else {

                if (!clienteEncontrado) {
                    const responseCliente = await addCliente(formik.values, idEnderecoReclamante);
                    formik.setFieldValue('idReclamante', responseCliente.id);
                    formik.setFieldValue('clienteEncontrado', true);
                }

                const isLocal = await verificarIsLocal(formik.values.cepReclamante)
                formik.setFieldValue('isLocal', isLocal);
                if(isLocal) {
                    formik.setFieldValue('steps', ['Dados do reclamante', 'Dados da reclamada', 'Resumo']);
                }

                handleNext()
            }

        } catch (error) {
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: 'Tente preencher as informações corretamente.',
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Grid item lg={12}>
            <Card sx={{ border: 1, borderColor: "#D8D8D8", mb: 4 }}>
                <CardContent >
                    <Grid container spacing={3} >

                        {inputs.map((current, i) => (
                            <Grid key={i} item lg={current.tamanho}>
                                {current.search ? <InputFormSearchIcon disabled={viewData} setIsLoading={setIsLoading} id={current.name} label={current.label} maxLength={current.maxLength && current.maxLength} formik={formik} /> : <InputForm disabled={viewData} id={current.name} label={current.label} type={current.type} maxLength={current.maxLength && current.maxLength} formik={formik} />}
                            </Grid>
                        ))}

                        <Grid item lg={3}>
                            <InpultProfissao formik={formik} label="Profissão" disabled={viewData} />
                        </Grid>

                    </Grid>
                </CardContent>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    sx={{ p: 2 }}
                >
                    <Button onClick={viewData ? handleNext : saveEndereceAndCliente} endIcon={<NavigateNextIcon />} variant="contained">Próximo</Button>
                </Stack>
                <Carregando isLoading={isLoading} />
            </Card>
        </Grid>
    );
}
