import { Box, Button, Grid, CardContent, Card, Stack, Typography, Divider } from '@mui/material';
import { useState } from 'react';
import { Carregando } from '../Carregando';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { InputFormMultiline } from '../InputFormMultiline';
import { InputForm } from '../InputForm';
import usePlataforma from '../../hooks/usePlataforma';

export const DadosResumo = ({ formik, handleBack, viewData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { addPeticao } = usePlataforma()
    async function handleClickNext() {
        setIsLoading(true);
        formik.setFieldTouched("horarioAtendimentoPreferencia", true, true)
        try {
            if (formik.values.horarioAtendimentoPreferencia) {
                await addPeticao(formik.values)
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <Grid item lg={12}>
            <Card sx={{ border: 1, borderColor: "#D8D8D8", mb: 3 }}>
                <CardContent>
                    <Grid container spacing={3} >
                        <Grid item lg={12}>
                            <Typography sx={{ fontWeight: 500, fontSize: 19 }}>
                                Descrição dos fatos
                            </Typography>
                            <Box my={1}><Divider /></Box>
                        </Grid>
                        <Grid item lg={12}>
                            <InputFormMultiline disabled={viewData} id="breveDescricaoFatos" label="Resumo" formik={formik} />
                        </Grid>
                        <Grid item lg={3}>
                            <InputForm disabled={viewData} id="horarioAtendimentoPreferencia" label="Melhor horário para atendimento" formik={formik} type="time" />
                        </Grid>
                    </Grid>
                </CardContent>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    sx={{ p: 2 }}
                >
                    <Button onClick={handleBack} startIcon={<NavigateBeforeIcon />} variant="outlined">Voltar</Button>
                    {!viewData && <Button onClick={handleClickNext} endIcon={<SaveAltIcon />} variant="contained">Salvar</Button>}
                </Stack>
                <Carregando isLoading={isLoading} />
            </Card>
        </Grid>
    );
}
