import ControlledCheckbox from "./CheckboxForm";
import { InputSearchCep } from "./InputSearchCep";
import { InputForm } from "./InputForm";
import { InputFormCPF } from "./InputFormCPF";
import { InputFormSearchIconCNPJ } from "./InputFormSearchIconCNPJ";
import InpultAdvogado from "./InpultAdvogado";
import { TitleHeader } from "./TitleHeader";
import { InputFormMultiline } from "./InputFormMultiline";
import InpultInsalubridade from "./InpultInsalubridade";
import InpultEscalaTrabalho from "./InpultEscalaTrabalho";
import { InputFormSearchCPF } from "./InputFormSearchCPF";
import InputProfissao from "./InpultProfissao";
import { InputFormSelect } from "./InputFormSelect";
import { Box, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import React from "react";
import { InputFormFile } from "./InputFormFile";
import { AlertInfo } from "./AlertInfo";
import { AlertWarning } from "./AlertWarning";
import { AlertError } from "./AlertError";

export function InputChoice({ formik, campo, disabled = false, handleOpenEdit, showEditIcon = true }) {
    const { tipo_campo } = campo;
    let FieldTypeComponent = InputForm;

    switch (true) {
        case tipo_campo.nome.toUpperCase().includes("ALERTA_INFO"):
            FieldTypeComponent = AlertInfo;
            break;
        case tipo_campo.nome.toUpperCase().includes("ALERTA_ERROR"):
            FieldTypeComponent = AlertError;
            break;
        case tipo_campo.nome.toUpperCase().includes("ALERTA_WARNING"):
            FieldTypeComponent = AlertWarning;
            break;
        case tipo_campo.nome.toUpperCase().includes('FILE'):
            FieldTypeComponent = InputFormFile;
            break;
        case tipo_campo.nome.toUpperCase().includes('CPF') && campo.search:
            FieldTypeComponent = InputFormSearchCPF;
            break;
        case tipo_campo.nome.toUpperCase().includes('CPF'):
            FieldTypeComponent = InputFormCPF;
            break;
        case tipo_campo.nome.toUpperCase().includes('CNPJ'):
            FieldTypeComponent = InputFormSearchIconCNPJ;
            break;
        case tipo_campo.nome.toUpperCase().includes('ADVOGADO'):
            FieldTypeComponent = InpultAdvogado;
            break;
        case tipo_campo.nome.toUpperCase().includes('PROFISSAO'):
            FieldTypeComponent = InputProfissao;
            break;
        case tipo_campo.nome.toUpperCase().includes('ESCALATRABALHO'):
            FieldTypeComponent = InpultEscalaTrabalho;
            break;
        case tipo_campo.nome.toUpperCase().includes('INSALUBRIDADE'):
            FieldTypeComponent = InpultInsalubridade;
            break;
        case tipo_campo.nome.toUpperCase().includes('TITLE'):
            FieldTypeComponent = TitleHeader;
            break;
        case tipo_campo.nome.toUpperCase().includes('MULTILINE'):
            FieldTypeComponent = InputFormMultiline;
            break;
        case tipo_campo.nome.toUpperCase().includes('TIME'):
            FieldTypeComponent = InputForm;
            break;
        case tipo_campo.nome.toUpperCase().includes('CEP'):
            const search = campo.search;
            FieldTypeComponent = search ? InputSearchCep : InputForm;
            break;
        case tipo_campo.nome.toUpperCase().includes('EMAIL'):
            FieldTypeComponent = InputForm
            break;
        case tipo_campo.nome.toUpperCase().includes('SELECT'):
            FieldTypeComponent = InputFormSelect
            break;
        case tipo_campo.tipo.includes('checkbox'):
            FieldTypeComponent = ControlledCheckbox;
            break;
        default:
            FieldTypeComponent = InputForm;
    }

    if (handleOpenEdit) {
        return (
            <React.Fragment>
                {showEditIcon ? (
                    <Box sx={{
                        position: 'relative',
                        transition: 'transform 0.3s ease, background-color 0.3s ease',
                        '&:hover': {
                            transform: 'scale(0.98)'
                        }
                    }}>
                        <IconButton
                            aria-label="editar"
                            sx={{ position: 'absolute', top: 0, right: 0, bottom: 40 }}
                            onClick={handleOpenEdit}
                        >
                            <EditIcon sx={{ fontSize: 15 }} />
                        </IconButton>
                        <FieldTypeComponent
                            id={campo.nome_id}
                            label={campo.nome}
                            type={tipo_campo.tipo}
                            maxLength={campo.qtd_max_caracter}
                            minLength={campo.qtd_min_caracter}
                            formik={formik}
                            result_search={campo.result_search}
                            disabled={disabled}
                            options={campo.options}
                        />
                    </Box>
                ) : (
                    <FieldTypeComponent
                        id={campo.nome_id}
                        label={campo.nome}
                        type={tipo_campo.tipo}
                        maxLength={campo.qtd_max_caracter}
                        minLength={campo.qtd_min_caracter}
                        formik={formik}
                        result_search={campo.result_search}
                        disabled={disabled}
                        options={campo.options}
                    />
                )}
            </React.Fragment>
        )
    } else {
        return (
            <FieldTypeComponent
                id={campo.nome_id}
                label={campo.nome}
                type={tipo_campo.tipo}
                maxLength={campo.qtd_max_caracter}
                minLength={campo.qtd_min_caracter}
                formik={formik}
                result_search={campo.result_search}
                disabled={disabled}
                options={campo.options}
            />
        )
    }
}
