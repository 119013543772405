import * as React from "react";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  List,
  ListSubheader,
  Pagination,
  Skeleton,
  Stack,
} from "@mui/material";
import { TitlePage } from "../../TitlePage";
import { ListEditDetalhe } from "../../ListEditDetalhe";
import { FormAddOpcao } from "../../FormAddOpcao";
import usePlataforma from "../../../hooks/usePlataforma";
import useFetchGetConfigEscalaDeTrabalho from "../../../queries/configEscalaDeTrabalho";

export const EscalaDeTrabalho = () => {
  const { excluirEscalaDeTrabalho, criarEscalaDeTrabalho, atualizarEscalaDeTrabalho } = usePlataforma()
  const [page, setPage] = React.useState(1);
  
  const { data, isLoading } = useFetchGetConfigEscalaDeTrabalho(page)

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <React.Fragment>
      {isLoading ? (
       <Box
       display="flex"
       justifyContent="center"
       alignItems="center"
       width="100%"
       height="100%"
     >
       <Skeleton variant="rounded" width="100%" height="100%" />
       <CircularProgress style={{ position: 'absolute' }} />
     </Box>
      ) : (
        <Card sx={{ height: "100%" }}>
          <TitlePage title="Escala de trabalho" />
          <Box py={1}>
            <Divider />
          </Box>
          <FormAddOpcao funcaoCriar={criarEscalaDeTrabalho} />
          <List
            sx={{ width: "100%", bgcolor: "#bcd7f330" }}
            subheader={<ListSubheader>Opções</ListSubheader>}
          >
            {data && data.data.map((current, index, arr) => (
              <ListEditDetalhe
                key={current.id}
                id={current.id}
                title={current.nomeEscala}
                borderBottom={index === arr.length - 1 ? 0 : 1}
                funcaoExcluir={excluirEscalaDeTrabalho}
                funcaoAtualizar={atualizarEscalaDeTrabalho}
              />
            ))}
          </List>
          <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        my={2}
      >
        {!isLoading && data && data.totalPages > 1 && <Pagination color="primary" count={data.totalPages} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} />}
      </Stack>
        </Card>
      )}
    </React.Fragment>
  );
};
