import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Alert, Backdrop, Breadcrumbs, CircularProgress, Grid, LinearProgress } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import api from '../../service/api';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { InputChoice } from '../FormularioDinamico/InpultChoice';
import useFormulario from '../../hooks/useFormulario';
import usePlataforma from '../../hooks/usePlataforma';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogRespostaEdit({ handleClose, open = false, inpultData, id_resposta, form_id }) {
  const { editarResposta } = usePlataforma()
  const [carregando, setCarregando] = React.useState(false);
  const { campo, grupo, value } = inpultData
  const { getvalidationSchema } = useFormulario()
  const nomeInpult = campo.campo.nome
  const grupoInpult = grupo.nome_label

  const validationSchema = getvalidationSchema([{ campo_formulario: [campo] }])

  const formik = useFormik({
    initialValues: {
      [campo.nome_id]: value
    },
    validationSchema: Yup.object(validationSchema),
    onSubmit: async (values) => {
      try {
        setCarregando(true)
        const resposta = typeof values[campo.nome_id] === 'object' && values[campo.nome_id] !== null && 'id' in values[campo.nome_id] ? values[campo.nome_id].id : values[campo.nome_id];
        await editarResposta({ id_resposta, campo_formulario: campo.nome_id, resposta: resposta.toString(), form_id })
        setCarregando(false)
        handleClose()
      } catch (error) {
        setCarregando(false)
        console.log(error)
        handleClose()
      }
    },
  });

  function handleAlterar() {
    if (formik.dirty) {
      formik.handleSubmit()
      return
    }
    handleClose()
  }

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {carregando && <LinearProgress />}
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Typography key="1" color="text.primary">
            {grupoInpult}
          </Typography>
          <Typography key="2" color="text.primary">
            {nomeInpult}
          </Typography>
        </Breadcrumbs>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container spacing={2} sx={{ maxWidth: 450, py: 1.5 }}>
          <Grid item xs={12}>
            <Alert severity="info">
              <Typography sx={{ fontSize: 12 }}>
                Você está prestes a modificar uma informação na resposta. Se estiver seguro dessa alteração, edite o campo e clique em 'alterar'.
              </Typography>
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <InputChoice showEditIcon={false} handleOpenEdit={console.log} disabled={false} formik={formik} campo={{ ...campo.campo, result_search: campo.result_search, nome_id: campo.nome_id }} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" disabled={carregando} color='error' onClick={handleClose}>
          Cancelar
        </Button>
        <Button size="small" disabled={carregando} autoFocus variant='contained' onClick={handleAlterar}>
          Alterar
        </Button>
      </DialogActions>
      <Backdrop
        open={carregando}
        sx={{
          color: 'common.white',
          zIndex: theme => theme.zIndex.mobileStepper - 1
        }}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </BootstrapDialog>
  )
}
