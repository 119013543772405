import React, { useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, Typography, Grid } from '@mui/material';
import { DadosDoReclamante } from './FormPeticao/DadosDoReclamante';
import { DadosDaReclamada } from './FormPeticao/DadosDaReclamada';
import { ContratoDeTrabalho } from './FormPeticao/ContratoDeTrabalho';
import { JornadaDeTrabalho } from './FormPeticao/JornadaDeTrabalho';
import { DadosFinais } from './FormPeticao/DadosFinais';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import usePlataforma from '../hooks/usePlataforma';
import { ValuesView } from '../utils/ValuesView';
import { OpcoesViewPeticao } from './OpcoesViewPeticao';
import { DadosResumo } from './FormPeticao/DadosResumo';


const ViewPeticao = ({ viewData, type, user }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { addPeticao } = usePlataforma()

    
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const formik = useFormik({
        initialValues: {
            steps: ['Dados do reclamante', 'Dados da reclamada', 'Contrato de trabalho', 'Jornada de trabalho', 'Dados finais'],
            isLocal: true,
            enderecoIsLocalTrabalho: true,
            informacoesAdicionaisFinais: "", 
            informacoesAdicionaisJornada: "", 
            informacoesAdicionaisTrabalho: "",
            breveDescricaoFatos: "",
            horarioAtendimentoPreferencia: "",
            enderecoEncontradoLocalTrabalho: false,
            idEnderecoLocalTrabalho: null,
            complementoEnderecoLocalTrabalho: "",
            numeroCasaEnderecoLocalTrabalho: "",
            cepEnderecoLocalTrabalho: "",
            ruaEnderecoLocalTrabalho: "",
            bairroEnderecoLocalTrabalho: "",
            cidadeEnderecoLocalTrabalho: "",
            estadoEnderecoLocalTrabalho: "",
            enderecoLocalTrabalho: null,
            enderecoEncontrado: false,
            clienteEncontrado: false,
            enderecoCNPJEncontrado: false,
            cnpjEncontrado: false,
            idReclamante: "",
            idAdvogado: "",
            advogado: null,
            idInsalubridade: "",
            idEmpresaReclamada: "",
            idEnderecoReclamante: "",
            idEnderecoReclamada: "",
            cpfReclamante: "",
            nomeReclamante: "",
            emailReclamante: "",
            emailReclamada: "",
            dataNascimento: "",
            estadoCivilReclamante: "",
            idProfissao: "",
            profissao: null,
            ddiCelular: "",
            dddCelular: "",
            telefoneCelularReclamante: "",
            telefoneCelularReclamada: "",
            cepReclamante: "",
            ruaReclamante: "",
            numeroCasaReclamante: "",
            bairroReclamante: "",
            complementoEnderecoReclamante: "",
            cidadeReclamante: "",
            estadoReclamante: "",
            cnpjReclamada: "",
            razaoSocialReclamada: "",
            cepReclamada: "",
            ruaReclamada: "",
            numeroCasaReclamada: "",
            complementoEnderecoReclamada: "",
            bairroReclamada: "",
            cidadeReclamada: "",
            estadoReclamada: "",
            dataContratacao: "",
            dataDispensa: "",
            funcao: "",
            salarioInicial: "",
            salarioFinal: "",
            motivoRescisao: "",
            assinaturaCarteira: false,
            desvioFuncao: false,
            funcaoDiversa: false,
            recebiaValoresExtra: false,
            contratacaoAssinaturaDivergente: false,
            horaEntrada: "",
            horaSaida: "",
            idEscalaTrabalho: "",
            intervaloDescansoAlmoco: false,
            tempoIntervalo: "",
            trabalhoDomingoFolga: false,
            frequenciaTrabalhoDomingo: "",
            trabalhoFeriado: false,
            frequenciaTrabalhoFeriado: "",
            trabalhoNoturno: false,
            mediaHorasTrabalhoNoturno: "",
            mediaDiasTrabalhoNoturno: "",
            trabalhoInsalubre: false,
            recebeuEpi: false,
            epiRecebido: "",
            agenteInsalubre: "",
            trabalhoPericulosidade: false,
            recebePericulosidade: false,
            agentesPericulosos: "",
            feriasPeriodoIncorreto: false,
            dataFeriasPeriodoCorreto: "",
            dataFeriasPeriodoIncorreto: "",
            feriasPagamento: false,
            feriasInicio: "",
            dataFeriasPagamento: "",
            trabalhoDuranteFerias: false,
            trabalhoDuranteFeriasQuantidade: "",
            acidenteTrabalho: false,
            emissaoCat: false,
            afastamentoBeneficiario: false,
            danoEstetico: false,
            doencaOcupacional: false,
            doencaOcupacionalAfastamento: false,
            assedioMoral: false,
            nomeAssediador: "",
            descricaoFatos: "",
            assedioSexual: false,
            nomeAssediadorSexual: "",
            descricaoFatosAssedioSexual: "",
            dispensaDiscriminatoria: false,
            pagamentoRescisao: false,
            dataPagamentoRescisao: "",
            entregaDocumentos: false,
            dataEntregaDocumentos: "",
            dataAssinatura: "",
            escalaTrabalho: null,
            insalubridade: null
        },
        validationSchema: Yup.object({
            cpfReclamante: Yup.string().required('Este campo é obrigatório.'),
            nomeReclamante: Yup.string().required('Este campo é obrigatório.'),
            dataNascimento: Yup.string().required('Este campo é obrigatório.'),
            emailReclamante: Yup.string().required('Este campo é obrigatório.'),
            estadoCivilReclamante: Yup.string().required('Este campo é obrigatório.'),
            telefoneCelularReclamante: Yup.string().required('Este campo é obrigatório.'),
            cepReclamante: Yup.string().required('Este campo é obrigatório.'),
            ruaReclamante: Yup.string().required('Este campo é obrigatório.'),
            numeroCasaReclamante: Yup.string().required('Este campo é obrigatório.'),
            bairroReclamante: Yup.string().required('Este campo é obrigatório.'),
            cidadeReclamante: Yup.string().required('Este campo é obrigatório.'),
            estadoReclamante: Yup.string().required('Este campo é obrigatório.'),
            cnpjReclamada: Yup.string().required('Este campo é obrigatório.'),
            razaoSocialReclamada: Yup.string().required('Este campo é obrigatório.'),
            emailReclamada: Yup.string().required('Este campo é obrigatório.'),
            telefoneCelularReclamada: Yup.string().required('Este campo é obrigatório.'),
            cepReclamada: Yup.string().required('Este campo é obrigatório.'),
            ruaReclamada: Yup.string().required('Este campo é obrigatório.'),
            numeroCasaReclamada: Yup.string().required('Este campo é obrigatório.'),
            bairroReclamada: Yup.string().required('Este campo é obrigatório.'),
            cidadeReclamada: Yup.string().required('Este campo é obrigatório.'),
            estadoReclamada: Yup.string().required('Este campo é obrigatório.'),
            dataContratacao: Yup.string().required('Este campo é obrigatório.'),
            dataDispensa: Yup.string().required('Este campo é obrigatório.'),
            funcao: Yup.string().required('Este campo é obrigatório.'),
            salarioInicial: Yup.string().required('Este campo é obrigatório.'),
            salarioFinal: Yup.string().required('Este campo é obrigatório.'),
            motivoRescisao: Yup.string().required('Este campo é obrigatório.'),
            horaEntrada: Yup.string().required('Este campo é obrigatório.'),
            horaSaida: Yup.string().required('Este campo é obrigatório.'),
        }),
        onSubmit: async (values) => {
            try {
                setIsLoading(true)
                await addPeticao(values)
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
            }
        },
    });

    useEffect(() => {
        if (viewData) {
            var formatData = ValuesView(viewData)
            formik.setValues(formatData);
        }
    }, [viewData]);

    useEffect(() => {
        if(type !== "user") {
            formik.setFieldValue('idAdvogado', user.id);
        }
    }, [user, type]);


    function verificarErro(errosObj, tocados, campo) {
        return tocados[campo] && errosObj[campo];
    }
    
    function verificarErroDoPasso(erros, tocados, camposObrigatorios) {
        return camposObrigatorios.some(campo => verificarErro(erros, tocados, campo));
    }
    
    const camposObrigatorios = {
        error0: ["cpfReclamante", "nomeReclamante", "dataNascimento", "emailReclamante", "estadoCivilReclamante", "telefoneCelularReclamante", "cepReclamante", "ruaReclamante", "numeroCasaReclamante", "bairroReclamante", "cidadeReclamante", "estadoReclamante"],
        error1: ["cnpjReclamada", "razaoSocialReclamada", "emailReclamada", "telefoneCelularReclamada", "cepReclamada", "ruaReclamada", "numeroCasaReclamada", "bairroReclamada", "cidadeReclamada", "estadoReclamada"],
        error2: ["dataContratacao", "dataDispensa", "funcao", "salarioInicial", "salarioFinal", "motivoRescisao"],
        error3: ["horaEntrada", "horaSaida"]
    };

    const isLocal = formik.values.isLocal

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ mt: 3, px: 4 }}
            spacing={3}
        >
            {viewData && <Grid item xs={12}>
            <OpcoesViewPeticao idPeticao={viewData.id} viewData={viewData} />
            </Grid>}
            <Grid item xs={2}>
                <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
                    Novo cadastro
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {formik.values.steps.map((rotulo, indice) => (
                        <Step key={rotulo}>
                            <StepLabel error={verificarErroDoPasso(formik.errors, formik.touched, camposObrigatorios[`error${indice}`] || [])}>
                                {rotulo}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            {activeStep === 0 && <DadosDoReclamante viewData={viewData ? true : false} formik={formik} handleNext={handleNext} />}
            {activeStep === 1 && <DadosDaReclamada viewData={viewData ? true : false} formik={formik} handleNext={handleNext} handleBack={handleBack}/>}
            {activeStep === 2 && !isLocal && <ContratoDeTrabalho viewData={viewData ? true : false} handleNext={handleNext} formik={formik} handleBack={handleBack}/>}
            {activeStep === 3 && <JornadaDeTrabalho viewData={viewData ? true : false} handleNext={handleNext} formik={formik} handleBack={handleBack}/>}
            {activeStep === 4 && <DadosFinais type={type} viewData={viewData ? true : false} isLoading={isLoading} formik={formik} handleBack={handleBack} />}
            {activeStep === 2 && isLocal && <DadosResumo viewData={viewData ? true : false} formik={formik} handleNext={handleNext} handleBack={handleBack}/>}
        </Grid >
    );
};

export default ViewPeticao;
