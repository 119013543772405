import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import useToken from '../providers/useToken';
import { useQueryClient } from 'react-query';
import usePlataforma from '../hooks/usePlataforma';
import { baseURL } from './constants';
const { Dragger } = Upload;


export default function UploadArquivo({ idPeticao }) {
    const access_token = useToken((state) => state.access_token);
    const queryClient = useQueryClient();
    const { deleteStorage } = usePlataforma()
    
    const props = {
        name: 'file',
        multiple: true,
        action: `${baseURL}/api/storage/upload?idPeticao=${idPeticao}`,
        headers: {
            Authorization: `Bearer ${access_token}`
        },
        onChange(info) {
            const { status } = info.file;
            if(status === 'error'){
                message.error(`O envio do arquivo ${info.file.name} falhou.`);
            } else if (status === 'done') {
                queryClient.invalidateQueries(['peticaoById']);
                message.success(`${info.file.name} enviado com sucesso.`);
            }
        },
        onRemove(e) {
            const idStorage = e.response.id ?? null;
            if(idStorage){
                deleteStorage(idStorage)
            }
        }
    };

    return (
        <Dragger {...props}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer upload</p>
            <p className="ant-upload-hint">
                Faça o upload de múltiplos arquivos, permitindo 50 MB por arquivo.
            </p>
        </Dragger>
    );
}