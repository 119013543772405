import { useQuery } from "react-query";
import api from "../../service/api";

async function getClienteConfigs(ctx) {
  const [, shortId] = ctx.queryKey
  const { data } = await api.get('/api/advogado/configs', { params: { shortId } })
  return data
}

export default function useFetchGetAdvogado(shortId) {
    return useQuery(['configs-cliente', shortId], getClienteConfigs, { enabled: Boolean(shortId), refetchOnWindowFocus: false, initialData: 
      { 
        logo: "/logo.png",
        dddCelular: false,
        ddiCelular: false,
        nome: "",
        telefoneCelular: ""
    } })
}