import * as React from "react";
import {
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const ListEditDetalheEndAtendimento = ({ id, title, borderBottom, funcaoExcluir }) => {
   
    function handleApagar() {
        funcaoExcluir(id)
    }

    return (
        <ListItem
            sx={{ borderBottom, borderColor: "divider" }}
        >
            <ListItemIcon>
                <LabelIcon />
            </ListItemIcon>
            <ListItemText primary={title} />
            <Tooltip title="Apagar">
                <IconButton onClick={handleApagar} aria-label="Apagar">
                    <DeleteForeverIcon />
                </IconButton>
            </Tooltip>
        </ListItem>
    );
};
