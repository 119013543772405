import { Box, Button, Grid, CardContent, Card, Stack, Typography, Divider } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { InputForm } from '../InputForm';
import { useState } from 'react';
import { InputFormSearchIconCNPJ } from '../InputFormSearchIconCNPJ';
import usePlataforma from '../../hooks/usePlataforma';
import { Carregando } from '../Carregando';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Swal from 'sweetalert2';
import { InputFormSearchIconEmpresa } from '../InputFormSearchIconEmpresa';
import ControlledCheckbox from '../CheckboxForm';

const inputs = [
    { label: 'CNPJ', name: 'cnpjReclamada', tamanho: 3, search: true },
    { label: 'Razão social', name: 'razaoSocialReclamada', tamanho: 3, search: false },
    { label: 'Email', name: 'emailReclamada', tamanho: 3, search: false },
    { label: 'Telefone', name: 'telefoneCelularReclamada', tamanho: 3, search: false },
    { label: 'CEP', name: 'cepReclamada', tamanho: 2, search: true },
    { label: 'Rua', name: 'ruaReclamada', tamanho: 4, search: false },
    { label: 'Número', name: 'numeroCasaReclamada', tamanho: 2, search: false },
    { label: 'Complemento', name: 'complementoEnderecoReclamada', tamanho: 4, search: false },
    { label: 'Bairro', name: 'bairroReclamada', tamanho: 3, search: false },
    { label: 'Cidade', name: 'cidadeReclamada', tamanho: 3, search: false },
    { label: 'Estado', name: 'estadoReclamada', tamanho: 3, search: false },
]


export const DadosDaReclamada = ({ handleNext, formik, handleBack, viewData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { addEnderecoEmpresa, addEmpresa, verificarCoberturaAtendimento } = usePlataforma()


    const cnpjEncontrado = formik.values.cnpjEncontrado
    const enderecoCNPJEncontrado = formik.values.enderecoCNPJEncontrado
    let enderecoEncontradoLocalTrabalho = formik.values.enderecoEncontradoLocalTrabalho
    var mostrarEndLocalTrabalho = viewData || enderecoEncontradoLocalTrabalho
    const enderecoIsLocalTrabalho = formik.values.enderecoIsLocalTrabalho

    async function handleClickNext() {
        setIsLoading(true);
        formik.setFieldTouched("cnpjReclamada", true)
        formik.setFieldTouched("cepReclamada", true)

        if (viewData) {
            setIsLoading(false);
            handleNext();
            return
        }

        try {
            var idEnderecoReclamada = formik.values.idEnderecoReclamada
            if (!enderecoCNPJEncontrado) {
                const responseEndereco = await addEnderecoEmpresa(formik.values);
                idEnderecoReclamada = responseEndereco.id
                formik.setFieldValue('idEnderecoReclamada', responseEndereco.id);
                formik.setFieldValue('enderecoCNPJEncontrado', true);
                if(enderecoIsLocalTrabalho){
                    formik.setFieldValue('idEnderecoLocalTrabalho', responseEndereco.id);
                }
            }

            if (!cnpjEncontrado) {
                const responseEmpresa = await addEmpresa(formik.values, idEnderecoReclamada);
                if(enderecoIsLocalTrabalho){
                    formik.setFieldValue('idEnderecoLocalTrabalho', idEnderecoReclamada);
                }
                formik.setFieldValue('idEmpresaReclamada', responseEmpresa.id);
                formik.setFieldValue('cnpjEncontrado', true);
            } else if(enderecoIsLocalTrabalho){
                    formik.setFieldValue('idEnderecoLocalTrabalho', idEnderecoReclamada);
                }

            if (enderecoIsLocalTrabalho) {
                var diponibilidade = await verificarCoberturaAtendimento(formik.values.cepReclamada)
                if (diponibilidade) {
                    handleNext();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Atenção',
                        text: 'Desculpe, mas não atendemos a esse endereço.',
                    });
                }
            } else {
                var diponibilidade = await verificarCoberturaAtendimento(formik.values.cepEnderecoLocalTrabalho)
                if (diponibilidade) {
                    handleNext();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Atenção',
                        text: 'Desculpe, mas não atendemos a esse endereço.',
                    });
                }
            }
        } catch (error) {
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: 'Tente preencher as informações corretamente.',
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Grid item lg={12}>
            <Card sx={{ border: 1, borderColor: "#D8D8D8", mb: 3 }}>
                <CardContent>
                    <Grid container spacing={3} >

                        {inputs.map((current, i) => (
                            <Grid key={i} item xs={current.tamanho}>
                                {current.search ? <InputFormSearchIconCNPJ disabled={viewData} setIsLoading={setIsLoading} id={current.name} label={current.label} formik={formik} /> : <InputForm disabled={viewData} id={current.name} label={current.label} formik={formik} />}
                            </Grid>
                        ))}

                        <Grid item lg={3}>
                          {!viewData && <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                                spacing={2}
                                height="100%"
                            >
                            <ControlledCheckbox disabled={viewData} id="enderecoIsLocalTrabalho" label="Endereço é local de trabalho" formik={formik} />
                            </Stack>}
                        </Grid>

                      {(viewData || !enderecoIsLocalTrabalho) &&
                      <>
                      <Grid item lg={12}>
                            <Typography sx={{ fontWeight: 500, fontSize: 19 }}>
                                Endereço de local de trabalho
                            </Typography>
                            <Box my={1}><Divider /></Box>
                        </Grid>
                        <Grid item lg={3}>
                            <InputFormSearchIconEmpresa disabled={viewData} setIsLoading={setIsLoading} formik={formik} id="cepEnderecoLocalTrabalho" label="CEP" />
                        </Grid>
                        {mostrarEndLocalTrabalho && <Grid item lg={3}>
                            <InputForm formik={formik} id="ruaEnderecoLocalTrabalho" label="Rua" disabled={true} />
                        </Grid>}
                        {mostrarEndLocalTrabalho && <Grid item lg={1}>
                            <InputForm formik={formik} id="numeroCasaEnderecoLocalTrabalho" label="Número" disabled={viewData || !enderecoEncontradoLocalTrabalho} />
                        </Grid>}
                        {mostrarEndLocalTrabalho && <Grid item lg={3}>
                            <InputForm formik={formik} id="bairroEnderecoLocalTrabalho" label="Bairro" disabled={true} />
                        </Grid>}
                        {mostrarEndLocalTrabalho && <Grid item lg={4}>
                            <InputForm formik={formik} id="complementoEnderecoLocalTrabalho" label="Complemento" disabled={viewData || !enderecoEncontradoLocalTrabalho} />
                        </Grid>}
                        {mostrarEndLocalTrabalho && <Grid item lg={3}>
                            <InputForm formik={formik} id="cidadeEnderecoLocalTrabalho" label="Cidade" disabled={true} />
                        </Grid>}
                        {mostrarEndLocalTrabalho && <Grid item lg={3}>
                            <InputForm formik={formik} id="estadoEnderecoLocalTrabalho" label="Estado" disabled={true} />
                        </Grid>} </>}
                    </Grid>
                </CardContent>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    sx={{ p: 2 }}
                >
                    <Button onClick={handleBack} startIcon={<NavigateBeforeIcon />} variant="outlined">Voltar</Button>
                    <Button onClick={viewData ? handleNext : handleClickNext} endIcon={<NavigateNextIcon />} variant="contained">Próximo</Button>
                </Stack>
                <Carregando isLoading={isLoading} />
            </Card>
        </Grid>
    );
}
