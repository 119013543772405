import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import errorImg from "../../../assets/error.gif"

export function ErrorForm() {
    return (
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ mt: 3, px: 4 }}
                spacing={3}
            >
                <Grid item xs={2}>
                    <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
                        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                </Grid>

                <Grid item lg={12}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ border: 1, borderColor: "#D8D8D8", mb: 4, minHeight: 500, pt: 4, bgcolor: "#fff", borderRadius: 1 }}
                    >
                        <img src={errorImg} alt="error" style={{ height: 180 }} />
                        <Box mt={-4}>
                            <Typography sx={{ color: "#f44336" }} variant="subtitle2" textAlign="center">Desculpe. Algo deu errado.<br />Tente novamente mais tarde.</Typography>
                        </Box>
                    </Stack>

                </Grid>

            </Grid>
    )
}