import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Grid, LinearProgress, Stack } from "@mui/material";
import MessageIcon from '@mui/icons-material/Message';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import useFetchGetStaugetSatusPeticao from '../queries/getSatusPeticao';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import usePlataforma from '../hooks/usePlataforma';
import { toast } from 'react-toastify';
import useUser from '../providers/useUser';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PaymentsIcon from '@mui/icons-material/Payments';
import { ModalSelectContrato } from './ModalSelectContrato';

export const OpcoesViewPeticao = ({ idPeticao, viewData }) => {
    let navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const podeVisualizarComponente = useUser((state) => state.podeVisualizarComponente)
    const handleComentario = () => navigate(`/detalhe/peticao/comentarios/${idPeticao}`);
    const handleArquivos = () => navigate(`/detalhe/peticao/arquivos/${idPeticao}`);
    const handleVoltar = () => navigate("/");
    const { downloadContrato } = usePlataforma()
    const [modalDownloadContrato, setModalDownloadContrato] = React.useState(false);

    const handleSolicitarAdiantamento = () => {
        navigate("/financeiro", { state: { idPeticao } });
    }

    const handleDownloadContrato = async () => setModalDownloadContrato(true)

    const { data: dataStatusPeticao } = useFetchGetStaugetSatusPeticao()


    var statusIdPeticao = viewData?.status?.id

    return (
        <>
            <Grid mb={2} justifyContent="flex-end" container spacing={2} sx={{ border: 1, borderColor: "#D8D8D8", borderRadius: 1 }}>

                <Grid item xs={4}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Button onClick={handleVoltar} startIcon={<KeyboardBackspaceIcon />} variant="contained">VOLTAR</Button>
                    </Stack>
                </Grid>
                <Grid item xs={8}>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                        pr={2}
                    >
                        {podeVisualizarComponente(["advogado"]) && <Button onClick={handleSolicitarAdiantamento} size="small" startIcon={<PaymentsIcon />} variant='contained' >SOLICITAR ADIANTAMENTO</Button>}
                        {podeVisualizarComponente(["user"]) && <Button onClick={() => setOpen(true)} size="small" startIcon={<ChangeCircleIcon />} variant='contained' >ALTERAR STATUS</Button>}
                        {podeVisualizarComponente(["user"]) && <Button onClick={handleDownloadContrato} size="small" startIcon={<SimCardDownloadIcon />} variant='contained' >DOWNLOAD CONTRATO</Button>}
                        <Button onClick={handleComentario} size="small" startIcon={<MessageIcon />} variant='contained' >MENSAGENS</Button>
                        <Button onClick={handleArquivos} size="small" startIcon={<CloudUploadIcon />} variant='contained' >ARQUIVOS</Button>
                    </Stack>

                </Grid>
                <Grid item xs={12} />
            </Grid>
            <ModalSelectContrato idPeticao={idPeticao} open={modalDownloadContrato} setOpen={setModalDownloadContrato} />
            <ModalChangeStatus open={open} setOpen={setOpen} idPeticao={idPeticao} dataStatusPeticao={dataStatusPeticao} statusIdPeticao={statusIdPeticao} />
        </>
    );
}



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));



function ModalChangeStatus({ dataStatusPeticao, statusIdPeticao, idPeticao, open, setOpen }) {
    const [isLoading, setIsLoading] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Alterar Status
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            {isLoading && <LinearProgress />}
            <DialogContent dividers>
                <CheckboxListSecondary idPeticao={idPeticao} setIsLoading={setIsLoading} dataStatusPeticao={dataStatusPeticao} statusIdPeticao={statusIdPeticao} />
            </DialogContent>
        </BootstrapDialog>
    );
}

function CheckboxListSecondary({ statusIdPeticao, dataStatusPeticao, setIsLoading, idPeticao }) {
    const { changeStatusPeticao } = usePlataforma()
    let navigate = useNavigate();
    const handleBack = () => navigate(-1);

    async function handleChangeStatus(idStatus) {
        if (statusIdPeticao === idStatus) {
            toast.info("Este é o status atual, selecione outro para alterar.")
        } else {
            setIsLoading(true)
            try {
                await changeStatusPeticao(idStatus, idPeticao)
                setIsLoading(false)
                handleBack()
            } catch (error) {
                setIsLoading(false)
            }
        }
    }

    return (
        <List dense sx={{ width: '100%', minWidth: 400 }}>
            {dataStatusPeticao && dataStatusPeticao.data.map((row) => {
                const labelId = `checkbox-list-secondary-label-${row.id}`;
                return (
                    <ListItem
                        key={row.id}
                        secondaryAction={
                            row.id === statusIdPeticao ? <IconButton disabled aria-label="selecionar"><RadioButtonCheckedIcon color='primary' /></IconButton> :
                                <IconButton aria-label="selecionar" onClick={() => handleChangeStatus(row.id)}>
                                    <RadioButtonUncheckedIcon color="disabled" />
                                </IconButton>
                        }
                        disablePadding
                    >
                        <ListItemButton onClick={() => handleChangeStatus(row.id)}>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: row.color, width: 24, height: 24 }}> </Avatar>
                            </ListItemAvatar>
                            <ListItemText id={labelId} primary={row.nome} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}
