import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import LabelIcon from "@mui/icons-material/Label";
import ModeIcon from "@mui/icons-material/Mode";
import { Chip, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Tooltip } from '@mui/material';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import EditIcon from '@mui/icons-material/Edit';

export default function AccordionUsage({ grupos = [], setDialogSettings, setGrupoDialogSettings }) {
    return (
        <div>
            {grupos.map(grupo => (
                <Accordion key={grupo.id}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        {grupo.nome_label}
                    </AccordionSummary>
                    <AccordionDetails>
                        <List
                            sx={{ width: "100%", bgcolor: "#bcd7f330" }}
                            subheader={<ListSubheader>Campos</ListSubheader>}
                        >
                            {grupo.campo_formulario.map((campo, index, arr) => (
                                <ListCampos
                                    id={campo.id}
                                    campo={campo}
                                    grupo={grupo}
                                    key={campo.id}
                                    setDialogSettings={setDialogSettings}
                                    tipo_campo={campo.campo.tipo_campo.nome}
                                    title={campo.campo.nome}
                                    borderBottom={index === arr.length - 1 ? 0 : 1}

                                />
                            ))}
                        </List>
                        <Button
                            onClick={() => setDialogSettings({ campo: null, grupo, editar: false })}
                            startIcon={<KeyboardControlKeyIcon />}
                            fullWidth
                            sx={{ p: 0, m: 0, px: 1, py: 1, fontWeight: 500, fontSize: 12, mt: 2 }}
                            size="small"
                            variant="contained"
                        >
                            NOVO CAMPO
                        </Button>
                        <Button
                            onClick={() => setGrupoDialogSettings({ grupo, editar: true })}
                            startIcon={<EditIcon />}
                            fullWidth
                            sx={{ p: 0, m: 0, px: 1, py: 1, fontWeight: 500, fontSize: 12, mt: 2 }}
                            size="small"
                            variant="text"
                            color='info'
                        >
                            EDITAR GRUPO
                        </Button>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}


function ListCampos({ id, title, tipo_campo, borderBottom, setDialogSettings, campo, grupo }) {
    return (
        <ListItem
            sx={{ borderBottom, borderColor: "divider" }}
        >
            <ListItemIcon>
                <Chip size='small' sx={{ fontSize: 9, textTransform: 'uppercase' }} label={tipo_campo} />
            </ListItemIcon>
            <ListItemText sx={{
                '& .MuiTypography-root': {
                    fontSize: 13,
                    fontWeight: 400
                },
            }} primary={title} />
            <Tooltip title="Editar">
                <IconButton onClick={() => setDialogSettings({ campo, grupo, editar: true })} aria-label="Permissões">
                    <ModeIcon />
                </IconButton>
            </Tooltip>
        </ListItem>
    )
}