import { useQuery } from "react-query";
import api from "../../service/api";

async function getConfigInsalubridade(ctx) {
  const [, page] = ctx.queryKey
  const { data } = await api.get('/api/insalubridade', { params: { page, limit: 20 } })
  return data
}

export default function useFetchGetConfigInsalubridade(page) {
    return useQuery(['configInsalubridade', page], getConfigInsalubridade, { refetchOnWindowFocus: false})
}