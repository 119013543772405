import React from 'react';
import Chip from '@mui/material/Chip';

const MethodColors = ({ method }) => {
  const methodColors = {
    GET: 'success',
    POST: 'info',
    PUT: 'warning',
    DELETE: 'error',
  };

  const color = methodColors[method] || 'default';

  return (
    <Chip
      size="small"
      sx={{ borderRadius: 1 }}
      label={method}
      color={color}
    />
  );
};

export default MethodColors;
