import ImageListItem from '@mui/material/ImageListItem';
import { Upload, message } from 'antd';
import React from 'react';
import { Box } from '@mui/material';
import { InboxOutlined } from '@ant-design/icons';
import { toast } from "react-toastify";



const LogoAdvogado = ({ file, setFile, disabled }) => {
    const { Dragger } = Upload;

    const props = {
        name: 'file',
        accept: 'image/*',
        maxCount: 1,
        multiple: false,
        showUploadList: false,
        beforeUpload: (data) => {

            const isLt1M = data.size / 1024 / 1024 < 1;
            if (!isLt1M) {
                message.error('Tamanho excedido. Tamanho máximo permitido 1MB!');
                return false
            }

            // converte em base64
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onload = () => {
                // console.log(reader.result);
                setFile(reader.result);
            };

            return false;
        },
    };
    return (
        <React.Fragment>
            {
                file ?
                    <Dragger {...props} disabled={disabled}>
                        <ImageListItem >
                            < img
                                src={file}
                                alt={"logo"}
                            />
                        </ImageListItem >
                    </Dragger>

                    :
                    <Box marginInlineEnd={2}>
                        <Dragger {...props} disabled={disabled}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <Box p={2}>
                                <p className="ant-upload-text">Faça o upload da sua Logo.</p>
                                <p className="ant-upload-hint">
                                    Sua logo será exibida para o seu cliente
                                </p>
                                <p className="ant-upload-hint">
                                    amanho máximo permitido 1MB
                                </p>
                            </Box>
                        </Dragger>
                    </Box>
            }
        </React.Fragment>
    )
};


export default LogoAdvogado;