import { useQuery } from "react-query";
import api from "../../service/api";

async function getListAdiantamento(ctx) {
  const [, page] = ctx.queryKey
  const { data } = await api.get('/api/financeiro/listas-adiantamento', { params: { page, limit: 20 } })
  return data
}

export default function useFetchGetListAdiantamento(page = 1) {
  return useQuery(['listas-adiantamento', page], getListAdiantamento, { refetchOnWindowFocus: false })
}