import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


export default function SelectedListItem({ selectedConfiguracao, handleListItemClick, configuracoesLinks }) {

  return (
      <List component="nav" aria-label="main mailbox folders">
        {configuracoesLinks.map(current => (
            <ListItemButton
            key={current.id}
            selected={selectedConfiguracao === current.id}
            onClick={(event) => handleListItemClick(event, current.id)}
          >
            <ListItemIcon>
              {current.icon}
            </ListItemIcon>
            <ListItemText primary={current.nome} />
          </ListItemButton>
        ))}        
      </List>
  );
}
