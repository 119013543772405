import { Box, Divider, Typography } from "@mui/material"

export const TitleHeader = ({ label }) => {

    return (
        <>
            <Typography sx={{ fontWeight: 500, fontSize: 19 }}>
                {label}
            </Typography>
            <Box my={1}><Divider /></Box>
        </>
    )
}