import { useQuery } from "react-query";
import api from "../../service/api";

async function getPeticaoById(ctx) {
  const [, id] = ctx.queryKey
  const { data } = await api.get(`/api/peticao?page=1&limit=1&idPeticao=${id}`)
  return data
}

export default function useFetchGetPeticaoById(id) {
    return useQuery(['peticaoById', id], getPeticaoById, { refetchOnWindowFocus: false})
}