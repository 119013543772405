import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

export const DetalhePeticao = () => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (isOpen) => () => {
        setOpen(isOpen);
    };

    return (
        <Drawer
            open={open}
            onClose={toggleDrawer(false)}
            sx={{
                width: 350,
            }}
            anchor="right"
        >
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                sx={{ width: 350, height: '100%' }}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                    sx={{ width: "100%" }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        sx={{ width: "100%", p: 2, borderBottom: 1, borderColor: "#D8D8D8" }}
                    >
                        <Typography sx={{ fontWeight: 300, fontSize: 24 }}>
                            Detalhe Petição
                        </Typography>
                        <IconButton onClick={toggleDrawer(false)} aria-label="delete">
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    {/* components aqui */}


                </Stack>

                {/* botão salvar ou sair aqui */}
            </Stack>
        </Drawer>
    );
}
