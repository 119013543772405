import React, { useRef } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import api from '../service/api';
import moment from 'moment/moment';
import NumberFormat from "react-number-format";
import Swal from 'sweetalert2';

export const InputFormSearchIconEmpresa = ({ id, label, formik, setIsLoading, disabled = false }) => {
  const inputRef = useRef(null);

  const handleTypographyClick = () => {
    inputRef.current?.focus();
  };

  const handleSearch = () => {
    searchApi();
  };

  const searchApi = async () => {
    try {
      setIsLoading(true);
      const endpoint = `/api/enderecoFindByCep/${formik.values[id].replace(/[^0-9]/g, '')}`;

      const response = await api.get(endpoint);

      formEndereco(response.data);

      if(!response.data) {
        Swal.fire({
          icon: 'warning',
          title: 'Aviso',
          text: "Registro não encontrado.",
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: 'warning',
        title: 'Aviso',
        text: "Registro não encontrado.",
      });
    }
  };

  const formEndereco = (data) => {
    if (data && data.length > 0) {
      const { estado, cidade, bairro, rua, id, cep } = data[0];

      formik.setFieldValue('cepEnderecoLocalTrabalho', cep || "");
      formik.setFieldValue('ruaEnderecoLocalTrabalho', rua || "");
      formik.setFieldValue('bairroEnderecoLocalTrabalho', bairro || "");
      formik.setFieldValue('cidadeEnderecoLocalTrabalho', cidade || "");
      formik.setFieldValue('estadoEnderecoLocalTrabalho', estado || "");
      formik.setFieldValue('idEnderecoLocalTrabalho', id || "");
      formik.setFieldValue('enderecoEncontradoLocalTrabalho', true);
    }
  };

  const inputFormat = "#####-###";

  return (
    <Box>
      <Typography
        sx={{ fontWeight: 300, fontSize: 19, cursor: 'default' }}
        onClick={handleTypographyClick}
        component="div"
        role="button"
        tabIndex={0}
      >
        {label}
      </Typography>
      <NumberFormat
        format={inputFormat}
        placeholder="Digite o CEP para pesquisar."
        customInput={TextField}
        mask="_"
        disabled={disabled}
        fullWidth
        size="small"
        variant="outlined"
        id={id}
        name={id}
        inputRef={inputRef}
        value={formik.values[id]}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={Boolean(formik.touched[id] && formik.errors[id])}
        helperText={formik.touched[id] && formik.errors[id]}
        InputProps={{
          endAdornment: disabled ? null : (
            <SearchIcon
              sx={{ cursor: 'pointer' }}
              onClick={handleSearch}
              color="primary"
            />
          ),
        }}
      />
    </Box>
  );
};
