import create from 'zustand';

const useUser = create((set, get) => ({
  user: {
    "id": "",
    "nome": "",
    "type": "",
    "iat": "",
    "exp": "",
    isEmpty: true
  },
  setUser: (user) => set({ user: {...user, isEmpty: false } }),
  podeVisualizarComponente: (podeVer) => {
    const user = get().user
    if (!Array.isArray(podeVer)) {
      return false;
    }

    var tipoConta = user.type;
    if (podeVer.includes(tipoConta)) {
      return true;
    } else {
      return false;
    }
  }
}));

export default useUser;
