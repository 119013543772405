import moment from "moment";

export const ValuesView = (viewData) => {

    function extrairData(dataISO) {
        const regex = /^(\d{4}-\d{2}-\d{2}).*/;
        const match = dataISO.match(regex);
        if (match && match[1]) {
          return match[1];
        }
        return null
      }


    const formatDate = (date) => {
        if (date && date !== '') {
            var format = extrairData(date)
            return format;
        } else {
            return "";
        }
    };

    const changeValue = (value) => {
       return value ? value : ""
    };



    const {
        idReclamante,
        idInsalubridade,
        idEmpresaReclamada,
        cpfReclamante,
        nomeReclamante,
        idProfissao,
        profissaoReclamante,
        cnpjReclamada,
        razaoSocialReclamada,
        dataContratacao,
        dataDispensa,
        funcao,
        salarioInicial,
        salarioFinal,
        motivoRescisao,
        assinaturaCarteira,
        desvioFuncao,
        funcaoDiversa,
        recebiaValoresExtra,
        contratacaoAssinaturaDivergente,
        horaEntrada,
        horaSaida,
        idEscalaTrabalho,
        intervaloDescansoAlmoco,
        tempoIntervalo,
        trabalhoDomingoFolga,
        frequenciaTrabalhoDomingo,
        trabalhoFeriado,
        frequenciaTrabalhoFeriado,
        trabalhoNoturno,
        mediaHorasTrabalhoNoturno,
        mediaDiasTrabalhoNoturno,
        trabalhoInsalubre,
        recebeuEpi,
        epiRecebido,
        agenteInsalubre,
        trabalhoPericulosidade,
        recebePericulosidade,
        agentesPericulosos,
        feriasPeriodoIncorreto,
        dataFeriasPeriodoCorreto,
        dataFeriasPeriodoIncorreto,
        feriasPagamento,
        feriasInicio,
        dataFeriasPagamento,
        trabalhoDuranteFerias,
        trabalhoDuranteFeriasQuantidade,
        acidenteTrabalho,
        emissaoCat,
        afastamentoBeneficiario,
        danoEstetico,
        doencaOcupacional,
        doencaOcupacionalAfastamento,
        assedioMoral,
        nomeAssediador,
        descricaoFatos,
        assedioSexual,
        nomeAssediadorSexual,
        descricaoFatosAssedioSexual,
        dispensaDiscriminatoria,
        pagamentoRescisao,
        dataPagamentoRescisao,
        entregaDocumentos,
        dataEntregaDocumentos,
        dataAssinatura,
        escalaTrabalho,
        insalubridade,
        enderecoReclamante,
        empresaReclamada,
        reclamante,
        enderecoReclamada,
        idAdvogado,
        advogado,
        idEnderecoLocalTrabalho,
        complementoEnderecoLocalTrabalho,
        numeroCasaEnderecoLocalTrabalho,
        enderecoLocalTrabalho,
        idEnderecoReclamante,
        idEnderecoReclamada,
        informacoesAdicionaisFinais, 
        informacoesAdicionaisJornada, 
        informacoesAdicionaisTrabalho,
        isClienteLocal,
        breveDescricaoFatos,
        horarioAtendimentoPreferencia
    } = viewData;


    const data = {
        idAdvogado,
        advogado,
        idReclamante: changeValue(idReclamante),
        idInsalubridade: changeValue(idInsalubridade),
        idEmpresaReclamada: changeValue(idEmpresaReclamada),
        idEnderecoReclamante: changeValue(idEnderecoReclamante),
        idEnderecoReclamada: changeValue(idEnderecoReclamada),
        cpfReclamante,
        nomeReclamante,
        emailReclamante: reclamante ? reclamante.email : "",
        dataNascimento: reclamante ? formatDate(reclamante.dataNascimento) : "",
        estadoCivilReclamante: reclamante ? reclamante.estadoCivil : "",
        idProfissao,
        profissao: profissaoReclamante ? profissaoReclamante : null,
        ddiCelular: reclamante ? reclamante.ddiCelular : "",
        dddCelular: reclamante ? reclamante.dddCelular : "",
        telefoneCelularReclamante: reclamante ? reclamante.telefoneCelular : "",
        telefoneCelularReclamada: empresaReclamada ? empresaReclamada.telefone : "",
        cepReclamante: enderecoReclamante ? enderecoReclamante.cep : "",
        ruaReclamante: enderecoReclamante ? enderecoReclamante.rua : "",
        numeroCasaReclamante: reclamante ? reclamante.numeroCasa : "",
        bairroReclamante: enderecoReclamante ? enderecoReclamante.bairro : "",
        complementoEnderecoReclamante: reclamante ? reclamante.complementoEndereco : "",
        cidadeReclamante: enderecoReclamante ? enderecoReclamante.cidade : "",
        estadoReclamante: enderecoReclamante ? enderecoReclamante.estado : "",
        cnpjReclamada,
        razaoSocialReclamada,
        emailReclamada: empresaReclamada ? empresaReclamada.email : "",
        cepReclamada: enderecoReclamada ? enderecoReclamada.cep : "",
        ruaReclamada: enderecoReclamada ? enderecoReclamada.rua : "",
        numeroCasaReclamada: empresaReclamada ? empresaReclamada.numeroCasa : "",
        complementoEnderecoReclamada: empresaReclamada ? empresaReclamada.complementoEndereco : "",
        bairroReclamada: enderecoReclamada ? enderecoReclamada.bairro : "",
        cidadeReclamada: enderecoReclamada ? enderecoReclamada.cidade : "",
        estadoReclamada: enderecoReclamada ? enderecoReclamada.estado : "",
        dataContratacao: formatDate(dataContratacao),
        dataDispensa: formatDate(dataDispensa),
        funcao,
        salarioInicial,
        salarioFinal,
        motivoRescisao,
        assinaturaCarteira,
        desvioFuncao,
        funcaoDiversa,
        recebiaValoresExtra,
        contratacaoAssinaturaDivergente,
        horaEntrada,
        horaSaida,
        idEscalaTrabalho,
        intervaloDescansoAlmoco,
        tempoIntervalo: tempoIntervalo ? tempoIntervalo : "",
        trabalhoDomingoFolga,
        frequenciaTrabalhoDomingo,
        trabalhoFeriado,
        frequenciaTrabalhoFeriado: changeValue(frequenciaTrabalhoFeriado),
        trabalhoNoturno,
        mediaHorasTrabalhoNoturno: changeValue(mediaHorasTrabalhoNoturno),
        mediaDiasTrabalhoNoturno: changeValue(mediaDiasTrabalhoNoturno),
        trabalhoInsalubre,
        recebeuEpi,
        epiRecebido,
        agenteInsalubre: changeValue(agenteInsalubre),
        trabalhoPericulosidade,
        recebePericulosidade,
        agentesPericulosos: changeValue(agentesPericulosos),
        feriasPeriodoIncorreto,
        dataFeriasPeriodoCorreto: formatDate(dataFeriasPeriodoCorreto),
        dataFeriasPeriodoIncorreto: formatDate(dataFeriasPeriodoIncorreto),
        feriasPagamento,
        feriasInicio: changeValue(feriasInicio),
        dataFeriasPagamento: formatDate(dataFeriasPagamento),
        trabalhoDuranteFerias,
        trabalhoDuranteFeriasQuantidade,
        acidenteTrabalho,
        emissaoCat,
        afastamentoBeneficiario,
        danoEstetico,
        doencaOcupacional,
        doencaOcupacionalAfastamento,
        assedioMoral,
        nomeAssediador: changeValue(nomeAssediador),
        descricaoFatos: changeValue(descricaoFatos),
        assedioSexual,
        nomeAssediadorSexual: changeValue(nomeAssediadorSexual),
        descricaoFatosAssedioSexual: changeValue(descricaoFatosAssedioSexual),
        dispensaDiscriminatoria,
        pagamentoRescisao,
        dataPagamentoRescisao: formatDate(dataPagamentoRescisao),
        entregaDocumentos,
        dataEntregaDocumentos: formatDate(dataEntregaDocumentos),
        dataAssinatura: formatDate(dataAssinatura),
        escalaTrabalho: escalaTrabalho ? escalaTrabalho : null,
        insalubridade: insalubridade ? insalubridade : null,
        idEnderecoLocalTrabalho: changeValue(idEnderecoLocalTrabalho),
        complementoEnderecoLocalTrabalho: changeValue(complementoEnderecoLocalTrabalho),
        numeroCasaEnderecoLocalTrabalho: changeValue(numeroCasaEnderecoLocalTrabalho),
        cepEnderecoLocalTrabalho: enderecoLocalTrabalho ? enderecoLocalTrabalho.cep : "",
        ruaEnderecoLocalTrabalho: enderecoLocalTrabalho ? enderecoLocalTrabalho.rua : "",
        bairroEnderecoLocalTrabalho: enderecoLocalTrabalho ? enderecoLocalTrabalho.bairro : "",
        cidadeEnderecoLocalTrabalho: enderecoLocalTrabalho ? enderecoLocalTrabalho.cidade : "",
        estadoEnderecoLocalTrabalho: enderecoLocalTrabalho ? enderecoLocalTrabalho.estado : "",
        informacoesAdicionaisFinais: informacoesAdicionaisFinais ? informacoesAdicionaisFinais : "", 
        informacoesAdicionaisJornada: informacoesAdicionaisJornada ? informacoesAdicionaisJornada : "", 
        informacoesAdicionaisTrabalho: informacoesAdicionaisTrabalho ? informacoesAdicionaisTrabalho : "",
        isLocal: isClienteLocal ? isClienteLocal : false,
        breveDescricaoFatos: breveDescricaoFatos ? breveDescricaoFatos : "",
        horarioAtendimentoPreferencia: horarioAtendimentoPreferencia ? moment(horarioAtendimentoPreferencia).format('HH:mm:ss.SSS') : "",
        steps: isClienteLocal ? ['Dados do reclamante', 'Dados da reclamada', 'Resumo'] : ['Dados do reclamante', 'Dados da reclamada', 'Contrato de trabalho', 'Jornada de trabalho', 'Dados finais']
    };


    return data;
};
