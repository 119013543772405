import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { NavBar } from "../components/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import UploadArquivo from "../components/UploadArquivo";
import useFetchGetPeticaoById from "../queries/peticaoById";
import { Carregando } from "../components/Carregando";
import { useState } from "react";
import { CardArquivo } from "../components/CardArquivo";

export const ArquivosPeticao = () => {
    const { id } = useParams();
    let navigate = useNavigate();
    const [deleteLoading, setDeleteLoading] = useState(false);


    const handleBack = () => navigate(-1);

    const { data, isLoading } = useFetchGetPeticaoById(id)

    var arquivos = data?.data[0].Arquivos

    return (
        <Box>
            <NavBar />
            <Grid container spacing={2} p={2}>
                <Grid item xs={12}>
                    <Button onClick={handleBack} startIcon={<KeyboardBackspaceIcon />} variant="contained">VOLTAR</Button>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <UploadArquivo idPeticao={id} />
                    </Box>
                    <Box mt={2} />
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: 18, color: "#5f5f5f" }}>Arquivos enviados</Typography>
                    <Box py={1}><Divider /></Box>
                </Grid>

                {!isLoading && arquivos && arquivos.map(arquivo => (
                    <Grid key={arquivo.id} item xs={4}>
                        <CardArquivo setDeleteLoading={setDeleteLoading} idStorage={arquivo.id} createdAt={arquivo.createdAt} mimeType={arquivo.mimeType} nome={arquivo.nome} />
                    </Grid>
                ))}

            </Grid>
            <Box mt={4} />
            <Carregando isLoading={isLoading || deleteLoading} />
        </Box >
    );
}


