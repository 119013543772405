import { Stack } from "@mui/material";

export const Capa = () => {
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
        >
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ width: 750 }}
            >
                <img
                    alt="Capa"
                    src="/config.png"
                    style={{
                        display: "inline-block",
                        width: '100%',
                    }}
                />
            </Stack>

        </Stack>
    );
}
