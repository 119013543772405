import React, { useState } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import api from '../service/api';
import { useEffect } from 'react';

const InpultEscalaTrabalho = ({ formik, label, disabled=false }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const fetchOptions = async (inputValue) => {
        try {
            setIsLoading(true);
            const response = await api.get(`/api/escala/${inputValue}`);
            setOptions(response.data);
            setIsLoading(false);
        } catch (error) {
            setOptions([])
            setIsLoading(false);
            console.error('Erro ao buscar as opções:', error);
        }
    };

    const handleOptionSelect = (event, value) => {
        if (!value) {
            formik.setFieldValue('idEscalaTrabalho', null);
            formik.setFieldValue('escalaTrabalho', null);
            return;
        }

        formik.setFieldValue('idEscalaTrabalho', value.id || null);
        formik.setFieldValue('escalaTrabalho', value || null);
    };

    const isOptionEqualToValue = (option, value) => option.id === value.id;

    useEffect(() => {
        if (!formik.values.escalaTrabalho && !disabled) {
            fetchOptions('');
        }
    }, [formik.values.escalaTrabalho]);

    function handleOnInputChange(value) {
        if(!disabled) {
            fetchOptions(value)
        }
    }

    return (
        <Box>
            <Typography sx={{ fontWeight: 300, fontSize: 19, cursor: 'default' }}>
                {label}
            </Typography>
            <Autocomplete
                disabled={disabled}
                loadingText="Buscando..."
                noOptionsText="Não encontrada."
                loading={isLoading}
                options={options}
                getOptionLabel={(option) => option?.nomeEscala || ''}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={handleOptionSelect}
                onInputChange={(event, value) => handleOnInputChange(value)}
                size="small"
                value={formik.values.escalaTrabalho}
                renderInput={(params) => <TextField {...params} />}
            />
        </Box>
    );
};

export default InpultEscalaTrabalho;
