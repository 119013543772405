import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { CircularProgress, LinearProgress, ListSubheader, Pagination, Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import useFetchGetConfigContratosTemplates from '../queries/configContratosTemplates';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import api from '../service/api';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export function ModalSelectContrato({ open, setOpen, idPeticao }) {
    const [page, setPage] = React.useState(1);
    const [carregando, setCarregando] = React.useState(false);

    const { data, isLoading } = useFetchGetConfigContratosTemplates(page)

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Modelo contrato
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            {(isLoading || carregando) && <LinearProgress />}
            <DialogContent dividers>
                <CheckboxListSecondary handleClose={handleClose} isLoading={carregando} setCarregando={setCarregando} modelos={data?.data} idPeticao={idPeticao} />
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    my={2}
                >
                    {!isLoading && data && data.totalPages > 1 && <Pagination color="primary" count={data.totalPages} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} />}
                </Stack>
            </DialogContent>
        </BootstrapDialog>
    );
}

function CheckboxListSecondary({ modelos, idPeticao, isLoading, setCarregando, handleClose }) {
    const [modeloSelecionado, setModeloSelecionado] = React.useState(null);

    async function handleDownloadModelo(idModelo, filename) {
        setCarregando(true);
        setModeloSelecionado(idModelo)
        try {
            const response = await api.post(`/api/contratos/processo`, { idPeticao, idModelo }, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            handleClose()

        } catch (error) {
            console.error('Erro ao baixar o contrato:', error);
        } finally {
            setCarregando(false);
            setModeloSelecionado(null)
        }
    }

    return (
        <List
            subheader={<ListSubheader>Selecione uma opção</ListSubheader>}
            dense sx={{ width: '100%', minWidth: 400 }}>
            {modelos && modelos.map((row) => {
                const labelId = `checkbox-list-secondary-label-${row.id}`;
                return (
                    <ListItem
                        key={row.id}
                        secondaryAction={
                            <IconButton aria-label="selecionar" onClick={() => handleDownloadModelo(row.id, row.nome)}>
                                {(modeloSelecionado === row.id) ? < RadioButtonCheckedIcon color="disabled" /> : <RadioButtonUncheckedIcon color="disabled" />}
                            </IconButton>
                        }
                        disablePadding
                    >
                        <ListItemButton disabled={isLoading} onClick={() => handleDownloadModelo(row.id, row.nome)}>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: (modeloSelecionado === row.id) ? '#29a4046e' : "#29a404", width: 16, height: 16 }}>{(modeloSelecionado === row.id) && <CircularProgress size={16} />} </Avatar>
                            </ListItemAvatar>
                            <ListItemText id={labelId} primary={row.label} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}