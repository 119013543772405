import { useQuery } from "react-query";
import api from "../../service/api";

async function getNotificaoActions() {
  const { data } = await api.get("/api/notificao/actions")
  return data
}

export default function useFetchGetNotificaoActions() {
    return useQuery(['notificao-actions'], getNotificaoActions, { refetchOnWindowFocus: false})
}