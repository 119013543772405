import React, { useState } from "react";
import { Box, Button, CircularProgress, Divider, Grid, LinearProgress, Stack } from "@mui/material";
import { NavBar } from "../components/NavBar";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';

import { LoadingForm } from "../components/FormularioDinamico/components/LoadingForm";
import { ErrorForm } from "../components/FormularioDinamico/components/ErrorForm";
import { ViewForm } from "../components/FormularioDinamico/components/ViewForm";
import { OpcoesViewPeticao } from "../components/OpcoesViewPeticao";
import useFetchFormularioResposta from "../queries/getFormularioResposta";
import useFetchGetConfigFormulariosID from "../queries/configFormularios[id]";
import { decryptJSON } from "../utils/jsonCrypto";
import DialogRespostaEdit from "../components/FormularioEditar/DialogRespostaEdit";
import EditNoteIcon from '@mui/icons-material/EditNote';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import usePlataforma from "../hooks/usePlataforma";
import useUser from "../providers/useUser";

export const PeticaoView = () => {
    const { id } = useParams();
    const decrypt = decryptJSON(id)
    const [campoEdit, setCampoEdit] = useState(null);
    const [editarRespostas, setEditarRespostas] = useState(false);
    const { editarPermitirEdicao } = usePlataforma()
    const [carregando, setCarregando] = useState(false);
    const podeVisualizarComponente = useUser((state) => state.podeVisualizarComponente)


    const { isError, isLoading, data } = useFetchFormularioResposta(decrypt.idResposta)
    const { isError: configIsError, isLoading: configIsLoading, data: configData } = useFetchGetConfigFormulariosID(data && data.form_id)

    const permitir_edicao = data?.permitir_edicao || false

    function handleEditarRespostas() {

        if (editarRespostas) {
            setEditarRespostas(false)
            return
        }

        if (permitir_edicao) {
            setEditarRespostas(true)
            return
        }

        Swal.fire({
            icon: 'error',
            title: 'Atenção',
            text: "As respostas estão trancadas e não podem ser editadas.",
        });

    }


    async function handleOptionEdicao() {
        setCarregando(true)
        await editarPermitirEdicao({
            id_resposta: decrypt.idResposta,
            permitir_edicao: !permitir_edicao,
            peticao_id: data?.peticao_id
        })
        setCarregando(false)
    }

    return (
        <Box>
            {isLoading && <LinearProgress />}
            <NavBar />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ mt: 3, px: 4 }}
                spacing={3}
            >
                {data && (
                    <Grid item xs={12}>
                        <OpcoesViewPeticao idPeticao={decrypt.idPeticao} idResposta={decrypt.idResposta} viewData={decrypt} />
                        {(!isLoading && !configIsLoading) && (data && data.form_id) && <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            sx={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}>
                            {editarRespostas ? (
                                <Button variant="contained" endIcon={<PlaylistAddCheckIcon />} onClick={handleEditarRespostas} color="error" size="small">FINALIZAR EDIÇÃO</Button>
                            ) : (
                                <Button endIcon={<EditNoteIcon />} onClick={handleEditarRespostas} size="small">EDITAR RESPOSTAS</Button>
                            )}
                            {podeVisualizarComponente(["user"]) && <React.Fragment>
                                {permitir_edicao ? (
                                    <Button endIcon={carregando ? <CircularProgress size={14} /> : <LockIcon />} disabled={carregando} onClick={handleOptionEdicao} color="error" size="small">TRANCAR EDIÇÃO</Button>
                                ) : (
                                    <Button endIcon={carregando ? <CircularProgress size={14} /> : <LockOpenIcon />} disabled={carregando} onClick={handleOptionEdicao} color="success" size="small">LIBERAR EDIÇÃO</Button>
                                )}
                            </React.Fragment>}
                        </Stack>}
                    </Grid>
                )}

                {isLoading || configIsLoading ? (
                    <LoadingForm />
                ) : isError ? (
                    <ErrorForm />
                ) : data && data.form_id ? (
                    <ViewForm editarRespostas={editarRespostas} path="PeticaoView" setFieldValues={data.respostas} data={configData} disabled={true} setDialogSettings={setCampoEdit} />
                ) : (
                    <LoadingForm />
                )}
            </Grid>
            {Boolean(campoEdit) && <DialogRespostaEdit form_id={data?.form_id} id_resposta={data?.id_resposta} open={Boolean(campoEdit)} handleClose={() => setCampoEdit(null)} inpultData={campoEdit} />}
        </Box>
    );
}
