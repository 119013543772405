import { useQuery } from "react-query";
import api from "../../service/api";

async function getComentarios(ctx) {
  const [, page, id] = ctx.queryKey
  const { data } = await api.get(`/api/comentarios/${id}`, { params: { page, limite: 10 } })
  return data
}

export default function useFetchGetComentarios(id, page) {
    return useQuery(['comentarios', page, id], getComentarios, { refetchOnWindowFocus: true })
}