import { useQuery } from "react-query";
import api from "../../service/api";

async function getPermissionsByGroup(ctx) {
  const [, id] = ctx.queryKey
  const { data } = await api.get(`/api/permissionsByGroup/${id}`)
  return data
}

export default function useFetchGetPermissionsByGroup(id) {
    return useQuery(['permissionsByGroup', id], getPermissionsByGroup, { refetchOnWindowFocus: false})
}