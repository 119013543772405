import React, { useRef, useState } from 'react';
import { Box, Typography, TextField, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NumberFormat from "react-number-format";
import Swal from 'sweetalert2';
import api from '../../service/api';

export const InputFormSearchIconCNPJ = ({ id, label, formik, disabled = false }) => {
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleTypographyClick = () => {
    inputRef.current?.focus();
  };

  const handleSearch = () => {
    searchApi();
  };

  const searchApi = async () => {
    try {
      setIsLoading(true);
      const endpoint = id === "cnpjReclamada"
        ? `/api/empresaFindByCnpj/${formik.values[id].replace(/[^0-9]/g, '')}`
        : `/api/enderecoFindByCep/${formik.values[id].replace(/[^0-9]/g, '')}`;

      const response = await api.get(endpoint);

      if (id === "cnpjReclamada") {
        formCNPJ(response.data);
      } else {
        formEndereco(response.data);
      }

      if (!response.data) {
        Swal.fire({
          icon: 'warning',
          title: 'Aviso',
          text: "Registro não encontrado.",
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: 'warning',
        title: 'Aviso',
        text: "Registro não encontrado.",
      });
    }
  };


  const formCNPJ = (data) => {
    if (data) {
      const {
        razaoSocial,
        id,
        telefone,
        idEndereco,
        numeroCasa,
        complementoEndereco,
        email,
        endereco
      } = data;

      formik.setFieldValue('razaoSocialReclamada', razaoSocial || "");
      formik.setFieldValue('idEmpresaReclamada', id || "");
      formik.setFieldValue('telefoneCelularReclamada', telefone || "");
      formik.setFieldValue('idEnderecoReclamada', idEndereco || "");
      formik.setFieldValue('numeroCasaReclamada', numeroCasa || "");
      formik.setFieldValue('complementoEnderecoReclamada', complementoEndereco || "");
      formik.setFieldValue('emailReclamada', email || "");
      if (endereco) {
        formik.setFieldValue('idEnderecoReclamada', idEndereco);
        formEndereco([endereco])
      }
      formik.setFieldValue('cnpjEncontrado', true);
    }
  };

  const formEndereco = (data) => {
    if (data && data.length > 0) {
      const { estado, cidade, bairro, rua, id, cep } = data[0];

      formik.setFieldValue('cepReclamada', cep || "");
      formik.setFieldValue('estadoReclamada', estado || "");
      formik.setFieldValue('cidadeReclamada', cidade || "");
      formik.setFieldValue('bairroReclamada', bairro || "");
      formik.setFieldValue('ruaReclamada', rua || "");
      formik.setFieldValue('idEnderecoReclamada', id || "");
      formik.setFieldValue('enderecoCNPJEncontrado', true);
    }
  };
  const inputFormat = id === "cnpjReclamada" ? "##.###.###/####-##" : "#####-###";

  return (
    <Tooltip arrow title={formik.touched[id] && formik.errors[id]}>
      <Box>
        <Typography
          sx={{ fontWeight: 300, fontSize: 19, cursor: 'default', color: Boolean(formik.touched[id] && formik.errors[id]) ? "#e74c3c" : "#000" }}
          onClick={handleTypographyClick}
          component="div"
          role="button"
          tabIndex={0}
        >
          {label}
        </Typography>
        <NumberFormat
          format={inputFormat}
          customInput={TextField}
          placeholder={id === "cnpjReclamada" ? "Digite o CNPJ para pesquisar." : "Digite o CEP"}
          mask="_"
          disabled={disabled}
          fullWidth
          size="small"
          variant="outlined"
          id={id}
          name={id}
          inputRef={inputRef}
          value={formik.values[id]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={Boolean(formik.touched[id] && formik.errors[id])}
          InputProps={{
            endAdornment: disabled ? null : (
              <SearchIcon
                sx={{ cursor: 'pointer' }}
                onClick={handleSearch}
                color="primary"
              />
            ),
          }}
        />
      </Box>
    </Tooltip>
  );
};
