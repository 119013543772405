import { Button, Grid, Stack } from "@mui/material";
import { Carregando } from "../../Carregando";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export function CardView({ handleNext, handleBack, handleSubmit, children, isFirstPage, isLastPage, isLoading, nome }) {

    const showNextButton = !isLastPage;
    const showBackButton = !isFirstPage;
    const showhandleSubmitButton = isLastPage;

    function handleClickNext() {
        handleNext(nome)
    }

    return (
        <Grid item lg={12}>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                sx={{ border: 1, borderColor: "#D8D8D8", bgcolor: "#fff", borderRadius: 1, mb: 2, p: 2 }}
            >
                <Grid item lg={10} sx={{ px: 4 }}>
                    <Grid container spacing={3} direction="row" alignItems="flex-end">
                        {children}
                    </Grid>
                </Grid>
                <Grid item lg={2}>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                        sx={{ m: 1 }}
                    >
                        {(showBackButton || showhandleSubmitButton) && <Button onClick={handleBack} startIcon={<NavigateBeforeIcon />} disabled={isLoading} variant="outlined">Voltar</Button>}
                        {showNextButton && <Button onClick={handleClickNext} endIcon={<NavigateNextIcon />} disabled={isLoading} variant="contained">Próximo</Button>}
                    </Stack>
                </Grid>
            </Grid>
            <Carregando isLoading={Boolean(isLoading)} />
        </Grid >
    )
}