import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'

export default function App({ nome, ddi, ddd, telefone }) {

  return (
      <FloatingWhatsApp  
        accountName={nome}
        avatar="https://www.w3schools.com/w3images/avatar2.png"
        phoneNumber={`${ddi}${ddd}${telefone}`}
        statusMessage=""
        chatMessage="Olá, como podemos te ajudar?"
      />
  )
}