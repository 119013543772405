import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import NumberFormat from "react-number-format";

export const ListEnderecos = ({ current, borderBottom, funcaoExcluir, funcaoAtualizar }) => {
  const [editar, setEditar] = React.useState(false);
  const [cepvalue, setCepvalue] = React.useState(current.cep);
  const [cidadevalue, setCidadevalue] = React.useState(current.cidade);
  const [ruavalue, setRuavalue] = React.useState(current.rua);
  const [estadovalue, setEstadovalue] = React.useState(current.estado);
  const [bairrovalue, setBairrovalue] = React.useState(current.bairro);

  const handleInputCepChange = (event) => {
    setCepvalue(event.target.value);
  };

  const handleInputCidadeChange = (event) => {
    setCidadevalue(event.target.value);
  };

  const handleInputRuaChange = (event) => {
    setRuavalue(event.target.value);
  };

  const handleInputEstadoChange = (event) => {
    setEstadovalue(event.target.value);
  };

  const handleInputBairroChange = (event) => {
    setBairrovalue(event.target.value);
  };

  function handleEditar() {
    setEditar(true);
  }

  function handleAtualizar() {
    var values = {
      cep: cepvalue,
      cidade: cidadevalue,
      rua: ruavalue,
      estado: estadovalue,
      bairro: bairrovalue,
    };

    funcaoAtualizar(current.id, values);
    setEditar(false);
  }

  function handleApagar() {
    funcaoExcluir(current.id);
  }

  return (
    <React.Fragment>
      {editar ? (
        <Grid container spacing={2} p={2} sx={{ bgcolor: "#bcd7f330" }}>
          <Grid item lg={4}>
            <TextField onChange={handleInputRuaChange} value={ruavalue} fullWidth size="small" label="Rua" variant="outlined" />
          </Grid>
          <Grid item lg={4}>
            <TextField onChange={handleInputBairroChange} value={bairrovalue}
              fullWidth
              size="small"
              label="Bairro"
              variant="outlined"
            />
          </Grid>
          <Grid item lg={4}>
            <TextField onChange={handleInputEstadoChange} value={estadovalue}
              fullWidth
              size="small"
              label="Estado"
              variant="outlined"
            />
          </Grid>
          <Grid item lg={4}>
            <TextField onChange={handleInputCidadeChange} value={cidadevalue}
              fullWidth
              size="small"
              label="Cidade"
              variant="outlined"
            />
          </Grid>
          <Grid item lg={4}>
            <NumberFormat
              fullWidth
              customInput={TextField}
              format="#####-###"
              mask="_"
              label="Cep"
              onChange={handleInputCepChange}
              value={cepvalue}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item lg={4}>
            <Button onClick={handleAtualizar} variant="contained">Atualizar</Button>
          </Grid>
          <Grid item lg={12}>
            <Divider />
          </Grid>
        </Grid>
      ) : (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{
            width: "100%",
            borderBottom,
            borderColor: "divider",
            bgcolor: "#bcd7f330",
          }}
          p={2}
        >
          <Avatar>
            <LocationOnIcon />
          </Avatar>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Box p={2}>
              <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                {ruavalue} - {bairrovalue}
              </Typography>
              <Typography sx={{ fontWeight: 400, fontSize: 16 }}>
                {estadovalue} - {cidadevalue}, {cepvalue}
              </Typography>
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              {/* <Tooltip title="Editar">
                <IconButton onClick={handleEditar} aria-label="Editar">
                  <ModeIcon />
                </IconButton>
              </Tooltip> */}
              <Tooltip title="Apagar">
                <IconButton onClick={handleApagar} aria-label="Apagar">
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      )}
    </React.Fragment>
  );
};
