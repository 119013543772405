import * as React from 'react';
import { Box, Button, Card, LinearProgress, Stack, Typography } from '@mui/material';
import { NavBar } from '../components/NavBar';
import { TitleWithButton } from '../components/TitleWithButton';
import { PaginationData } from '../components/PaginationData';
import { RegistroNaoEncontrado } from '../components/RegistroNaoEncontrado';
import useFetchGetAdvogado from '../queries/advogado';
import TableParceiro from '../components/TableParceiro';
import { DWParceiro } from '../components/Drawers/DWParceiro';
import { InputSearchQuery } from '../components/InputSearchQuery';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { ModalPermissaoParceiro } from '../components/Configuracoes/ModalPermissaoParceiro';
import usePlataforma from '../hooks/usePlataforma';
import { ModalGrupoPermissaoParceiro } from '../components/Configuracoes/ModalGrupoPermissaoParceiro';
import { TableLoading } from '../components/TableLoading';
import { Carregando } from '../components/Carregando';


export const Parceiros = () => {
  const [page, setPage] = React.useState(1)
  const [open, setOpen] = React.useState(false);
  const [titleDW, setTitleDW] = React.useState("Novo");
  const [viewData, setViewData] = React.useState(null);
  const [searchCPF, setSearchCPF] = React.useState('');
  const [searchEmail, setSearchEmail] = React.useState('');
  const [isAccepted, setIsAccepted] = React.useState(null);
  const [modalPermissao, setModalPermissao] = React.useState(false);
  const [userIDPermissao, setUserIDPermissao] = React.useState(null);
  const [currentPermissions, setCurrentPermissions] = React.useState(null);
  const [loading, setLoading] = React.useState(false)
  const { addPermissaoAdvogado, deletePermissaoAdvogado, addGrupoPermissaoAdvogado, deleteGrupoPermissaoAdvogado, downloadContrato, enviarContratoAssinatura, downloadContratoAssinado } = usePlataforma()

  const [ModalOpenGrupoPermissao, setModalOpenGrupoPermissao] = React.useState(false);
  const [userIDGrupoPermissao, setUserIDGrupoPermissao] = React.useState(null);

  const toggleDrawer = (isOpen) => {
    setOpen(isOpen);
  };

  const openDrawer = () => {
    setTitleDW("Novo")
    setOpen(true);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleViewDetalhe = (data) => {
    setTitleDW("Ver")
    setViewData(data);
    setOpen(true)
  };

  const { isLoading, data } = useFetchGetAdvogado(page, searchCPF, searchEmail, isAccepted)

  const filterNAceitos = () => {
    setIsAccepted(prevState => prevState === false ? null : false);
  };

  function handleFecharModalPermissao() {
    setModalPermissao(false)
    setUserIDPermissao(null)
    setCurrentPermissions(null)
  }

  function handleAbrirModalPermissao(id, permissions) {
    setModalPermissao(true)
    setUserIDPermissao(id)
    setCurrentPermissions(permissions)
  }

  function handleAbrirModalGrupoPermissao(id) {
    setModalOpenGrupoPermissao(true)
    setUserIDGrupoPermissao(id)
  }

  function handleFecharModalGrupoPermissao() {
    setModalOpenGrupoPermissao(false)
    setUserIDGrupoPermissao(null)
  }

  function handleDownloadContrato({ idParceiro, path }) {
    downloadContrato({ idParceiro, path })
  }

  async function handleDowloadContratoAssinado(idParceiro) {
    setLoading(true)
    await downloadContratoAssinado(idParceiro)
    setLoading(false)
  }

  async function handleEnviarContratoAssinatura(idParceiro) {
    setLoading(true)
    await enviarContratoAssinatura(idParceiro)
    setLoading(false)
  }

  return (
    <Box>
      {isLoading && <LinearProgress />}
      <NavBar />
      <TitleWithButton title="Parceiros" handleClickAdd={openDrawer} totalRegistros={!isLoading && data ? data.totalRegistros : 0} />
      <Box sx={{ px: 4, mt: 3 }}>
        <Card sx={{ height: "100%", border: 1, borderColor: "#D8D8D8" }}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ mb: 2, p: 2, mt: 1 }}
          >
            <Typography sx={{ fontWeight: 300, fontSize: 24 }}>
              Filtros
            </Typography>
            <InputSearchQuery label="CPF" setQuery={setSearchCPF} />
            <InputSearchQuery label="Email" setQuery={setSearchEmail} />
            <Button onClick={filterNAceitos} endIcon={isAccepted === false ? <SearchOffIcon /> : <PersonSearchIcon />} variant='contained' color={isAccepted === false ? "error" : "primary"}>Não Aceitos</Button>
          </Stack>

          <TableParceiro
            rows={data && data.data}
            handleViewDetalhe={handleViewDetalhe}
            handleAbrirModalPermissao={handleAbrirModalPermissao}
            handleAbrirModalGrupoPermissao={handleAbrirModalGrupoPermissao}
            handleDownloadContrato={handleDownloadContrato}
            handleEnviarContratoAssinatura={handleEnviarContratoAssinatura}
            handleDowloadContratoAssinado={handleDowloadContratoAssinado}
          />

          <TableLoading data={data} isLoading={isLoading} />
          {!isLoading && data && data.totalRegistros === 0 && <RegistroNaoEncontrado />}

        </Card>
      </Box>
      <PaginationData isShow={!isLoading && data && data.totalPages > 1} count={!isLoading && data && data.totalPages} handleChangePage={handleChangePage} page={page} />
      <DWParceiro open={open} toggleDrawer={toggleDrawer} title={titleDW} viewData={viewData} setViewData={setViewData} />
      {userIDPermissao && (
        <ModalPermissaoParceiro
          userID={userIDPermissao}
          currentPermissions={currentPermissions}
          open={modalPermissao}
          funcaoHandleClose={handleFecharModalPermissao}
          addPermissaoUser={addPermissaoAdvogado}
          deletePermissaoUser={deletePermissaoAdvogado}
        />
      )}
      {userIDGrupoPermissao && (
        <ModalGrupoPermissaoParceiro
          userID={userIDGrupoPermissao}
          open={ModalOpenGrupoPermissao}
          funcaoHandleClose={handleFecharModalGrupoPermissao}
          addGrupoPermissao={addGrupoPermissaoAdvogado}
          deleteGrupoPermissao={deleteGrupoPermissaoAdvogado}
        />
      )}
      <Carregando isLoading={loading} />
    </Box>
  );
}
