import * as React from "react";
import { Button, CircularProgress, Stack, TextField } from "@mui/material";

export const FormAddOpcao = ({ funcaoCriar }) => {
  const [value, setValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  async function saveOpcao() {
    setIsLoading(true);
    try {
      await funcaoCriar(value);
      setIsLoading(false);
      setValue("");
    } catch (error) {
      setIsLoading(false);
    }
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      spacing={1}
      ml={2}
      p={1}
    >
      <TextField
        onChange={handleInputChange}
        value={value}
        size="small"
        label="Nova opção"
        variant="outlined"
      />
      <Button
        disabled={isLoading || !value}
        startIcon={isLoading && <CircularProgress size={20} color="inherit" />}
        onClick={saveOpcao}
        size="medium"
        variant="contained"
      >
        {isLoading ? "Carregando..." : "Salvar"}
      </Button>
    </Stack>
  );
};
