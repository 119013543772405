import React from 'react';
import ControlledCheckbox from '../CheckboxForm';
import { InputFormSearchIcon } from '../InputFormSearchIcon';
import { InputForm } from '../InputForm';

const InpultType = ({ current, formik, type, disabled }) => {
  if (current.checkbox) {
    return <ControlledCheckbox id={current.name} label={current.label} formik={formik}  disabled={disabled} />;
  } else if (current.search) {
    return <InputFormSearchIcon id={current.name} label={current.label} formik={formik}  disabled={disabled} />;
  } else {
    return <InputForm id={current.name} label={current.label} formik={formik} type={type} disabled={disabled} />;
  }
};

export default InpultType;
