import * as React from "react";
import {
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import ModeIcon from "@mui/icons-material/Mode";
import ShareIcon from '@mui/icons-material/Share';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LaunchIcon from '@mui/icons-material/Launch';

export const ListFormularios = ({ id, title, borderBottom }) => {
    const origin = window.location.origin
    let navigate = useNavigate();


    function handleEditar() {
        console.log("handleEditar", id)
        navigate(`/formulario/editar/${id}`)
    }

    function handleAbrir() {
        navigate(`/formulario/${id}`)
    }

    function handleRespostas() {
        console.log("handleRespostas")
    }


    return (
        <ListItem
            sx={{ borderBottom, borderColor: "divider" }}
        >
            <ListItemIcon>
                <LabelIcon />
            </ListItemIcon>
            <ListItemText primary={title} />
            <Tooltip title="Editar">
                <IconButton onClick={handleEditar} aria-label="Permissões">
                    <ModeIcon />
                </IconButton>
            </Tooltip>
            {/* <Tooltip title="Resultados">
                <IconButton onClick={handleRespostas} aria-label="Editar">
                    <ListAltIcon />
                </IconButton>
            </Tooltip> */}
            <Tooltip title="Abrir">
                <IconButton onClick={handleAbrir} aria-label="Apagar">
                    <LaunchIcon />
                </IconButton>
            </Tooltip>
        </ListItem>
    );
};
