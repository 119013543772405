import { useQuery } from "react-query";
import api from "../../service/api";

async function getConfigContratosTemplates(ctx) {
  const [, page] = ctx.queryKey
  const { data } = await api.get('/api/contratos/templates', { params: { page, limit: 20 } })
  return data
}

export default function useFetchGetConfigContratosTemplates(page) {
    return useQuery(['configContratosTemplates', page], getConfigContratosTemplates, { refetchOnWindowFocus: false})
}