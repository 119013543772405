import * as React from "react";
import {
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from "@mui/material";
import ModeIcon from "@mui/icons-material/Mode";
import PersonIcon from '@mui/icons-material/Person';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupsIcon from '@mui/icons-material/Groups';

export const ListUserDeatlhe = ({ handleAbrirModalGrupoPermissao, handleAbrirModalPermissao, id, title, borderBottom, isActivated, handleEditarUsuario, current, chanceStatusConfigUser }) => {
    const [userActivated, setUserActivated] = React.useState(isActivated);


    function handleEditar() {
        handleEditarUsuario(current)
    }

    function handleAtivar() {
        setUserActivated(true)
        chanceStatusConfigUser("activ", id)
    }

    function handleDesativar() {
        setUserActivated(false)
        chanceStatusConfigUser("desactiv", id)
    }

    function handlePermissao() {
        handleAbrirModalPermissao(id)
    }

    function handleGrupoPermissao() {
        handleAbrirModalGrupoPermissao(id)
    }

    return (
        <ListItem
            sx={{ borderBottom, borderColor: "divider" }}
        >
            <ListItemIcon>
                <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={title} />
            <Tooltip title="Editar">
                <IconButton onClick={handleEditar} aria-label="Editar">
                    <ModeIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Permissões">
                <IconButton onClick={handlePermissao} aria-label="Permissões">
                    <ManageAccountsIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Grupo permissões">
                <IconButton onClick={handleGrupoPermissao} aria-label="Permissões">
                    <GroupsIcon />
                </IconButton>
            </Tooltip>
            {userActivated ? <Tooltip title="Desativar">
                <IconButton onClick={handleDesativar} aria-label="Desativar">
                    <ToggleOnIcon color="success" />
                </IconButton>
            </Tooltip> :
                <Tooltip title="Ativar">
                    <IconButton onClick={handleAtivar} aria-label="Ativar">
                        <ToggleOffIcon color="error" />
                    </IconButton>
                </Tooltip>}
        </ListItem>
    );
};
