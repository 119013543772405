
import * as React from 'react';
import { Box, Button, Stack, Step, StepLabel, Stepper, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SendIcon from '@mui/icons-material/Send';
import { SearchCep } from '../components/SearchCep';
import usePlataforma from '../hooks/usePlataforma';
import { Carregando } from '../components/Carregando';
import NumberFormat from 'react-number-format';
import { Upload } from 'antd';

import { InboxOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const steps = ['Informações pessoais', 'Endereço', 'Dados bancários', 'Dados de acesso', "Enviar docs"];

var inputsInfo = [
  { nome: "nome", label: "Nome", tamanho: 6, search: false, type: "text" },
  { nome: "cpf", label: "CPF", tamanho: 6, search: false, type: "text", isCPF: true },
  { nome: "oab", label: "Oab", tamanho: 12, search: false, type: "text" },
]

var inputsEndereço = [
  { nome: "cep", label: "Cep", tamanho: 12, search: true, type: "text" },
  { nome: "rua", label: "Rua", tamanho: 12, search: false, type: "text" },
  { nome: "cidade", label: "Cidade", tamanho: 6, search: false, type: "text" },
  { nome: "estado", label: "Estado", tamanho: 6, search: false, type: "text" },
  { nome: "bairro", label: "Bairro", tamanho: 6, search: false, type: "text" },
  { nome: "numeroCasa", label: "Número", tamanho: 6, search: false, type: "text" },
  { nome: "complementoEndereco", label: "Complemento", tamanho: 12, search: false, type: "text" },
]

var inputsDadosBancario = [
  { nome: "nomeBanco", label: "Nome Banco", tamanho: 12, search: false, type: "text" },
  { nome: "codigoBanco", label: "Código do Banco", tamanho: 12, search: false, type: "text" },
  { nome: "agencia", label: "Agência", tamanho: 12, search: false, type: "text" },
  { nome: "conta", label: "Conta", tamanho: 12, search: false, type: "text" },
]

var inputsDadosAcesso = [
  { nome: "email", label: "Email", tamanho: 6, search: false, type: "text" },
  { nome: "senha", label: "Nova Senha", tamanho: 6, search: false, type: "text" },
]

export const CadastroParceiro = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const { addEndereco, novaContaAdvogado, getAccess_tokenAdvogado, UploadDocumentoOab, advogadoSaveOab } = usePlataforma()
  const [fileList, setFileList] = React.useState([]);
  const [access_token, setAccess_token] = React.useState(null);
  const [advogado, setAdvogado] = React.useState(null);
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      nome: "",
      cpf: "",
      oab: "",
      cep: "",
      rua: "",
      cidade: "",
      estado: "",
      bairro: "",
      numeroCasa: "",
      complementoEndereco: "",
      nomeBanco: "",
      codigoBanco: "",
      agencia: "",
      conta: "",
      email: "",
      senha: "",
      idEndereco: "",
      enderecoEncontrado: false
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Deve ser um e-mail válido').required('E-mail é obrigatório'),
      nome: Yup.string().required('Campo obrigatório.'),
      cpf: Yup.string().required('Campo obrigatório.'),
      oab: Yup.string().required('Campo obrigatório.'),
      cep: Yup.string().required('Campo obrigatório.'),
      rua: Yup.string().required('Campo obrigatório.'),
      cidade: Yup.string().required('Campo obrigatório.'),
      estado: Yup.string().required('Campo obrigatório.'),
      bairro: Yup.string().required('Campo obrigatório.'),
      numeroCasa: Yup.string().required('Campo obrigatório.'),
      nomeBanco: Yup.string().required('Campo obrigatório.'),
      agencia: Yup.string().required('Campo obrigatório.'),
      conta: Yup.string().required('Campo obrigatório.'),
      senha: Yup.string().required('Campo obrigatório.'),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        var idEndereco = values.idEndereco
        if (!values.enderecoEncontrado) {
          const endereco = await addEndereco(values)
          idEndereco = endereco.id
        }
        const responseAdvogado = await novaContaAdvogado(values, idEndereco)
        setAdvogado(responseAdvogado)
        const responseAccess_token = await getAccess_tokenAdvogado(values)
        setAccess_token(responseAccess_token)
        handleNext()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    },
  });


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSaveOab = async () => {
    try {
      setIsLoading(true)
      const upload = await UploadDocumentoOab(fileList, access_token)
      await advogadoSaveOab(advogado.id, upload.id, access_token)
      setIsLoading(false)
      navigate("/login-parceiro")
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  };

  const props = {
    name: 'file',
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const { Dragger } = Upload;

  React.useEffect(() => {
    if(formik.values.cep){
      formik.setFieldTouched("numeroCasa", true, true)
    }
  }, [formik.values.cep]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
      minHeight="100vh"
      p={2}
    >

      <Box sx={{ textAlign: "center", maxWidth: 250 }}>
        <img
          alt="logo"
          src="/logo.png"
          style={{
            display: "inline-block",
            width: '100%',
          }}
        />
      </Box>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ width: { xs: "90%", lg: 700 } }}
      >
        <Stepper activeStep={activeStep} alternativeLabel sx={{ width: { lg: 700 } }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && inputsInfo.map(current => (
          <React.Fragment key={current.nome}>
            {current.isCPF ? (
              <NumberFormat
                format="###.###.###-##"
                customInput={TextField}
                mask="_"
                fullWidth
                size='small'
                label={current.label}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={Boolean(formik.touched[current.nome] && formik.errors[current.nome])}
                helperText={formik.touched[current.nome] && formik.errors[current.nome]}
                name={current.nome}
                value={formik.values[current.nome]}
                variant="outlined" />
            ) : (
              <TextField
                fullWidth
                size='small'
                label={current.label}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={Boolean(formik.touched[current.nome] && formik.errors[current.nome])}
                helperText={formik.touched[current.nome] && formik.errors[current.nome]}
                name={current.nome}
                value={formik.values[current.nome]}
                variant="outlined" />
            )}
          </ React.Fragment>
        ))}

        {activeStep === 1 && inputsEndereço.map(current => (

          <React.Fragment key={current.nome}>
            {current.search ? (
              <SearchCep id={current.nome} formik={formik} label={current.label} />
            ) : (
              <TextField
                fullWidth
                size='small'
                label={current.label}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={Boolean(formik.touched[current.nome] && formik.errors[current.nome])}
                helperText={formik.touched[current.nome] && formik.errors[current.nome]}
                name={current.nome}
                value={formik.values[current.nome]}
                variant="outlined" />
            )}
          </React.Fragment>
        ))}

        {activeStep === 2 && inputsDadosBancario.map(current => (
          <TextField
            key={current.nome}
            fullWidth
            size='small'
            label={current.label}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched[current.nome] && formik.errors[current.nome])}
            helperText={formik.touched[current.nome] && formik.errors[current.nome]}
            name={current.nome}
            value={formik.values[current.nome]}
            variant="outlined" />
        ))}

        {activeStep === 3 && inputsDadosAcesso.map(current => (
          <TextField
            key={current.nome}
            fullWidth
            size='small'
            label={current.label}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched[current.nome] && formik.errors[current.nome])}
            helperText={formik.touched[current.nome] && formik.errors[current.nome]}
            name={current.nome}
            value={formik.values[current.nome]}
            variant="outlined" />
        ))}

        {activeStep === 4 && (
          <>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <Box p={2}>
                <p className="ant-upload-text">Faça o upload de sua carteira da OAB.</p>
                <p className="ant-upload-hint">
                  Documento obrigatório para criação da conta.
                </p>
              </Box>
            </Dragger>
            <Button onClick={handleSaveOab} disabled={fileList.length === 0} sx={{ width: 250 }} variant='contained'>ENVIAR</Button>
          </>
        )}
      </Stack>

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        pt={2}
      >
        {activeStep < 4 && <Button disabled={activeStep === 0} startIcon={<NavigateBeforeIcon />} onClick={handleBack} fullWidth variant="outlined">Anterior</Button>}
        {activeStep <= 2 && <Button endIcon={<NavigateNextIcon />} onClick={handleNext} fullWidth variant="contained">Próximo</Button>}
        {activeStep === 3 && <Button disabled={!formik.isValid || formik.isSubmitting} endIcon={<SendIcon />} onClick={formik.handleSubmit} fullWidth variant="contained">Salvar</Button>}
      </Stack>

      <Carregando isLoading={isLoading} />
    </Box>
  )
}
