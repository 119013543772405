import { useQuery } from "react-query";
import api from "../../service/api";

async function FormularioResposta(ctx) {
  const [, id] = ctx.queryKey
  const { data } = await api.get(`api/formularios/resposta/${id}`)
  return data
}

export default function useFetchFormularioResposta(id) {
  return useQuery(['getFormularioResposta', id], FormularioResposta, { refetchOnWindowFocus: false, retry: 0 })
}