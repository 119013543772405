import * as React from 'react';
import { Box, Button, Card, Chip, Divider, Grid, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Skeleton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { NavBar } from '../components/NavBar';
import { TitlePage } from '../components/TitlePage';
import AddIcon from '@mui/icons-material/Add';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { StatusSolicitacao } from '../components/StatusSolicitacao';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useFetchGetPeticaoFinanceiro from '../queries/peticaoFinanceiro';
import { PaginationData } from '../components/PaginationData';
import useFetchGetListAdiantamento from '../queries/listas-adiantamento';

import MessageIcon from '@mui/icons-material/Message';
import usePlataforma from '../hooks/usePlataforma';
import useUser from '../providers/useUser';
import { RegistroNaoEncontrado } from '../components/RegistroNaoEncontrado';
import NumberFormat from 'react-number-format';
import SettingsIcon from '@mui/icons-material/Settings';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RecommendIcon from '@mui/icons-material/Recommend';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

export const Financeiro = () => {
  const location = useLocation();
  const { state } = location
  const [open, setOpen] = React.useState(state ? true : false);
  const [page, setPage] = React.useState(1);
  const [isLoadingPostPeticao, setIsLoadingPostPeticao] = React.useState(false);

  const { isLoading, data } = useFetchGetPeticaoFinanceiro()
  const { isLoading: isLoadingListAdiantamento, data: dataListAdiantamento } = useFetchGetListAdiantamento(page)

  const podeVisualizarComponente = useUser((state) => state.podeVisualizarComponente)

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <Grid container spacing={1}>

      <Grid item lg={12}>
        {(isLoading || isLoadingListAdiantamento || isLoadingPostPeticao) && <LinearProgress />}
        <NavBar />
      </Grid>



      <Grid item lg={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <TitlePage title="Financeiro" />
          {podeVisualizarComponente(["advogado"]) && <Box pr={3}>
            <Button disabled={isLoading} onClick={() => setOpen(true)} size='small' startIcon={<AddIcon />} variant='contained'>SOLICITAR ADIANTAMENTO</Button>
          </Box>}
        </Stack>
      </Grid>

      {isLoadingListAdiantamento && <Grid item lg={12}>
        <Box px={4}>
          <Card sx={{ height: "100%", border: 1, borderColor: "#D8D8D8" }}>
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Box mt={1}>
              <Skeleton variant="rectangular" width="100%" height={350} />
            </Box>
          </Card>
        </Box>
      </Grid>}

      <Grid item lg={12}>
        {!isLoadingListAdiantamento && <Box py={2} px={4}>
          <Card sx={{ height: "100%", border: 1, borderColor: "#D8D8D8" }}>
            {dataListAdiantamento && <TableList podeVisualizarComponente={podeVisualizarComponente} data={dataListAdiantamento.data} />}
            {!isLoadingListAdiantamento && dataListAdiantamento && dataListAdiantamento.totalRegistros === 0 && <RegistroNaoEncontrado />}
          </Card>
        </Box>}
      </Grid>
      <Grid item lg={12}>
        <PaginationData isShow={!isLoadingPostPeticao && dataListAdiantamento && dataListAdiantamento.totalPages > 1} count={!isLoadingPostPeticao && dataListAdiantamento && dataListAdiantamento.totalPages} handleChangePage={handleChangePage} page={page} />
      </Grid>
      <Grid item lg={12} />
      <ModalDialog isLoadingPostPeticao={isLoadingPostPeticao} setIsLoadingPostPeticao={setIsLoadingPostPeticao} open={open} setOpen={setOpen} peticoes={data} stateIdPeticao={state && state.idPeticao} />
    </Grid >
  );
}

function TableList({ data, podeVisualizarComponente }) {
  const [adiantamento, setAdiantamento] = React.useState(null);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Protocolo</TableCell>
              <TableCell>Valor solicitado</TableCell>
              <TableCell>Valor pago</TableCell>
              <TableCell>Dt. Previsão de Pagamento</TableCell>
              <TableCell>Dt.Pagamento</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Status</TableCell>
              {podeVisualizarComponente(["user"]) && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
              >
                <TableCell component="th" scope="row">
                  {row.peticao ? row.peticao.protocolo : row.idPeticao}
                </TableCell>
                <TableCell>{row.valorSolicitado ? row.valorSolicitado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "-"}</TableCell>
                <TableCell>{row.valorPago ? row.valorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "-"}</TableCell>
                <TableCell>{row.dataPrevisaoPagamento ? moment(row.dataPrevisaoPagamento).format("DD/MM/YYYY") : "-"}</TableCell>
                <TableCell>{row.dataPagamento ? moment(row.dataPagamento).format("DD/MM/YYYY") : "-"}</TableCell>
                <TableCell><BasicPopover descricaoRecusado={row.descricaoRecusado} /></TableCell>
                <TableCell><StatusSolicitacao status={row.status} /></TableCell>
                {podeVisualizarComponente(["user"]) && <TableCell>
                  {(row.status === "PAGO" || row.status === "RECUSADO") ? (
                    <IconButton onClick={() => setAdiantamento({ id: row.id, status: row.status })} disabled={row.status === "PAGO" || row.status === "RECUSADO"} aria-label="delete">
                      <SettingsIcon />
                    </IconButton>
                  ) : (
                    <Tooltip title="Alterar status">
                      <IconButton onClick={() => setAdiantamento({ id: row.id, status: row.status })} disabled={row.status === "PAGO" || row.status === "RECUSADO"} aria-label="delete">
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {adiantamento && <ModalAlterarStatus adiantamento={adiantamento} setAdiantamento={setAdiantamento} />}
    </>
  );
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


function ModalDialog({ open, setOpen, peticoes, setIsLoadingPostPeticao, isLoadingPostPeticao, stateIdPeticao }) {
  const [valorSolicitado, setValorSolicitado] = React.useState('');
  const [idPeticao, setIdPeticao] = React.useState("");
  const { postAdiantamento } = usePlataforma()
  const [carregandoState, setCarregandoState] = React.useState(Boolean(stateIdPeticao));
  let navigate = useNavigate();

  const handleSolicitarAdiantamento = () => navigate("/financeiro", { state: null });

  const handleClose = () => {
    setOpen(false);
    setValorSolicitado('')
    setIdPeticao('')
    handleSolicitarAdiantamento()
  };

  const handleChangeValor = (event) => {
    setValorSolicitado(event.target.value);
  };

  const handleChangeIdPeticao = (event) => {
    setIdPeticao(event.target.value);
  };

  const handleClick = async (event) => {
    setIsLoadingPostPeticao(true)
    try {
      var valorSolicitadoFormated = parseFloat(valorSolicitado.replace(/\./g, '').replace(',', '.'))
      await postAdiantamento(idPeticao, valorSolicitadoFormated)
      setIsLoadingPostPeticao(false)
      handleClose()
    } catch (error) {
      setIsLoadingPostPeticao(false)
    }
  };

  const valorRef = React.useRef(null);

  React.useEffect(() => {

    if (valorRef.current && stateIdPeticao) {
      valorRef.current.focus();
    }

    if (peticoes && stateIdPeticao) {
      setIdPeticao(stateIdPeticao)
      setCarregandoState(false)
    }

  }, [stateIdPeticao, valorRef.current, peticoes]);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Solicitar Adiantamento
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {(isLoadingPostPeticao || carregandoState) && <Box>
        <LinearProgress />
      </Box>}
      <DialogContent dividers p={2} sx={{ minWidth: 500 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Selecione uma petição</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={idPeticao}
            label="Selecione uma petição"
            onChange={handleChangeIdPeticao}
          >
            {peticoes && peticoes.data.map(current => (
              <MenuItem key={current.id} value={current.id}>{`#${current.protocolo} - ${current.nomeReclamante}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box mt={2}>
          <NumberFormat
            inputRef={valorRef}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextField}
            value={valorSolicitado}
            onChange={handleChangeValor}
            fullWidth
            label="Valor desejado"
            variant="outlined" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoadingPostPeticao} variant='contained' onClick={handleClick}>
          Solicitar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

function BasicPopover({ descricaoRecusado }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button variant="text" startIcon={<MessageIcon />} size='small' disabled={!descricaoRecusado} aria-describedby={id} onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={6}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box sx={{ minWidth: 250, maxWidth: 250, p: 2, border: '1px solid #D8D8D8', borderRadius: 1 }}>
          <Typography>{descricaoRecusado}</Typography>
        </Box>
      </Popover>
    </div>
  );
}


function ModalAlterarStatus({ setAdiantamento, adiantamento }) {
  const status = adiantamento.status

  const [isLoading, setIsLoading] = React.useState(false);

  const [valorSolicitado, setValorSolicitado] = React.useState('');
  const [motivoRecusa, setMotivoRecusa] = React.useState('');
  const [dataPrevisaoPagamento, setDataPrevisaoPagamento] = React.useState('');

  const { postPagarAdiantamento, postRecusarAdiantamento, postAprovarAdiantamento } = usePlataforma()

  const [cliquePago, setCliquePago] = React.useState(false);
  const [cliqueRecusado, setCliqueRecusado] = React.useState(false);
  const [cliqueAprovado, setCliqueAprovado] = React.useState(false);

  const cliquePagoRef = React.useRef(null);
  const cliqueRecusadoRef = React.useRef(null);
  const cliqueAprovadoRef = React.useRef(null);

  const handleClose = () => {
    setAdiantamento(null);
    setCliquePago(false)
    setCliqueRecusado(false)
    setCliqueAprovado(false)
    setValorSolicitado('')
    setMotivoRecusa('')
    setDataPrevisaoPagamento('')
  };

  const handleChangeValor = (event) => {
    setValorSolicitado(event.target.value);
  };

  const handleChangeMotivoRecusa = (event) => {
    setMotivoRecusa(event.target.value);
  };

  const handleChangeDataPrevisaoPagamento = (event) => {
    setDataPrevisaoPagamento(event.target.value);
  };


  const handleClick = async (event) => {
    setIsLoading(true)
    try {
      var valorSolicitadoFormated = parseFloat(valorSolicitado.replace(/\./g, '').replace(',', '.'))

      if (cliquePago) {
        await postPagarAdiantamento(adiantamento.id, valorSolicitadoFormated)
      }

      if (cliqueRecusado) {
        await postRecusarAdiantamento(adiantamento.id, motivoRecusa)
      }

      if (cliqueAprovado) {
        await postAprovarAdiantamento(adiantamento.id, dataPrevisaoPagamento)
      }

      setIsLoading(false)
      handleClose()
    } catch (error) {
      setIsLoading(false)
    }
  };

  React.useEffect(() => {

    if (cliquePagoRef.current && cliquePago) {
      cliquePagoRef.current.focus();
    }

    if (cliqueRecusadoRef.current && cliqueRecusado) {
      cliqueRecusadoRef.current.focus();
    }

    if (cliqueAprovadoRef.current && cliqueAprovado) {
      cliqueAprovadoRef.current.focus();
    }

  }, [cliquePago, cliqueRecusado, cliqueAprovado]);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={Boolean(adiantamento)}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Alterar status
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {isLoading && <Box>
        <LinearProgress />
      </Box>}
      <DialogContent dividers p={2} sx={{ minWidth: 500 }}>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          aria-label="contacts"
        >

          <ListItem disablePadding sx={{ border: cliqueAprovado ? 2 : 1, borderColor: cliqueAprovado ? "primary.main" : "#D8D8D8", borderRadius: 1 }}>
            <ListItemButton disabled={status === "PAGO" || status === "RECUSADO" || status === "EM_PROCESSAMENTO" || cliquePago || cliqueRecusado} onClick={() => setCliqueAprovado(true)} selected={cliqueAprovado}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
                sx={{ width: "100%" }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  p={1}
                  sx={{ width: "100%" }}
                >
                  <ListItemIcon>
                    {cliqueAprovado ? <RadioButtonCheckedIcon color='primary' /> : <RadioButtonUncheckedIcon />}
                  </ListItemIcon>
                  <Chip size="small" sx={{ border: 0, cursor: "pointer" }} variant='outlined' icon={<RecommendIcon />} label="APROVAR" />
                </Stack>
                {cliqueAprovado && <>
                  <Box sx={{ width: "100%" }}><Divider /></Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: "100%" }}
                  >
                    <Typography>Previsão de Pagamento:</Typography>
                    <TextField inputRef={cliqueAprovadoRef} value={dataPrevisaoPagamento}
                      onChange={handleChangeDataPrevisaoPagamento} sx={{ width: "55%" }} size='small' variant="outlined" type='date' />
                  </Stack>
                </>}
              </Stack>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ border: cliquePago ? 2 : 1, borderColor: cliquePago ? "primary.main" : "#D8D8D8", borderRadius: 1, my: 1 }}>
            <ListItemButton disabled={status === "PAGO" || status === "RECUSADO" || cliqueRecusado || cliqueAprovado} onClick={() => setCliquePago(true)} selected={cliquePago}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
                sx={{ width: "100%" }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  p={1}
                  sx={{ width: "100%" }}
                >
                  <ListItemIcon>
                    {cliquePago ? <RadioButtonCheckedIcon color='primary' /> : <RadioButtonUncheckedIcon />}
                  </ListItemIcon>
                  <Chip size="small" sx={{ border: 0, cursor: "pointer" }} variant='outlined' icon={<CheckCircleIcon />} label="PAGO" />
                </Stack>
                {cliquePago && <>
                  <Box sx={{ width: "100%" }}><Divider /></Box>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: "100%" }}
                  >
                    <Typography>Valor pago:</Typography>
                    <NumberFormat
                      inputRef={cliquePagoRef}
                      sx={{ width: "55%" }}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      customInput={TextField}
                      value={valorSolicitado}
                      onChange={handleChangeValor}
                      fullWidth
                      size='small'
                      variant="outlined" />
                  </Stack>
                </>}
              </Stack>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ border: cliqueRecusado ? 2 : 1, borderColor: cliqueRecusado ? "primary.main" : "#D8D8D8", borderRadius: 1 }}>
            <ListItemButton disabled={status === "PAGO" || status === "RECUSADO" || cliquePago || cliqueAprovado} onClick={() => setCliqueRecusado(true)} selected={cliqueRecusado}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
                sx={{ width: "100%" }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  p={1}
                  sx={{ width: "100%" }}
                >
                  <ListItemIcon>
                    {cliqueRecusado ? <RadioButtonCheckedIcon color='primary' /> : <RadioButtonUncheckedIcon />}
                  </ListItemIcon>
                  <Chip size="small" sx={{ border: 0, cursor: "pointer" }} variant='outlined' icon={<RemoveCircleIcon />} label="RECUSAR" />
                </Stack>
                {cliqueRecusado && <>
                  <Box sx={{ width: "100%" }}><Divider /></Box>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    sx={{ width: "100%" }}
                    mt={0.5}
                  >
                    <TextField
                      inputRef={cliqueRecusadoRef}
                      value={motivoRecusa}
                      onChange={handleChangeMotivoRecusa}
                      multiline rows={4} fullWidth size='small' label="Motivo Recusada" variant="outlined" type='date' />
                  </Stack>
                </>}
              </Stack>
            </ListItemButton>
          </ListItem>


        </List>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} variant='outlined' onClick={handleClose}>
          Cancelar
        </Button>
        <Button disabled={isLoading} variant='contained' onClick={handleClick}>
          Salvar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}