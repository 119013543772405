import React, { useRef } from 'react';
import { Box, Typography, TextField, LinearProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import api from '../service/api';
import { useState } from 'react';
import NumberFormat from "react-number-format";

export const InputSearchCep = ({ id, label, formik, disabled=false, maxLength=8 }) => {
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  
const handleTypographyClick = () => {
    inputRef.current?.focus();
  };

  const handleSearch = () => {
    searchApi();
  };

  const searchApi = async () => {
    try {
      setIsLoading(true)
      const endpoint = `/api/enderecoFindByCep/${formik.values[id]}`
      const response = await api.get(endpoint);
      formEndereco(response.data);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Erro ao buscar', error);
    }
  };


  const formEndereco = (data) => {
    if (data && data.length > 0) {
      const { estado, cidade, bairro, rua, id } = data[0];

      formik.setFieldValue('cidade', cidade || "");
      formik.setFieldValue('rua', rua || "");
      formik.setFieldValue('estado', estado || "");
      formik.setFieldValue('bairro', bairro || "");
      formik.setFieldValue('idEndereco', id || "");
      formik.setFieldValue('enderecoEncontrado', true);
    }
  };

  return (
    <Box>
      <NumberFormat
        customInput={TextField}
        format="#####-###"
        mask="_"
        placeholder="Digite um CEP"
        fullWidth
        size='small'
        variant="outlined"
        id={id}
        name={id}
        inputRef={inputRef}
        label={label}
        value={formik.values[id]}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={Boolean(formik.touched[id] && formik.errors[id])}
        helperText={formik.touched[id] && formik.errors[id]}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <SearchIcon
              sx={{ cursor: 'pointer' }}
              onClick={handleSearch}
              color="primary"
            />
          ),
        }}
      />
      {isLoading && <Box mt={0.5} px={1}>
      <LinearProgress />
      </Box> }
    </Box>
  );
};
