import * as React from "react";
import {
  Skeleton,
  Stack,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";

export const ListItemPermissaoLoading = () => {
  return (
    <ListItem disablePadding sx={{ width: "100%" }}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2.5}
        sx={{ width: "100%" }}
      >
        <Skeleton variant="rounded" width={40} height={23} />
        <Skeleton variant="rounded" width={46} height={23} />
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "100%", height: 37 }} />
      </Stack>
  </ListItem>
  );
}
