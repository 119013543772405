/**
 * Remove as chaves especificadas de um objeto.
 * @param {Object} objeto - O objeto original.
 * @param {string[]} chavesParaRemover - Um array contendo as chaves a serem removidas.
 * @returns {Object} - Um novo objeto sem as chaves especificadas.
 */

export function deleteKeys(objeto, chavesParaRemover) {
    const novoObjeto = { ...objeto };

    chavesParaRemover.forEach(chave => {
        if (chave in novoObjeto) {
            delete novoObjeto[chave];
        }
    });

    return novoObjeto;
}
