import * as React from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListSubheader,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { TitlePage } from "../../TitlePage";
import usePlataforma from "../../../hooks/usePlataforma";
import useFetchGetNotificaoActions from "../../../queries/notificao-actions";
import { ListEditNotificacao } from "../../ListEditNotificacao";
import { useState } from "react";
import { toast } from "react-toastify";

export const Notificacoes = () => {
  const { data, isLoading } = useFetchGetNotificaoActions()
  const { postNotificaoMensagens, editNotificaoMensagens } = usePlataforma()

  const [action, setAction] = useState("");
  const [type, setType] = useState("");
  const [text, setText] = useState("");
  const [subject, setSubject] = useState("");
  const [mensagemID, setMensagemID] = useState("");

  const [isNewNotificacao, setIsNewNotificacao] = useState(false);
  const [isClickEditNotificacao, setIsClickEditNotificacao] = useState(false);

  const [isLoadingApi, setIsLoadingApi] = useState(false);

  function creatDataNotificao({ mensagem, isNewNotificacao, currentType, currentAction }) {
    setIsClickEditNotificacao(true)
    if (!currentType && !currentAction) {
      const { id, action, type, text, subject } = mensagem
      setIsNewNotificacao(isNewNotificacao)
      setAction(action)
      setType(type)
      setText(text)
      setSubject(subject)
      setMensagemID(id)
    } else {
      setType(currentType)
      setAction(currentAction)
    }
  }

  function limparDataNotificao() {
    setIsClickEditNotificacao(false)
    setAction("")
    setType("")
    setText("")
    setSubject("")
    setMensagemID("")
    setIsNewNotificacao(false)
  }

  async function handleSalvar() {
    setIsLoadingApi(true)
    try {
      if (isNewNotificacao) {
        await editNotificaoMensagens({ mensagemID, action, type, text, subject })
        toast.success("Notificação editada com sucesso!")
      } else {
        await postNotificaoMensagens({ action, type, text, subject })
        toast.success("Notificação criada com sucesso!")
      }
      setIsLoadingApi(false)
      limparDataNotificao()
    } catch (error) {
      setIsLoadingApi(false)
      console.log(error)
    }
  }

  const handleInputTextChange = (event) => {
    setText(event.target.value);
  };

  const handleInputSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Skeleton variant="rounded" width="100%" height="100%" />
          <CircularProgress style={{ position: 'absolute' }} />
        </Box>
      ) : (
        <Card sx={{ height: "100%" }}>
          <TitlePage title="Notificações" />
          <Box py={1}>
            <Divider />
            {isLoadingApi && <LinearProgress />}
          </Box>

          {isClickEditNotificacao ? (
            <Box px={2} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>{isNewNotificacao ? "EDITANDO" : "CRIANDO"} NOTIFICAÇÃO <strong>{type}</strong></Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField onChange={handleInputSubjectChange} value={subject} fullWidth label="Assunto" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={handleInputTextChange}
                    value={text}
                    fullWidth
                    label="Mensagem"
                    multiline
                    rows={6}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Button disabled={isLoadingApi} onClick={limparDataNotificao} variant="outlined">Cancelar</Button>
                    <Button disabled={isLoadingApi} onClick={handleSalvar} variant="contained">Salvar</Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          ) : (<List
            sx={{ width: "100%", bgcolor: "#bcd7f330" }}
            subheader={<ListSubheader>Opções</ListSubheader>}
          >
            {data && data.map((current, index, arr) => (
              <ListEditNotificacao
                key={index}
                borderBottom={index === arr.length - 1 ? 0 : 1}
                currentItem={current}
                creatDataNotificao={creatDataNotificao}
              />
            ))}
          </List>)}

        </Card>
      )}
    </React.Fragment>
  );
};