import { useQuery } from "react-query";
import api from "../../service/api";

async function getForm(ctx) {
  const [, id] = ctx.queryKey
  const { data } = await api.get(`/api/form/${id}`)
  return data
}

export default function useFetchGetForm(id) {
  return useQuery(['useFetchGetForm', id], getForm, { refetchOnWindowFocus: true, retry: 0 })
}