import { useQuery } from "react-query";
import api from "../../service/api";

async function getAdvogado(ctx) {
  const [, page, cpf, email, isAccepted] = ctx.queryKey
  const { data } = await api.get('/api/advogado', { params: { page, orderBy: "desc", cpf, email, isAccepted } })
  return data
}

export default function useFetchGetAdvogado(page, cpf, email, isAccepted) {
    return useQuery(['advogado', page, cpf, email, isAccepted], getAdvogado, { refetchOnWindowFocus: false})
}