import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import api from '../service/api';

const FilterCliente = ({ setFilter }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const fetchOptions = async (inputValue) => {
        try {
            setIsLoading(true);
            const response = await api.get(`/api/cliente?nome=${inputValue}`);
            setOptions(response.data);
            setIsLoading(false);
        } catch (error) {
            setOptions([])
            setIsLoading(false);
            console.error('Erro ao buscar as opções:', error);
        }
    };

    const handleOptionSelect = (event, value) => {
        var newSelect = value ? value.id : null
        setFilter(newSelect);
    };

    const isOptionEqualToValue = (option, value) => option.id === value.id;

    return (
            <Autocomplete
                fullWidth
                loadingText="Buscando..."
                noOptionsText="Não encontrada."
                loading={isLoading}
                options={options}
                getOptionLabel={(option) => option?.nome || ''}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={handleOptionSelect}
                onInputChange={(event, value) => fetchOptions(value)}
                size="small"
                renderInput={(params) => <TextField {...params} label="Cliente"/>}
            />
    );
};

export default FilterCliente;
