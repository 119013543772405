import { Button, Chip, Stack, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import useUser from "../providers/useUser";

export const TitleWithButton = ({ title, totalRegistros, handleClickAdd }) => {
    const podeVisualizarComponente = useUser((state) => state.podeVisualizarComponente)
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ px: 4, mt: 3 }}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
                spacing={1}
            >
                <Typography sx={{fontWeight: 700, fontSize: 24}}>
                    {title}
                </Typography>
                <Chip sx={{borderRadius: '4px',}} size="small" label={totalRegistros} />

            </Stack>
           {podeVisualizarComponente(["advogado", "user"]) && <Button onClick={handleClickAdd} variant="contained" startIcon={<AddIcon />}>
                Adicionar
            </Button>}
        </Stack>
    );
}
