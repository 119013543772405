import { IconButton, Stack, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import moment from "moment/moment";
import usePlataforma from "../hooks/usePlataforma";

export function CardArquivo({ mimeType, nome, createdAt, idStorage, setDeleteLoading }) {
    const { deleteStorage, baixarArquivo } = usePlataforma()

    const handleDelete = async () => {
        setDeleteLoading(true)
        try {
            await deleteStorage(idStorage)
            setDeleteLoading(false)
        } catch (error) {
            setDeleteLoading(false)
        }
    }

    const handleBaixarArquivo = () => baixarArquivo(idStorage, nome)

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ border: 1, borderColor: "#D8D8D8", borderRadius: 1 }}
            p={2}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
                <img src={`/iconArquivos/${mimeType}.png`} style={{ height: 50, width: "auto" }} />
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Typography sx={{ fontSize: 16, color: "#5f5f5f" }}>{nome}</Typography>
                    <Typography sx={{ fontSize: 12, color: "#5f5f5f" }}>enviado em {moment(createdAt).format("DD/MM/YYYY HH:mm")}</Typography>
                </Stack>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
            >
                <IconButton onClick={handleBaixarArquivo} aria-label="salvar">
                    <SaveAltIcon />
                </IconButton>
                <IconButton onClick={handleDelete} aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            </Stack>
        </Stack>
    )
}