import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function ControlledCheckbox({ id, label, formik, disabled = false }) {

    const isChecked = formik.values[id] === true ||
        formik.values[id] === "true" ||
        formik.values[id] === "on" ||
        (Array.isArray(formik.values[id]) && formik.values[id][0] === 'on');

    return (
        <FormControlLabel
            disabled={disabled}
            control={
                <Switch
                    id={id}
                    name={id}
                    onChange={formik.handleChange}
                    checked={isChecked}
                />
            }
            label={label}
        />
    );
}
