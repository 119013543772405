import * as React from "react";
import {
    Button,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    TextField,
    Tooltip,
} from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';

export const ListEditDetalheGrupoPermissao = ({ id, title, borderBottom, funcaoExcluir, funcaoAtualizar, handleAbrirModalPermissao }) => {
    const [editar, setEditar] = React.useState(false);
    const [valueEdit, setValueEdit] = React.useState(title);

    function handleEditar() {
        setEditar(true)
    }

    function handleAtualizar() {
        funcaoAtualizar(id, valueEdit)
        setEditar(false)
    }

    const handleInputChange = (event) => {
        setValueEdit(event.target.value);
    };

    function handleApagar() {
        funcaoExcluir(id)
    }

    function handlePermissao() {
        handleAbrirModalPermissao(id)
    }

    return (
        <React.Fragment>
            {editar ? (
                <ListItem>
                    <ListItemIcon>
                        <LabelIcon />
                    </ListItemIcon>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{ width: "100%" }}
                    >
                        <TextField onChange={handleInputChange} value={valueEdit} fullWidth size="small" variant="outlined" />
                        <Button onClick={handleAtualizar} variant="contained">Atualizar</Button>
                    </Stack>
                </ListItem>
            ) : (
                <ListItem
                    sx={{ borderBottom, borderColor: "divider" }}
                >
                    <ListItemIcon>
                        <LabelIcon />
                    </ListItemIcon>
                    <ListItemText primary={valueEdit} />
                    <Tooltip title="Permissões">
                        <IconButton onClick={handlePermissao} aria-label="Permissões">
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                        <IconButton onClick={handleEditar} aria-label="Editar">
                            <ModeIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Apagar">
                        <IconButton onClick={handleApagar} aria-label="Apagar">
                            <DeleteForeverIcon />
                        </IconButton>
                    </Tooltip>
                </ListItem>
            )}
        </React.Fragment>
    );
};
