import * as React from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import usePlataforma from '../hooks/usePlataforma';
import { Carregando } from '../components/Carregando';

export const Login = ({ redirect }) => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const { loginUser } = usePlataforma()

  React.useEffect(() => {
    if (redirect) {
      navigate("/", { replace: true });
    }
  }, [redirect, navigate]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Deve ser um e-mail válido').required('E-mail é obrigatório'),
      password: Yup.string().required('Senha é obrigatória')
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        await loginUser(values)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    },
  });

  function handleRecuperarSenha() {
    navigate("/forgot", { state: { type: "user", linkLogin: "/login" }});
    }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1.5}
      sx={{ height: '100vh', '& > :not(style) + :not(style)': { marginTop: 0 } }}
    >
      <Box sx={{ textAlign: "center", maxWidth: { xs: 200, lg: 250 } }}>
        <img
          alt="logo"
          src="/logo.png"
          style={{
            display: "inline-block",
            width: '100%',
          }}
        />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ px: { xs: 4 } }}>
          <TextField
            margin="normal"
            fullWidth
            variant="outlined"
            label="Email"
            name="email"
            type="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            helperText={formik.touched.email && formik.errors.email}
            error={Boolean(formik.touched.email && formik.errors.email)}
          />
          <TextField
            margin="normal"
            fullWidth
            variant="outlined"
            label="Senha"
            name="password"
            type="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
            helperText={formik.touched.password && formik.errors.password}
            error={Boolean(formik.touched.password && formik.errors.password)}
          />
          <Button
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >Entrar</Button>
        </Box>
      </form>
      <Box pt={1.5}>
        <Button
          fullWidth
          onClick={handleRecuperarSenha}
          variant="text"
        >Esqueceu sua senha?</Button>
      </Box>
      <Carregando isLoading={isLoading} />
    </Stack>
  );
}
