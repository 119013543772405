import * as React from 'react';
import { Box, Card, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { NavBar } from '../components/NavBar';
import { TitleWithButton } from '../components/TitleWithButton';
import { PaginationData } from '../components/PaginationData';
import { DetalhePeticao } from '../components/Drawers/DetalhePeticao';
import TablePeticao from '../components/TablePeticao';
import { useNavigate } from 'react-router-dom';
import useFetchGetPeticao from '../queries/peticao';
import { RegistroNaoEncontrado } from '../components/RegistroNaoEncontrado';
import useUser from '../providers/useUser';
import FilterAdvogado from '../components/FilterAdvogado';
import FilterCliente from '../components/FilterCliente';
import { TableLoading } from '../components/TableLoading';
import { ModalSelectForm } from '../components/ModalSelectForm';

export const Dashboard = ({ redirect }) => {
  let navigate = useNavigate();
  const [page, setPage] = React.useState(1)
  const [idAdvogado, setIdAdvogado] = React.useState(null);
  const [idCliente, setIdCliente] = React.useState(null);
  const podeVisualizarComponente = useUser((state) => state.podeVisualizarComponente)
  const [openModalSelectForm, setOpenModalSelectForm] = React.useState(false);

  React.useEffect(() => {
    if (redirect) {
      navigate("/", { replace: true });
    }
  }, [redirect, navigate]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleSelectModal = () => {
    setOpenModalSelectForm(true)
  };

  const { isLoading, data } = useFetchGetPeticao(page, idAdvogado, idCliente)

  return (
    <Box>
      {isLoading && <LinearProgress />}
      <NavBar />
      <TitleWithButton title="Processos" handleClickAdd={handleSelectModal} totalRegistros={!isLoading && data ? data.totalRegistros : 0} />
      <Box sx={{ px: 4, mt: 3 }}>
        <Card sx={{ height: "100%", border: 1, borderColor: "#D8D8D8" }}>
          {podeVisualizarComponente(["advogado", "user"]) && <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ mb: 2, p: 2, mt: 1 }}
          >
            <Grid container spacing={2}>
              <Grid item lg={1}>
                <Typography sx={{ fontWeight: 300, fontSize: 24 }}>
                  Filtros
                </Typography>
              </Grid>
              {podeVisualizarComponente(["user"]) && <Grid item lg={3}>
                <FilterAdvogado setFilter={setIdAdvogado} />
              </Grid>}
              <Grid item lg={3}>
                <FilterCliente setFilter={setIdCliente} />
              </Grid>
            </Grid>
          </Stack>}
          <TablePeticao rows={data ? data.data : []} />

          <TableLoading data={data} isLoading={isLoading} />

          {!isLoading && data && data.totalRegistros === 0 && <RegistroNaoEncontrado />}
        </Card>
      </Box>
      <PaginationData isShow={!isLoading && data && data.totalPages > 1} count={!isLoading && data && data.totalPages} handleChangePage={handleChangePage} page={page} />
      <DetalhePeticao />
      <ModalSelectForm open={openModalSelectForm} setOpen={setOpenModalSelectForm} />
    </Box>
  );
}
