import { Tooltip, Typography } from "@mui/material";

export const TextTooltipDetalhe = ({ text, maxLength }) => {
  return (
    <Tooltip title={text}>
      <Typography sx={{cursor: "default"}}>{text.substring(0, maxLength)}{text.length > maxLength ? "..." : ""}</Typography>
    </Tooltip>
  );
};

