import * as React from "react";
import {
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import MethodColors from "./MethodColors";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

export const ListItemGrupo = ({ title, isActived, idGrupo, idUser, addGrupoPermissao, deleteGrupoPermissao }) => {
  const [value, setValue] = React.useState(isActived);


  function handleDesativar() {
    deleteGrupoPermissao(idUser, [idGrupo])
    setValue(false)
  }

  function handleAtivar() {
    addGrupoPermissao(idUser, [idGrupo])
    setValue(true)
  }


  return (
    <ListItem disablePadding sx={{ width: "100%" }}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ width: "100%" }}
      >
        {value ? <Tooltip placement="top" title="Desativar">
          <IconButton onClick={handleDesativar} size="large" aria-label="Desativar">
            <ToggleOnIcon color="success" />
          </IconButton>
        </Tooltip> : <Tooltip placement="top" title="Ativar">
          <IconButton onClick={handleAtivar} size="large" aria-label="Ativar">
            <ToggleOffIcon color="error" />
          </IconButton>
        </Tooltip>}
        <ListItemText primary={title} />
      </Stack>
    </ListItem>
  );
}
