import { useQuery } from "react-query";
import api from "../../service/api";

async function getConfigUser(ctx) {
  const [, page] = ctx.queryKey
  const { data } = await api.get('/api/user', { params: { page, limit: 10 } })
  return data
}

export default function useFetchGetConfigUser(page) {
    return useQuery(['configUser', page], getConfigUser, { refetchOnWindowFocus: false})
}