import React, { useRef } from 'react';
import { Box, Typography, TextField } from '@mui/material';

export const InputForm = ({ id, label, type, maxLength, formik, disabled=false }) => {
  const inputRef = useRef(null);

  const handleTypographyClick = () => {
    inputRef.current?.focus();
  };

  return (
    <Box>
      <Typography
        sx={{ fontWeight: 300, fontSize: 19, cursor: "default" }}
        onClick={handleTypographyClick}
        component="div"
        role="button"
        tabIndex={-1}
      >
        {label}
      </Typography>
      <TextField
        fullWidth
        size='small'
        variant="outlined"
        id={id}
        name={id}
        type={type ? type : "text"}
        value={formik.values[id]}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={Boolean(formik.touched[id] && formik.errors[id])}
        helperText={formik.touched[id] && formik.errors[id]}
        disabled={disabled}
        inputRef={inputRef}
        inputProps={
          maxLength ? {
            maxLength,
          } : undefined
        }
      />
    </Box>
  );
};
