import create from 'zustand';
import { persist } from 'zustand/middleware';

const useToken = create(
  persist(
    (set, get) => ({
      user: null,
      access_token: '',
      link_login: '/',
      shortIdAdvogado: false,
      setToken: (access_token, link_login) => set({ access_token, link_login }),
      setUser: (user) => set({ user }),
      setShortIdAdvogado: (shortIdAdvogado) => set({ shortIdAdvogado }),
      closeUser: (navigate) => {
        let link = get().link_login
        console.log("shortIdAdvogado",  get().shortIdAdvogado)
        if(get().shortIdAdvogado) link = `${link}/${get().shortIdAdvogado}`
        set({ access_token: '', shortIdAdvogado: false, user: null});
        navigate(link);
      },
    }),
    {
      name: 'useToken',
    }
  )
);

export default useToken;
