
import * as React from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import usePlataforma from '../hooks/usePlataforma';
import { Carregando } from '../components/Carregando';

export const VerificacaoCliente = () => {
  const location = useLocation();
  const { state } = location;
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const { requestCode, verifyCode } = usePlataforma()
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

  React.useEffect(() => {
    if (!state) {
      navigate("/login-cliente", { replace: true });
    }
  }, [state, navigate]);


  const formik = useFormik({
    initialValues: {
      codigo: "",
    },
    validationSchema: Yup.object({
      codigo: Yup.string().required('o código é obrigatório')
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        await verifyCode(values.codigo, state.access_token)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    },
  });

  const reenviarCodigo = () => {
    requestCode(state.access_token)
  };

  const reativarBotao = () => {
    setIsButtonDisabled(false);
  };

  React.useEffect(() => {
    if (isButtonDisabled) {
      setTimeout(reativarBotao, 180000);
    }
  }, [isButtonDisabled]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1.5}
      sx={{ height: '100vh', '& > :not(style) + :not(style)': { marginTop: 0 } }}
    >

      <Box sx={{ textAlign: "center", maxWidth: { xs: 200, lg: 250 } }}>
        <img
          alt="logo"
          src="/logo.png"
          style={{
            display: "inline-block",
            width: '100%',
          }}
        />
      </Box>

      <Box sx={{ px: { xs: 4 } }}>
        <Typography sx={{ fontWeight: 600, fontSize: { lg: 22, xs: 19 }, color: "#636166" }}>
          Informe o código de verificação
        </Typography>
        <Typography sx={{ fontWeight: 300, fontSize: { lg: 16, xs: 12 }, color: "#636166" }}>
          Você recebeu um número de 6 dígitos pelo WhatsApp.
        </Typography>
      </Box>

      <Box sx={{ width: "100%", px: { xs: 4, lg: 60 } }}>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box mt={1}>
            <TextField
              placeholder='_ _ _ _ _ _'
              fullWidth
              variant="outlined"
              name="codigo"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.codigo}
              helperText={formik.touched.codigo && formik.errors.codigo}
              error={Boolean(formik.touched.codigo && formik.errors.codigo)}
              inputProps={{
                maxLength: 6,
              }}
            />
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%", mt: 2 }}

          >
            <Button type="submit" fullWidth variant="contained">Verificar</Button>
            <Button
              onClick={() => {
                reenviarCodigo();
                setIsButtonDisabled(true);
              }}
              fullWidth
              variant="text"
              disabled={isButtonDisabled}
            >Reenviar Código</Button>
          </Stack>
        </form>
      </Box>
      <Carregando isLoading={isLoading} />
    </Stack>
  );
}
