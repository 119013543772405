function encryptJSON(jsonObj) {
    const jsonString = JSON.stringify(jsonObj);
    const base64String = btoa(jsonString);
    return base64String;
}

function decryptJSON(base64String) {
    try {
        const jsonString = atob(base64String);
        const jsonObj = JSON.parse(jsonString);
        return jsonObj;
    } catch (error) {
        return { idResposta: null, idPeticao: null, status: null };
    }
}

// Exportar as funções
export { encryptJSON, decryptJSON };
