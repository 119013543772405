import { Button, Grid, CardContent, Card, Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import InpultType from './InpultType';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ControlledCheckbox from '../CheckboxForm';
import { InputForm } from '../InputForm';
import { InputFormMultiline } from '../InputFormMultiline';

const inputs = [
    { label: 'Data de contratação', name: 'dataContratacao', tamanho: 3, search: false, checkbox: false, type: "date" },
    { label: 'Data da Dispensa', name: 'dataDispensa', tamanho: 3, search: false, checkbox: false, type: "date" },
    { label: 'Função', name: 'funcao', tamanho: 6, search: false, checkbox: false },
    { label: 'Salário inicial', name: 'salarioInicial', tamanho: 3, search: false, checkbox: false, type: "number" },
    { label: 'Último salário', name: 'salarioFinal', tamanho: 3, search: false, checkbox: false, type: "number" },
    { label: 'Motivo rescisão contratual', name: 'motivoRescisao', tamanho: 6, search: false, checkbox: false },
    { label: 'Reclamante atuou em desvio de função', name: 'desvioFuncao', tamanho: 4, search: false, checkbox: true },
    { label: 'Reclamante atuou em acúmulo de função', name: 'funcaoDiversa', tamanho: 4, search: false, checkbox: true },
    { label: 'Recebia valores extra folha', name: 'recebiaValoresExtra', tamanho: 4, search: false, checkbox: true },
]


export const ContratoDeTrabalho = ({ handleNext, formik, handleBack, viewData }) => {
    

    async function handleClickNext() {
        handleNext()
    }

    return (
        <Grid item lg={12}>
            <Card sx={{ border: 1, borderColor: "#D8D8D8", mb:3 }}>
                <CardContent>
                    <Grid container spacing={3} >
                        
                        {inputs.map((current, i) => (
                            <Grid key={i} item lg={current.tamanho}>
                             <InpultType disabled={viewData} current={current} formik={formik} type={current.type && current.type}/>
                            </Grid>
                        ))}
                        <Grid item lg={3}>
                       <ControlledCheckbox disabled={viewData} id="assinaturaCarteira" label="Houve assinatura da carteira" formik={formik} />
                        </Grid>
                        {formik.values.assinaturaCarteira && <Grid item lg={5}>
                        <ControlledCheckbox disabled={viewData} id="contratacaoAssinaturaDivergente" label="Data de contratação e assinatura da não carteira coincide" formik={formik} />
                        </Grid>}
                        {formik.values.assinaturaCarteira && formik.values.contratacaoAssinaturaDivergente && <Grid item lg={3}>
                        <InputForm disabled={viewData} id="dataAssinatura" label="Data de assinatura de carteira" formik={formik} type="date" />
                        </Grid>}
                        <Grid item lg={12}>
                        <InputFormMultiline  disabled={viewData} id="informacoesAdicionaisTrabalho" label="Informações adicionais" formik={formik}/>
                        </Grid>
                    </Grid>
                </CardContent>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    sx={{ p: 2 }}
                >
                    <Button onClick={handleBack} startIcon={<NavigateBeforeIcon />} variant="outlined">Voltar</Button>
                    <Button onClick={handleClickNext} endIcon={<NavigateNextIcon />} variant="contained">Próximo</Button>
                </Stack>
            </Card>
        </Grid>
    );
}
