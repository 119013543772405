export const stringToNumber = (value) => {
  if (typeof value === 'string' && value.trim() !== '') {
    const numericValue = Number(value.replace(/[^0-9.-]+/g, ''));
    return isNaN(numericValue) ? null : numericValue;
  } else if (typeof value === 'number' && value !== 0) {
    return value;
  }

  return null;
};
