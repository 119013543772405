import * as React from 'react';
import { Box } from '@mui/material';
import img from "../assets/loading.gif"

export const LoadingView = () => {

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
      <img
          alt="carregando"
          src={img}
          style={{
            display: "inline-block",
            width: '100%',
          }}
        />
    </Box>
  );
}
