import { Box, Button, Drawer, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { GroupTitle } from "./GroupTitle";
import { InputSearchCep } from "../InputSearchCep";
import usePlataforma from "../../hooks/usePlataforma";
import * as Yup from 'yup';
import React, { useEffect, useState } from "react";
import { LoadingButton } from "../LoadingButton";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PublicIcon from '@mui/icons-material/Public';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { processValueFormik } from "../../utils/processValueFormik";
import NumberFormat from 'react-number-format';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { toast } from "react-toastify";
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ColorPicker from "../ColorPicker";
import LogoAdvogado from "../LogoAdvogado";
import useUser from '../../providers/useUser';
import useToken from "../../providers/useToken";

var inputsInfo = [
    { nome: "nome", label: "Nome", tamanho: 12, search: false, type: "text" },
    { nome: "cpf", label: "CPF", tamanho: 6, search: false, type: "text", isCPF: true },
    { nome: "oab", label: "Oab", tamanho: 6, search: false, type: "text" },
]

var inputsEndereço = [
    { nome: "cep", label: "Cep", tamanho: 12, search: true, type: "text" },
    { nome: "rua", label: "Rua", tamanho: 12, search: false, type: "text" },
    { nome: "cidade", label: "Cidade", tamanho: 6, search: false, type: "text" },
    { nome: "estado", label: "Estado", tamanho: 6, search: false, type: "text" },
    { nome: "bairro", label: "Bairro", tamanho: 6, search: false, type: "text" },
    { nome: "numeroCasa", label: "Número", tamanho: 6, search: false, type: "text" },
    { nome: "complementoEndereco", label: "Complemento", tamanho: 12, search: false, type: "text" },
]

var inputsDadosBancario = [
    { nome: "nomeBanco", label: "Nome Banco", tamanho: 12, search: false, type: "text" },
    { nome: "codigoBanco", label: "Código do Banco", tamanho: 12, search: false, type: "text" },
    { nome: "agencia", label: "Agência", tamanho: 12, search: false, type: "text" },
    { nome: "conta", label: "Conta", tamanho: 12, search: false, type: "text" },
]

var inputsDadosAcesso = [
    { nome: "email", label: "Email", tamanho: 6, search: false, type: "text", hideInView: false },
    { nome: "senha", label: "Nova Senha", tamanho: 6, search: false, type: "text", hideInView: true },
]

export const DWParceiro = ({ open, toggleDrawer, title, viewData, setViewData }) => {
    const { addEndereco, addAdvogado, editAdvogado, changeStatusAdvogado, userSaveOab, userUploadDocumentoOab, baixarArquivo, getUrlCliente } = usePlataforma()
    const podeVisualizarComponente = useUser((state) => state.podeVisualizarComponente)
    const setUser = useToken((state) => state.setUser)
    const [carregando, setCarregando] = useState(false);
    const [url, setUrl] = useState(null);
    const [editar, setEditar] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [editarDocumentoUpload, setEditarDocumentoUpload] = useState(false);

    const formik = useFormik({
        initialValues: {
            nome: "",
            cpf: "",
            oab: "",
            email: "",
            senha: "",
            cep: "",
            cidade: "",
            rua: "",
            estado: "",
            bairro: "",
            numeroCasa: "",
            complementoEndereco: "",
            nomeBanco: "",
            agencia: "",
            conta: "",
            codigoBanco: "",
            idEndereco: "",
            enderecoEncontrado: false,
            cor: '',
            logo: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Deve ser um e-mail válido').required('E-mail é obrigatório'),
            nome: Yup.string().required('Campo obrigatório.'),
            cpf: Yup.string().required('Campo obrigatório.'),
            oab: Yup.string().required('Campo obrigatório.'),
            cep: Yup.string().required('Campo obrigatório.'),
            rua: Yup.string().required('Campo obrigatório.'),
            cidade: Yup.string().required('Campo obrigatório.'),
            estado: Yup.string().required('Campo obrigatório.'),
            bairro: Yup.string().required('Campo obrigatório.'),
            numeroCasa: Yup.string().required('Campo obrigatório.'),
            codigoBanco: Yup.string().required('Campo obrigatório.'),
            nomeBanco: Yup.string().required('Campo obrigatório.'),
            agencia: Yup.string().required('Campo obrigatório.'),
            conta: Yup.string().required('Campo obrigatório.'),
        }),
        onSubmit: async (values) => {
            try {
                setCarregando(true)
                var idEndereco = values.idEndereco
                if (editar) {
                    let user = await editAdvogado(values, viewData.id)

                    if(podeVisualizarComponente(["advogado"])){
                        console.log("é advogado...")
                        setUser(user)
                    }

                    if (fileList.length > 0 && editarDocumentoUpload) {
                        const upload = await userUploadDocumentoOab(fileList)
                        await userSaveOab(viewData.id, upload.id)
                        toast.success("Registro alterado com sucesso!")
                    }
                } else {
                    if (!values.enderecoEncontrado) {
                        const endereco = await addEndereco(values)
                        idEndereco = endereco.id
                    }
                    const responseAdvogado = await addAdvogado(values, idEndereco)
                    if (fileList.length > 0) {
                        const upload = await userUploadDocumentoOab(fileList)
                        await userSaveOab(responseAdvogado.id, upload.id)
                        toast.success("Advogado adicionado com sucesso!")
                    }
                }
                setCarregando(false)
                closeDw()
            } catch (error) {
                setCarregando(false)
            }
        },
    });

    function closeDw() {
        setCarregando(false)
        setEditar(false)
        formik.resetForm();
        toggleDrawer(false)
        setViewData(null)
        setEditarDocumentoUpload(false)
        setFileList([])
    }

    useEffect(() => {
        if (viewData) {
            formik.setValues({
                nome: processValueFormik(viewData.nome),
                cpf: processValueFormik(viewData.cpf),
                oab: processValueFormik(viewData.oab),
                email: processValueFormik(viewData.email),
                cep: viewData.endereco ? processValueFormik(viewData.endereco.cep) : "",
                cidade: viewData.endereco ? processValueFormik(viewData.endereco.cidade) : "",
                rua: viewData.endereco ? processValueFormik(viewData.endereco.rua) : "",
                estado: viewData.endereco ? processValueFormik(viewData.endereco.estado) : "",
                bairro: viewData.endereco ? processValueFormik(viewData.endereco.bairro) : "",
                numeroCasa: processValueFormik(viewData.numeroCasa),
                complementoEndereco: processValueFormik(viewData.complementoEndereco),
                nomeBanco: processValueFormik(viewData.nomeBanco),
                agencia: processValueFormik(viewData.agencia),
                conta: processValueFormik(viewData.conta),
                codigoBanco: processValueFormik(viewData.codigoBanco),
                logo: processValueFormik(viewData.logo),
                cor: processValueFormik(viewData.cor),
                senha: ""
            });
        }
    }, [viewData]);

    function handleAtivar() {
        changeStatusAdvogado("activ", viewData.id)
        closeDw()
    }

    function handleAceitar() {
        changeStatusAdvogado("accept", viewData.id)
        closeDw()
    }

    async function handlerUrl() {
        setCarregando(true)
        const data = await getUrlCliente(viewData.id);
        toast.success("URL: " + data.url)
        setUrl(data.url);
        setCarregando(false);
    }

    async function copyUrl() {
        await navigator.clipboard.writeText(url);
        toast.success("Copiado com sucesso!")
    }

    function handleDesativar() {
        changeStatusAdvogado("desactiv", viewData.id)
        closeDw()
    }

    const props = {
        name: 'file',
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

    const { Dragger } = Upload;


    return (
        <Drawer
            open={open}
            onClose={closeDw}
            sx={{
                width: 350,
            }}
            anchor="right"
        >
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                sx={{ width: 350, height: '100%' }}
            >
                <Stack
                    direction="column"
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        sx={{ width: "100%", p: 2, borderBottom: 1, borderColor: "#D8D8D8" }}
                    >
                        <Typography sx={{ fontWeight: 300, fontSize: 24 }}>
                            {editar ? "Editar" : title} Parceiro
                        </Typography>
                        <IconButton onClick={closeDw} aria-label="delete">
                            <CloseIcon />
                        </IconButton>
                    </Stack>


                    <Grid p={2} container spacing={2} >
                        <Grid item xs={12}>
                            <GroupTitle title="Informações pessoais" />
                        </Grid>
                        {inputsInfo.map(current => (
                            <Grid item xs={current.tamanho} key={current.nome}>
                                {current.isCPF ? (
                                    <NumberFormat
                                        format="###.###.###-##"
                                        customInput={TextField}
                                        mask="_"
                                        fullWidth
                                        size='small'
                                        label={current.label}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched[current.nome] && formik.errors[current.nome])}
                                        helperText={formik.touched[current.nome] && formik.errors[current.nome]}
                                        name={current.nome}
                                        value={formik.values[current.nome]}
                                        variant="outlined"
                                        disabled={!editar && viewData ? true : false}
                                    />
                                ) : (
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={current.label}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched[current.nome] && formik.errors[current.nome])}
                                        helperText={formik.touched[current.nome] && formik.errors[current.nome]}
                                        name={current.nome}
                                        value={formik.values[current.nome]}
                                        variant="outlined"
                                        disabled={!editar && viewData ? true : false}
                                    />
                                )}
                            </ Grid>
                        ))}

                        <Grid item xs={12}>
                            <GroupTitle title="Endereço" />
                        </Grid>

                        {inputsEndereço.map(current => (
                            <Grid item xs={current.tamanho} key={current.nome}>
                                {current.search ? (
                                    <InputSearchCep
                                        id={current.nome}
                                        label={current.label}
                                        formik={formik}
                                        disabled={!editar && viewData ? true : false}
                                    />
                                ) : (
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={current.label}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched[current.nome] && formik.errors[current.nome])}
                                        helperText={formik.touched[current.nome] && formik.errors[current.nome]}
                                        name={current.nome}
                                        value={formik.values[current.nome]}
                                        variant="outlined"
                                        disabled={!editar && viewData ? true : false}
                                    />
                                )}
                            </Grid>
                        ))}


                        <Grid item xs={12}>
                            <GroupTitle title="Dados bancários" />
                        </Grid>

                        {inputsDadosBancario.map(current => (
                            <Grid item xs={current.tamanho} key={current.nome}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={current.label}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched[current.nome] && formik.errors[current.nome])}
                                    helperText={formik.touched[current.nome] && formik.errors[current.nome]}
                                    name={current.nome}
                                    value={formik.values[current.nome]}
                                    variant="outlined"
                                    disabled={!editar && viewData ? true : false}
                                />
                            </Grid>
                        ))}

                        <Grid item xs={12}>
                            <GroupTitle title="Personalização para o Cliente" />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <ColorPicker />
                        </Grid> */}

                        {viewData &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <GroupTitle title="Logo" />
                                </Grid>
                                <Grid item xs={12}>
                                    <LogoAdvogado file={formik.values['logo']}
                                        setFile={
                                            (file) => {
                                                formik.setFieldValue('logo', file)
                                            }}
                                        disabled={!editar}
                                    />
                                </Grid>
                            </React.Fragment>
                        }

                        <Grid item xs={12}>
                            <GroupTitle title="Dados de acesso" />
                        </Grid>


                        <Grid item xs={viewData ? 12 : 6}>
                            <TextField
                                fullWidth
                                size='small'
                                label={inputsDadosAcesso[0].label}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched[inputsDadosAcesso[0].nome] && formik.errors[inputsDadosAcesso[0].nome])}
                                helperText={formik.touched[inputsDadosAcesso[0].nome] && formik.errors[inputsDadosAcesso[0].nome]}
                                name={inputsDadosAcesso[0].nome}
                                value={formik.values[inputsDadosAcesso[0].nome]}
                                variant="outlined"
                                disabled={!editar && viewData ? true : false}
                            />
                        </Grid>

                        {!viewData && <Grid item xs={6}>
                            <TextField
                                fullWidth
                                size='small'
                                label={inputsDadosAcesso[1].label}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched[inputsDadosAcesso[1].nome] && formik.errors[inputsDadosAcesso[1].nome])}
                                helperText={formik.touched[inputsDadosAcesso[1].nome] && formik.errors[inputsDadosAcesso[1].nome]}
                                name={inputsDadosAcesso[1].nome}
                                value={formik.values[inputsDadosAcesso[1].nome]}
                                variant="outlined"
                                disabled={!editar && viewData ? true : false}
                            />
                        </Grid>}

                        {viewData && editar && !editarDocumentoUpload && <Grid item xs={12}>
                            <Button startIcon={<CloudSyncIcon />} onClick={() => setEditarDocumentoUpload(true)} fullWidth variant="text" color="info">EDITAR ARQUIVO OAB</Button>
                        </Grid>}

                        {viewData && viewData.oabFile && !editar && !editarDocumentoUpload && <Grid item xs={12}>
                            <Button startIcon={<CloudDownloadIcon />} onClick={() => baixarArquivo(viewData.oabFile.id, viewData.oabFile.nome)} fullWidth variant="text" color="info">BAIXAR ARQUIVO OAB</Button>
                        </Grid>}

                        {(!viewData || editarDocumentoUpload) &&
                            <>
                                <Grid item xs={12}>
                                    <GroupTitle title={editarDocumentoUpload ? "Editar Documento" : "Envio Documento"} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <Box p={2}>
                                            <p className="ant-upload-text">Faça o upload da carteira da OAB.</p>
                                        </Box>
                                    </Dragger>
                                </Grid>
                            </>
                        }

                        {url && <Grid item xs={12}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" style={{ cursor: 'pointer' }} onClick={copyUrl}>
                                            <ContentCopyIcon />
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                                size='small'
                                name={"Url"}
                                value={url}
                                variant="outlined"
                                disabled={true}
                            />
                        </Grid>}
                    </Grid>

                </Stack>
                {viewData && <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    px={2}
                    sx={{ width: "100%" }}
                >
                    {<Button startIcon={<PublicIcon />} disabled={carregando} onClick={handlerUrl} fullWidth variant="contained" color="info">URL</Button>}
                </Stack>}
                {viewData && <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    px={2}
                    sx={{ width: "100%" }}
                >
                    {!viewData.isAccepted && !editar && <Button startIcon={<HowToRegIcon />} disabled={carregando} onClick={handleAceitar} fullWidth variant="contained" color="success">Aceitar</Button>}
                    {viewData.isActivated && !editar && <Button startIcon={<PowerSettingsNewIcon />} disabled={carregando} onClick={handleDesativar} fullWidth variant="contained" color="error">Desativar</Button>}
                    {!viewData.isActivated && !editar && <Button startIcon={<PowerSettingsNewIcon />} disabled={carregando} onClick={handleAtivar} fullWidth variant="contained" color="success">Ativar</Button>}
                </Stack>}
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    px={2}
                    pb={2}
                    sx={{ width: "100%" }}
                >
                    {!viewData && <LoadingButton loading={carregando} onClick={formik.handleSubmit} >Salvar</LoadingButton>}
                    {viewData && editar && <LoadingButton loading={carregando} onClick={formik.handleSubmit} >Salvar</LoadingButton>}
                    {viewData && !editar && <Button disabled={carregando} onClick={() => setEditar(true)} fullWidth variant="outlined" >Editar</Button>}
                    {(!viewData || editar) && <Button disabled={carregando} onClick={closeDw} fullWidth variant="outlined" color="error">Cancelar</Button>}
                </Stack>
            </Stack>

        </Drawer>
    );
}



