import { Pagination, Stack } from "@mui/material";

export const PaginationData = ({ isShow = false, count = 1, page, handleChangePage }) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ my: 2 }}
    >
      {isShow && <Pagination count={count} page={page} onChange={handleChangePage} />}
    </Stack>
  );
}
