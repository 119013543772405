import React, { useRef, useState } from 'react';
import { Box, Typography, Stack, IconButton, Tooltip, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import api from '../../service/api';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export const InputFormFile = ({ id, label, formik, disabled = false }) => {
  const inputRef = useRef(null);
  const [arquivo, setArquivo] = useState(null);
  const [carregando, setCarregando] = useState(false);
  const [errorUpload, seterrorUpload] = useState(false);

  const valueFormik = formik.values[id]

  const handleStackClick = () => {
    if (!arquivo || !valueFormik) {
      inputRef.current?.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setArquivo(file);
      handleSubmit(file)
    }
  };

  const handleSubmit = async (file) => {
    setCarregando(true)
    seterrorUpload(false)

    const formData = new FormData();

    formData.append('file', file);

    try {
      const { data } = await api.post('/api/storage/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      let id_upload = data.id

      formik.setFieldValue(id, id_upload);
      setCarregando(false)

    } catch (error) {
      seterrorUpload(true)
      setCarregando(false)
      console.error('Erro ao enviar o arquivo', error);
    }
  };

  const handleDelete = async () => {
    try {
      setCarregando(true)
      await api.delete(`/api/storage/download/${valueFormik}`)
      setArquivo(null)
      seterrorUpload(false)
      setCarregando(false)
      formik.setFieldValue(id, "");
    } catch (error) {
      seterrorUpload(false)
      setCarregando(false)
      console.log(error)
    }
  }

  const handleOpenLink = async () => {
    try {
      setCarregando(true);

      const response = await api.get(`/api/storage/download/${valueFormik}`, {
        responseType: 'blob',
      });

      if (response.status === 200) {
        const fileUrl = window.URL.createObjectURL(response.data);

        window.open(fileUrl, '_blank');

        window.URL.revokeObjectURL(fileUrl);
      } else {
        console.error('Erro na resposta:', response);
      }

      setCarregando(false);
    } catch (error) {
      setCarregando(false);
      console.log('Erro ao abrir o link:', error);
    }
  };


  return (
    <Box>
      <Typography
        sx={{ fontWeight: 300, fontSize: 19, cursor: "default" }}
        component="div"
        role="button"
        tabIndex={0}
      >
        {label}
      </Typography>
      {disabled ? (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            px: 2,
            justifyContent: "space-between",
            alignItems: "center",
            border: '2px dotted #d9d9d9',
            height: 50,
            width: "100%",
            borderRadius: 1,
            bgcolor: "#00000005",
            cursor: "default"
          }}
        >
          {arquivo || valueFormik ? (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {carregando ? (
                <CircularProgress size={22} thickness={2} color='warning' />
              ) : (
                <CloudDoneIcon sx={{ color: "#009688" }} />
              )}
              <Typography sx={{ color: "rgb(0 0 0 / 45%)" }}>
                {arquivo?.name || formik.values[id]}
              </Typography>
            </Stack>
          ) : (
            <Typography sx={{ color: "rgb(0 0 0 / 45%)" }}>
              Nenhum arquivo.
            </Typography>
          )}
          {valueFormik &&
            <Tooltip placement='top' arrow title="Baixar">
              <IconButton disabled={carregando} onClick={handleOpenLink} aria-label="Download">
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>}
        </Stack>
      ) : (
        <Stack
          direction="row"
          spacing={2}
          onClick={handleStackClick}
          sx={{
            px: 2,
            justifyContent: "space-between",
            alignItems: "center",
            border: '2px dotted #d9d9d9',
            height: 50,
            width: "100%",
            borderRadius: 1,
            bgcolor: "#00000005",
            cursor: !valueFormik ? 'pointer' : "default"
          }}
        >
          {arquivo || valueFormik ? (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {carregando ? (
                <CircularProgress size={22} thickness={2} color='warning' />
              ) : (
                <React.Fragment>
                  {errorUpload ? (
                    <Tooltip placement='top' arrow title="Erro no upload. Tente apagar e enviar novamente">
                      <CloudOffIcon sx={{ color: "#960000" }} />
                    </Tooltip>
                  ) : (
                    <CloudDoneIcon sx={{ color: "#009688" }} />
                  )}
                </React.Fragment>
              )}
              <Typography sx={{ color: "rgb(0 0 0 / 45%)" }}>
                {arquivo?.name || formik.values[id]}
              </Typography>
            </Stack>
          ) : (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <CloudUploadIcon sx={{ color: "rgb(0 0 0 / 45%)" }} />
              <Typography sx={{ color: "rgb(0 0 0 / 45%)" }}>
                Selecione um arquivo...
              </Typography>
            </Stack>
          )}
          {valueFormik && !disabled &&
            <Tooltip placement='top' arrow title="Excluir e enviar novamente">
              <IconButton disabled={carregando} onClick={handleDelete} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>}
        </Stack>
      )}
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </Box>
  );
};
