import React from 'react';
import { IconButton, Badge, Popover, List, Box, ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
// import { useNavigate } from 'react-router-dom';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { RegistroNaoEncontrado } from './RegistroNaoEncontrado';

const NotificationComponent = () => {
    // let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    function goTask(id, idNotificacao) {
        handleClose()
        // navigate("/detalhe/" + id);
      }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

   
    const open = Boolean(anchorEl);
    const id = open ? 'notification-popover' : undefined;

    var data = null
    var dataCount = null

    return (
        <Box mr={2}>
            <IconButton
                sx={{ color: 'white' }}
                aria-label="delete"
                onClick={handleClick}
            >
                <Badge color="error" badgeContent={dataCount ? dataCount.notificacoes : 0 }>
                    <NotificationsNoneIcon />
                </Badge>
            </IconButton>
            <Popover
                elevation={6}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiPaper-root': {
                      maxHeight: 300,
                    },
                  }}
            >
                <List>
                    {data && data.notificacoes.map((current, index, arr) => (
                        <ListItemButton key={current._id} onClick={() => goTask(current.registroID._id, current._id)} sx={{borderBottom: index === arr.length - 1 ? 0 : 1, borderColor: "divider"}}>
                        <ListItemIcon>
                           {current.tipo === "atraso" ? <AccessTimeFilledIcon />: <MarkEmailUnreadIcon />}
                        </ListItemIcon>
                        <ListItemText primary={current.registroID.taskID} secondary={current.mensagem} />
                    </ListItemButton>
                    ))}
                </List>
                <Box p={3}><RegistroNaoEncontrado /></Box>
            </Popover>
        </Box>
    );
};

export default NotificationComponent;
