import { Button, Grid, CardContent, Card, Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { InputForm } from '../InputForm';
import ControlledCheckbox from '../CheckboxForm';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import InpultEscalaTrabalho from '../InpultEscalaTrabalho';
import { InputFormMultiline } from '../InputFormMultiline';

export const JornadaDeTrabalho = ({ handleNext, formik, handleBack, viewData }) => {

    async function handleClickNext() {
        handleNext()
    }


    return (
        <Grid item lg={12}>
            <Card sx={{ border: 1, borderColor: "#D8D8D8", mb: 3 }}>
                <CardContent>
                    <Grid container spacing={3} >
                        <Grid item lg={4}>
                            <InputForm disabled={viewData} id="horaEntrada" label="Horário de Entrada" formik={formik} type="time" />
                        </Grid>
                        <Grid item lg={4}>
                            <InputForm disabled={viewData} id="horaSaida" label="Horário de Saída" formik={formik} type="time" />
                        </Grid>
                        <Grid item lg={4}>
                            <InpultEscalaTrabalho disabled={viewData} label="Escala de trabalho" formik={formik} />
                        </Grid>
                        <Grid item lg={12}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                                spacing={2}
                            >
                                <ControlledCheckbox disabled={viewData} id="intervaloDescansoAlmoco" label="Possuia intervalo para almoço/descanso" formik={formik} />
                               {formik.values.intervaloDescansoAlmoco && <InputForm disabled={viewData} id="tempoIntervalo" label="Duração do Intervalo" formik={formik} type="time"/> }
                            </Stack>
                        </Grid>
                        <Grid item lg={12}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                                spacing={2}
                            >
                                <ControlledCheckbox disabled={viewData} id="trabalhoDomingoFolga" label="Trabalhou em domingos e folgas?" formik={formik} />
                                { formik.values.trabalhoDomingoFolga && <InputForm disabled={viewData} type="number" id="frequenciaTrabalhoDomingo" label="Frequência de dias trabalhados por mês" formik={formik} /> }
                            </Stack>
                        </Grid>
                        <Grid item lg={12}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                                spacing={2}
                            >
                                <ControlledCheckbox disabled={viewData} id="trabalhoFeriado" label="Trabalhou em feriados?" formik={formik} />
                                { formik.values.trabalhoFeriado && <InputForm disabled={viewData} type="number" id="frequenciaTrabalhoFeriado" label="Frequência de dias trabalhados por mês" formik={formik} /> }
                            </Stack>
                        </Grid>
                        <Grid item lg={12}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                                spacing={2}
                            >
                                <ControlledCheckbox disabled={viewData} id="trabalhoNoturno" label="Trabalhou em jornada noturna?" formik={formik} />
                                { formik.values.trabalhoNoturno && <InputForm disabled={viewData} id="mediaHorasTrabalhoNoturno" label="Média de horas em trabalho noturno" formik={formik} type="time"/> }
                                { formik.values.trabalhoNoturno && <InputForm disabled={viewData} type="number" id="mediaDiasTrabalhoNoturno" label="Média de dias em trabalho noturno no mês" formik={formik} /> }
                            </Stack>
                        </Grid>
                        <Grid item lg={12}>
                        <InputFormMultiline  disabled={viewData} id="informacoesAdicionaisJornada" label="Informações adicionais" formik={formik}/>
                        </Grid>
                    </Grid>
                </CardContent>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    sx={{ p: 2 }}
                >
                    <Button onClick={handleBack} startIcon={<NavigateBeforeIcon />} variant="outlined">Voltar</Button>
                    <Button onClick={handleClickNext} endIcon={<NavigateNextIcon />} variant="contained">Próximo</Button>
                </Stack>
            </Card>
        </Grid>
    );
}
