import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useJwt } from "react-jwt";
import { useEffect, useState } from "react";
import api from "../service/api";

import { Login } from "../pages/Login";
import { Dashboard } from "../pages/Dashboard";
import { LoginParceiro } from "../pages/LoginParceiro";
import { CadastroParceiro } from "../pages/CadastroParceiro";
import { LoginCliente } from "../pages/LoginCliente";
import { VerificacaoCliente } from "../pages/VerificacaoCliente";

import useToken from "../providers/useToken";
import useUser from "../providers/useUser";
import { Parceiros } from "../pages/Parceiros";
import { Configuracoes } from "../pages/Configuracoes";
import { Peticao } from "../pages/Peticao";
import { RecuperarSenha } from "../pages/RecuperarSenha";
import { NovaSenha } from "../pages/NovaSenha";
import { LoadingView } from "../pages/LoadingView";
import { Comentarios } from "../pages/Comentarios";
import { ArquivosPeticao } from "../pages/ArquivosPeticao";
import { Financeiro } from "../pages/Financeiro";
import { Formulario } from "../pages/Formulario";
import { FormularioEditar } from "../pages/FormularioEditar";
import { PeticaoView } from "../pages/PeticaoView";
// import { SemAcesso } from "../pages/SemAcesso";

export function Rotas() {
  const access_token = useToken((state) => state.access_token);
  const link_login = useToken((state) => state.link_login);
  const { decodedToken, isExpired } = useJwt(access_token);
  const setToken = useToken((state) => state.setToken)
  const setUser = useUser((state) => state.setUser)
  const [isLoading, setIsLoading] = useState(true);
  const podeVisualizarComponente = useUser((state) => state.podeVisualizarComponente)

  const redirectToLogin = (link_login) => {
    window.location.href = link_login || "/";
  };

  useEffect(() => {
    setIsLoading(true);
    if (access_token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      
      if (decodedToken && isExpired) {
        setToken("");
        redirectToLogin(link_login);
      }
    
      if (decodedToken) {
        setUser(decodedToken);
      }

    } else {
      setIsLoading(false);
    }
  }, [decodedToken, isExpired, setToken, setUser, link_login, access_token]);


  useUser.subscribe((state) => {
    var user = state.user
    if(!user.isEmpty){
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  })

  // var loggedPermission = ['GET_parceiross', "GET_peticao"]
  
  const renderRoutes = (routes) => {
    return (
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    );
  };


  const renderRoutesPrivate = (routes) => {
    return (
      <Routes>
        {routes.map((route) =>
          podeVisualizarComponente(route.showTo) ? (
            <Route key={route.path} path={route.path} element={route.element} />
          ) : ( <Route key={route.path} path="*" element={<Dashboard redirect />} /> )
        )}
      </Routes>
    );
  };
  
  // const renderRoutesPrivate = (routes) => {
  //   return (
  //     <Routes>
  //       {routes.map((route) => (
  //         loggedPermission.includes(route.permission) ? (
  //           <Route key={route.path} path={route.path} element={route.element} />
  //         ) : (
  //           <Route key={route.path} path={route.path} element={<SemAcesso />} />
  //         )
  //       ))}
  //     </Routes>
  //   );
  // };

  const privateRoutes = [
    { path: "/", element: <Dashboard />, showTo: ["advogado", "user", "client"] },
    { path: "/add/peticao", element: <Peticao />, showTo: ["advogado", "user" ] },
    { path: "/detalhe/peticao/:id", element: <PeticaoView />, showTo: ["advogado", "user", "client"] },
    { path: "/detalhe-old/peticao/:id", element: <Peticao />, showTo: ["advogado", "user", "client"] },
    { path: "/detalhe/peticao/comentarios/:id", element: <Comentarios />, showTo: ["advogado", "user", "client"] },
    { path: "/detalhe/peticao/arquivos/:id", element: <ArquivosPeticao />, showTo: ["advogado", "user", "client"] },
    { path: "/parceiros", element: <Parceiros />, showTo: ["user"] },
    { path: "/configuracoes", element: <Configuracoes />, showTo: ["user"] },
    { path: "/financeiro", element: <Financeiro />, showTo: ["advogado", "user"] },
    { path: "/formulario/:id", element: <Formulario />, showTo: ["advogado", "user"] },
    { path: "/formulario/editar/:id", element: <FormularioEditar />, showTo: ["advogado", "user"] },
    { path: "*", element: <Dashboard redirect />, showTo: ["advogado", "user", "client"] },
  ];

  const publicRoutes = [
    { path: "/", element: <Login /> },
    { path: "/login-parceiro", element: <LoginParceiro /> },
    { path: "/login-cliente/:shortId", element: <LoginCliente /> },
    { path: "/login-cliente", element: <LoginCliente /> },
    { path: "/verificacao-cliente", element: <VerificacaoCliente /> },
    { path: "/nova-conta-parceiro", element: <CadastroParceiro /> },
    { path: "/forgot", element: <RecuperarSenha /> },
    { path: "/nova-senha", element: <NovaSenha /> },
    { path: "*", element: <Login redirect /> },
  ];

  return (
    <BrowserRouter>
       {isLoading ? <LoadingView /> : access_token ? renderRoutesPrivate(privateRoutes) : renderRoutes(publicRoutes)}
    </BrowserRouter>
  );
}
