
import * as React from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import usePlataforma from '../hooks/usePlataforma';
import NumberFormat from "react-number-format";
import { Carregando } from '../components/Carregando';
import useFetchGetConfigs from '../queries/cliente';
import { useParams } from 'react-router';
import BotaoWhastapp from '../components/BotaoWhatsapp';

export const LoginCliente = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { loginCliente } = usePlataforma()
  const shortId = useParams().shortId
  const { isLoading: isLoadingConfig, data: configs } = useFetchGetConfigs(shortId)

  const formik = useFormik({
    initialValues: {
      cpf: "",
      dataNascimento: ""
    },
    validationSchema: Yup.object({
      cpf: Yup.string().required('Este campo é obrigatório.'),
      dataNascimento: Yup.string().required('Este campo é obrigatório.')
    }),
    onSubmit: async (values) => {
      try {
        var cpf = values.cpf.replace(/\D/g, '')
        var dataNascimento = values.dataNascimento.split('/').reverse().join('-')
        setIsLoading(true)
        await loginCliente(cpf, dataNascimento, shortId)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }
  });

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1.5}
      sx={{ height: '100vh', '& > :not(style) + :not(style)': { marginTop: 0 } }}
    >

      {isLoadingConfig ?
        <Carregando isLoading={isLoadingConfig} />
        :
        <Box sx={{ textAlign: "center", maxWidth: { xs: 200, lg: 250 } }}>
          <img
            alt="logo"
            src={configs ? configs.logo || "/logo.png" : "./logo.png"}
            style={{
              display: "inline-block",
              width: '100%',
            }}
          />
        </Box>
      }

      <Box sx={{ mt: 2, px: { xs: 4 } }}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            variant="outlined"
            label="CPF"
            name="cpf"
            type="cpf"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.cpf}
            helperText={formik.touched.cpf && formik.errors.cpf}
            error={Boolean(formik.touched.cpf && formik.errors.cpf)}
          />
          <NumberFormat
            customInput={TextField}
            format="##/##/####"
            mask="_"
            fullWidth
            margin="normal"
            label="Data de Nasc."
            variant="outlined"
            name="dataNascimento"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.dataNascimento}
            helperText={formik.touched.dataNascimento && formik.errors.dataNascimento}
            error={Boolean(formik.touched.dataNascimento && formik.errors.dataNascimento)}
          />
          <Button
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            type="submit"
          >Entrar</Button>
        </form>
        {!isLoadingConfig && configs && configs.telefoneCelular && configs.dddCelular && configs.ddiCelular &&
          <BotaoWhastapp nome={configs.nome} ddi={configs.ddiCelular} ddd={configs.dddCelular} telefone={configs.telefoneCelular} />
        }
      </Box>

      <Carregando isLoading={isLoading} />
    </Stack>
  );
}
