import { useQuery } from "react-query";
import api from "../../service/api";

async function getUserById(ctx) {
  const [, id] = ctx.queryKey
  const { data } = await api.get(`/api/user/${id}`)
  return data
}

export default function useFetchGetUserById(id) {
    return useQuery(['userById', id], getUserById, { refetchOnWindowFocus: false})
}