import { Button, Grid, CardContent, Card, Stack, Typography, Divider, Box } from '@mui/material';
import ControlledCheckbox from '../CheckboxForm';
import { InputForm } from '../InputForm';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Carregando } from '../Carregando';
import InpultInsalubridade from '../InpultInsalubridade';
import InpultAdvogado from '../InpultAdvogado';
import { InputFormMultiline } from '../InputFormMultiline';

export const DadosFinais = ({ formik, handleBack, isLoading, viewData, type }) => {
    

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

    async function handleClickNext() {
        scrollToTop()
        formik.handleSubmit()
    }

    return (
        <Grid item lg={12}>
            <Card sx={{ border: 1, borderColor: "#D8D8D8", mb: 4 }}>
                <CardContent>
                    <Grid container spacing={2} >

                        {type === "user" && <Grid item lg={12}>
                            <Typography sx={{ fontWeight: 500, fontSize: 19 }}>
                                Advogado responsável
                            </Typography>
                            <Box my={1}><Divider /></Box>
                        </Grid>}
                         
                       {type === "user" && <Grid item lg={3}>
                        <InpultAdvogado formik={formik} label="Selecione um advogado (opcional)" disabled={viewData} />
                        </Grid>}

                        <Grid item lg={12}>
                            <Typography sx={{ fontWeight: 500, fontSize: 19 }}>
                                Ambiente de trabalho
                            </Typography>
                            <Box my={1}><Divider /></Box>
                        </Grid>

                        <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="trabalhoInsalubre" label="Trabalhou em condições insalubres?" formik={formik} />
                        </Grid>
                        {formik.values.trabalhoInsalubre &&  <Grid item lg={3}>
                      <InpultInsalubridade disabled={viewData} label="Recebeu adicional de insalubridade?" formik={formik} /> 
                        </Grid>}

                        {formik.values.trabalhoInsalubre && <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="recebeuEpi" label="Recebeu EPI?" formik={formik} />
                        </Grid> }
                        {formik.values.recebeuEpi && formik.values.trabalhoInsalubre && <Grid item lg={3}>
                        <InputForm disabled={viewData} id="epiRecebido" label="EPI's recebidos" formik={formik} /> 
                        </Grid>}
                        {formik.values.trabalhoInsalubre && <Grid item lg={3}>
                         <InputForm disabled={viewData} id="agenteInsalubre" label="Agentes insalubres" formik={formik} /> 
                        </Grid>}

                        <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="trabalhoPericulosidade" label="Trabalhou em condições periculosas?" formik={formik} />
                        </Grid>
                        {formik.values.trabalhoPericulosidade && <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="recebePericulosidade" label="Recebeu periculosidade?" formik={formik} />
                        </Grid>}
                        {formik.values.trabalhoPericulosidade && <Grid item lg={3}>
                        <InputForm disabled={viewData} id="agentesPericulosos" label="Agentes periculosos" formik={formik} />
                        </Grid>}

                        <Grid item lg={12}>
                            <Typography sx={{ fontWeight: 500, fontSize: 19 }}>
                            Férias
                            </Typography>
                            <Box my={1}><Divider /></Box>
                        </Grid>

                        <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="feriasPeriodoIncorreto" label="As férias não foram usufruidas no período correto." formik={formik} />
                        </Grid>
                        {formik.values.feriasPeriodoIncorreto && <Grid item lg={4}>
                        <InputForm disabled={viewData} id="dataFeriasPeriodoIncorreto" label="Data em que as férias deveriam ser usufruidas" formik={formik} type="date"/>
                        </Grid>}
                        {formik.values.feriasPeriodoIncorreto && <Grid item lg={4}>
                        <InputForm disabled={viewData} id="dataFeriasPeriodoCorreto" label="Data em que as férias foram usufruidas" formik={formik} type="date"/>
                        </Grid>}


                        <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="feriasPagamento" label="As férias foram pagas intempestivamente." formik={formik} />
                        </Grid>
                        {formik.values.feriasPagamento && <Grid item lg={4}>
                        <InputForm disabled={viewData} id="feriasInicio" label="Data em que as férias se iniciaram." formik={formik} type="date"/>
                        </Grid>}
                        {formik.values.feriasPagamento && <Grid item lg={4}>
                        <InputForm disabled={viewData} id="dataFeriasPagamento" label="Data de pagamento de férias" formik={formik} type="date"/>
                        </Grid>}


                        <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="trabalhoDuranteFerias" label="Trabalhou durante as férias." formik={formik} />
                        </Grid>
                        {formik.values.trabalhoDuranteFerias && <Grid item lg={4}>
                        <InputForm disabled={viewData} type="number" id="trabalhoDuranteFeriasQuantidade" label="Quantidade de dias de trabalhados na férias" formik={formik} />
                        </Grid>}


                        <Grid item lg={12}>
                            <Typography sx={{ fontWeight: 500, fontSize: 19 }}>
                            Danos decorrentes do contrato de trabalho
                            </Typography>
                            <Box my={1}><Divider /></Box>
                        </Grid>

                        <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="acidenteTrabalho" label="Sofreu acidente de trabalho." formik={formik} />
                        </Grid>
                        {formik.values.acidenteTrabalho && <Grid item lg={12} ml={3}>
                        <ControlledCheckbox disabled={viewData} id="emissaoCat" label="Foi emitida CAT." formik={formik} />
                        </Grid>}
                        {formik.values.acidenteTrabalho && <Grid item lg={12} ml={3}>
                        <ControlledCheckbox disabled={viewData} id="afastamentoBeneficiario" label="Ficou afastado em benefício previdenciário." formik={formik} />
                        </Grid>}
                        {formik.values.acidenteTrabalho && <Grid item lg={12} ml={3}>
                        <ControlledCheckbox disabled={viewData} id="danoEstetico" label="Sofreu dano estético." formik={formik} />
                        </Grid>}

                        
                        <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="doencaOcupacional" label="Sofreu de doença ocupacional." formik={formik} />
                        </Grid>
                        {formik.values.doencaOcupacional &&  <Grid item lg={12} ml={3}>
                        <ControlledCheckbox disabled={viewData} id="doencaOcupacionalAfastamento" label="Ficou afastado em benefício previdenciário." formik={formik} />
                        </Grid>}


                        <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="assedioMoral" label="Sofreu de assédio moral." formik={formik} />
                        </Grid>
                        {formik.values.assedioMoral &&  <Grid item lg={4}>
                        <InputForm disabled={viewData} id="nomeAssediador" label="Nome do assediador" formik={formik} />
                        </Grid>}
                        {formik.values.assedioMoral &&  <Grid item lg={4}>
                        <InputForm disabled={viewData} id="descricaoFatos" label="Descrição dos fatos" formik={formik} />
                        </Grid>}


                        <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="assedioSexual" label="Sofreu de assédio sexual." formik={formik} />
                        </Grid>
                        {formik.values.assedioSexual &&  <Grid item lg={4}>
                        <InputForm disabled={viewData} id="nomeAssediadorSexual" label="Nome do assediador" formik={formik} />
                        </Grid>}
                        {formik.values.assedioSexual &&  <Grid item lg={4}>
                        <InputForm disabled={viewData} id="descricaoFatosAssedioSexual" label="Descrição dos fatos" formik={formik} />
                        </Grid>}


                        <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="dispensaDiscriminatoria" label="Sofreu de dispensa discriminatória." formik={formik} />
                        </Grid>


                        <Grid item lg={12}>
                            <Typography sx={{ fontWeight: 500, fontSize: 19 }}>
                            Rescisão contratual propriamente dita
                            </Typography>
                            <Box my={1}><Divider /></Box>
                        </Grid>

                        <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="pagamentoRescisao" label="As verbas rescisórias não foram pagas dentro do prazo de 10 dias." formik={formik} />
                        </Grid>
                        {formik.values.pagamentoRescisao &&  <Grid item lg={4}>
                        <InputForm disabled={viewData} id="dataPagamentoRescisao" label="Data de pagamento da rescisão" formik={formik} type="date" />
                        </Grid>}


                        <Grid item lg={12}>
                        <ControlledCheckbox disabled={viewData} id="entregaDocumentos" label="Os documentos rescisórios não foram entregues dentro do prazo de 10 dias da dispensa." formik={formik} />
                        </Grid>
                        {formik.values.entregaDocumentos &&  <Grid item lg={4}>
                        <InputForm disabled={viewData} id="dataEntregaDocumentos" label="Data de entrega documentos da rescisão" formik={formik} type="date" />
                        </Grid>}

                        <Grid item lg={12}>
                        <InputFormMultiline disabled={viewData} id="informacoesAdicionaisFinais" label="Informações adicionais" formik={formik}/>
                        </Grid>

                    </Grid>
                </CardContent>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    sx={{ p: 2 }}
                >
                    <Button onClick={handleBack} startIcon={<NavigateBeforeIcon />} variant="outlined">Voltar</Button>
                  {!viewData && <Button onClick={handleClickNext} endIcon={<SaveAltIcon />} variant="contained">Gerar petição e documentos</Button> }
                </Stack>
                <Carregando isLoading={isLoading}/>
            </Card>
        </Grid>
    );
}
