import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import React from "react";
import NumberFormat from "react-number-format";
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const NovoEndereco = ({ setAdicionar, criarEndereco }) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            cep: '',
            cidade: '',
            rua: '',
            estado: '',
            bairro: ''
        },
        validationSchema: Yup.object({
            cep: Yup.string().required('Este campo é obrigatório.'),
            cidade: Yup.string().required('Este campo é obrigatório.'),
            rua: Yup.string().required('Este campo é obrigatório.'),
            estado: Yup.string().required('Este campo é obrigatório.'),
            bairro: Yup.string().required('Este campo é obrigatório.')
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true)
            try {
                await criarEndereco(values);
                setIsLoading(false)
                resetForm()
                setAdicionar(false)
            } catch (error) {
                setIsLoading(false)
            }
        },
    });

    return (
        <Grid container spacing={2} p={2} >
            <Grid item lg={4}>
                <NumberFormat
                    name="cep"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.cep}
                    helperText={formik.touched.cep && formik.errors.cep}
                    error={Boolean(formik.touched.cep && formik.errors.cep)}
                    fullWidth
                    customInput={TextField}
                    format="#####-###"
                    mask="_"
                    label="Cep"
                    size="small"
                    variant="outlined"
                />
            </Grid>
            <Grid item lg={4}>
                <TextField
                    name="rua"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.rua}
                    helperText={formik.touched.rua && formik.errors.rua}
                    error={Boolean(formik.touched.rua && formik.errors.rua)}
                    fullWidth
                    size="small"
                    label="Rua"
                    variant="outlined"
                />
            </Grid>
            <Grid item lg={4}>
                <TextField
                    name="bairro"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.bairro}
                    helperText={formik.touched.bairro && formik.errors.bairro}
                    error={Boolean(formik.touched.bairro && formik.errors.bairro)}
                    fullWidth
                    size="small"
                    label="Bairro"
                    variant="outlined"
                />
            </Grid>
            <Grid item lg={4}>
                <TextField
                    name="estado"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.estado}
                    helperText={formik.touched.estado && formik.errors.estado}
                    error={Boolean(formik.touched.estado && formik.errors.estado)}
                    fullWidth
                    size="small"
                    label="Estado"
                    variant="outlined"
                />
            </Grid>
            <Grid item lg={4}>
                <TextField
                    name="cidade"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.cidade}
                    helperText={formik.touched.cidade && formik.errors.cidade}
                    error={Boolean(formik.touched.cidade && formik.errors.cidade)}
                    fullWidth
                    size="small"
                    label="Cidade"
                    variant="outlined"
                />
            </Grid>
            <Grid item lg={2}>
                <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={formik.handleSubmit}
                    disabled={isLoading}
                    startIcon={isLoading && <CircularProgress size={20} color="inherit" />}
                >
                    {isLoading ? 'Carregando...' : "Salvar"}
                </Button>
            </Grid>
            <Grid item lg={2}>
                <Button
                    variant="outlined"
                    fullWidth
                    color="error"
                    onClick={() => setAdicionar(false)}
                >
                    Cancelar
                </Button>
            </Grid>
        </Grid>
    );
}
