import React, { useRef } from 'react';
import { Box, TextField, LinearProgress, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import NumberFormat from "react-number-format";
import api from '../../service/api';

export const InputSearchCep = ({ id, label, formik, disabled = false, result_search = [] }) => {
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = () => {
    searchApi();
  };

  const searchApi = async () => {
    try {
      setIsLoading(true)
      const endpoint = `/api/enderecoFindByCep/${formik.values[id]}`
      const response = await api.get(endpoint);
      formEndereco(response.data);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Erro ao buscar', error);
    }
  };


  const formEndereco = (data) => {
    if (data && data.length > 0) {

      result_search.forEach((element) => {
        console.log(element.campo_preencher, data[0][element.response])
        formik.setFieldValue(element.campo_preencher, data[0][element.response]);
      })

      if (id === "cepReclamante") {
        formik.setFieldValue('enderecoEncontrado', true);
        formik.setFieldValue('idEnderecoReclamante', data[0].id);
      } else if (id === "cepReclamada") {
        formik.setFieldValue('enderecoEncontradoLocalTrabalho', true);
        formik.setFieldValue('idEnderecoReclamada', data[0].id);
      }

    }
  };

  return (
    <Tooltip arrow title={formik.touched[id] && formik.errors[id]}>
      <Box>
        <NumberFormat
          customInput={TextField}
          format="#####-###"
          mask="_"
          placeholder="Digite um CEP"
          fullWidth
          size='small'
          variant="outlined"
          id={id}
          name={id}
          inputRef={inputRef}
          label={label}
          value={formik.values[id]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={Boolean(formik.touched[id] && formik.errors[id])}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <SearchIcon
                sx={{ cursor: 'pointer' }}
                onClick={handleSearch}
                color="primary"
              />
            ),
          }}
        />
        {isLoading && <Box mt={0.5} px={1}>
          <LinearProgress />
        </Box>}
      </Box>
    </Tooltip>
  );
};
