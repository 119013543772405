import React, { useState } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import api from '../../service/api';
import useUser from '../../providers/useUser';

const InpultAdvogado = ({ formik, label, disabled = false }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const user = useUser((state) => state.user)


    const fetchOptions = async (inputValue) => {
        try {
            setIsLoading(true);
            const response = await api.get(`/api/advogado?limit=10&page=1&nome=${inputValue}&orderBy=asc&isAccepted=true&isActivated=true`);
            setOptions(response.data.data);
            setIsLoading(false);
        } catch (error) {
            setOptions([])
            setIsLoading(false);
            console.error('Erro ao buscar as opções:', error);
        }
    };

    const handleOptionSelect = (event, value) => {
        if (!value) {
            formik.setFieldValue('advogado', null);
            return;
        }

        formik.setFieldValue('advogado', value || null);
    };

    const isOptionEqualToValue = (option, value) => option.id === value.id;

    function handleOnInputChange(value) {
        if (!disabled) {
            fetchOptions(value)
        }
    }
    
    if (user.type !== "user") {
        return (
            <Box>
                <Typography sx={{ fontWeight: 300, fontSize: 19, cursor: 'default' }}>
                    Responsável
                </Typography>
                <TextField disabled fullWidth size='small' placeholder={user.nome} value={user.nome} />
            </Box>
        )
    }

    return (
        <Box>
            <Typography sx={{ fontWeight: 300, fontSize: 19, cursor: 'default' }}>
                {label}
            </Typography>
            <Autocomplete
                disabled={disabled}
                loadingText="Buscando..."
                noOptionsText="Não encontrada."
                loading={isLoading}
                options={options}
                getOptionLabel={(option) => option?.nome || ''}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={handleOptionSelect}
                onInputChange={(event, value) => handleOnInputChange(value)}
                size="small"
                value={formik.values.advogado}
                renderInput={(params) => <TextField {...params} />}
            />
        </Box>
    );
};

export default InpultAdvogado;
