import * as React from "react";
import {
    Dialog,
    Grid,
    IconButton,
    Pagination,
    Stack,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"
import List from "@mui/material/List";
import { ListItemPermissaoLoading } from "./ListItemPermissaoLoading";
import { ListItemGrupo } from "./ListItemGrupo";
import useFetchGetConfigGrupoPermissoes from "../../queries/configGrupoPermissoes";
import useFetchGetParceiroById from "../../queries/parceiroById";

export const ModalGrupoPermissaoParceiro = ({ open, userID, funcaoHandleClose, addGrupoPermissao, deleteGrupoPermissao }) => {
    const [page, setPage] = React.useState(1)
    const [gruposList, setGruposList] = React.useState([]);
    const [listaAtualizada, setListaAtualizada] = React.useState(true);

    const { data, isLoading } = useFetchGetParceiroById(userID);
    const { data: dataGrupoPermissao, isLoading: isLoadingGrupoPermissao } = useFetchGetConfigGrupoPermissoes(page);

    const handleClose = () => {
        funcaoHandleClose();
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };


    React.useEffect(() => {
        if (data && dataGrupoPermissao) {
          const grupoAtual = data.grupos.map(current => current.grupo);
          const listgrupos = dataGrupoPermissao.data.map(current => current);

          const newlist = listgrupos.map(current => {
            const isActived = grupoAtual.some(item => item.id === current.id);
            return { isActived, ...current };
          });
    
          setGruposList(newlist);
          setListaAtualizada(false)
        }
      }, [data, dataGrupoPermissao]);


    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            onClose={handleClose}
            PaperProps={{
                style: {
                    width: "100%",
                },
            }}
        >
            <Grid container p={2}>
                <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "#D8D8D8"  }}>
                    <Stack
                        sx={{ width: "100%" }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        p={1}
                    >
                        <Typography sx={{fontSize: 18, fontWeight: 500}}>Lista de grupos</Typography>
                        <IconButton
                            size="small"
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="Fechar"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>

                </Grid>
                <Grid item p={1} xs={6} sx={{ borderRight: gruposList.length > 8 ? 1 : 0, borderColor: "#D8D8D8" }}>
                    <List dense aria-label="dataGrupoPermissao">
                        {!isLoadingGrupoPermissao && gruposList.slice(0, 8).map((current) => (
                                <ListItemGrupo
                                    key={current.id}
                                    title={current.nome_grupo}
                                    isActived={current.isActived}
                                    idGrupo={current.id}
                                    idUser={userID}
                                    addGrupoPermissao={addGrupoPermissao}
                                    deleteGrupoPermissao={deleteGrupoPermissao}
                                />
                            ))}
                            {(isLoadingGrupoPermissao || isLoading || listaAtualizada) && [1, 2, 3, 4, 5, 6, 7, 8].map(current => (
                            <ListItemPermissaoLoading key={current} />
                        ))}
                    </List>
                </Grid>
                <Grid item p={1} xs={6}>
                    <List dense aria-label="dataGrupoPermissaoDesativada">
                        {!isLoadingGrupoPermissao && gruposList.slice(8).map((current) => (
                                <ListItemGrupo
                                    key={current.id}
                                    title={current.nome_grupo}
                                    isActived={current.isActived}
                                    idGrupo={current.id}
                                    idUser={userID}
                                    addGrupoPermissao={addGrupoPermissao}
                                    deleteGrupoPermissao={deleteGrupoPermissao}
                                />
                            ))}
                        {(isLoadingGrupoPermissao || isLoading || listaAtualizada) && [1, 2, 3, 4, 5, 6, 7, 8].map(current => (
                            <ListItemPermissaoLoading key={current} />
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12}>
               {dataGrupoPermissao && dataGrupoPermissao.totalPages > 1 && <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        py={2}
                        sx={{width: '100%'}}
                    >
                        {dataGrupoPermissao && <Pagination page={page} onChange={handleChangePage} size="small" count={dataGrupoPermissao.totalPages} color="primary" />}
                    </Stack>}
                </Grid>
            </Grid>
        </Dialog>
    );
};
