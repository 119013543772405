import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Chip } from '@mui/material';
import PageviewIcon from '@mui/icons-material/Pageview';
import { useNavigate } from 'react-router-dom';
import { TextTooltipDetalhe } from './TooltipDetalhe';
import { encryptJSON } from '../utils/jsonCrypto';


export default function TablePeticao({ rows = [] }) {
  let navigate = useNavigate();

  const handleDetalhe = (current) => () => {
    if(current.form_id){
      const idResposta = current.id_resposta
      const idPeticao = current.id
      const status = current.status
      const path = encryptJSON({ idResposta, idPeticao, status })
      
      navigate(`/detalhe/peticao/${path}`);
    }else{
      navigate(`/detalhe-old/peticao/${current.id}`, { state: current });
    }
  };

  return (
    <Table sx={{ width: "100%" }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Formulário</TableCell>
          <TableCell>Protocolo</TableCell>
          <TableCell>Nome do Reclamante</TableCell>
          <TableCell>CPF do Reclamante</TableCell>
          <TableCell>Razão Social Reclamada</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Local</TableCell>
          <TableCell>ações</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <TableRow
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            hover
          >
            <TableCell>{row.form ? row.form.descricao : "Processo padrão"}</TableCell>
            <TableCell>{row.protocolo}</TableCell>
            <TableCell>{row.nomeReclamante ? <TextTooltipDetalhe maxLength={22} text={row.nomeReclamante} /> : '-'}</TableCell>
            <TableCell>{row.cpfReclamante || "-"}</TableCell>
            <TableCell>{row.razaoSocialReclamada ? <TextTooltipDetalhe maxLength={19} text={row.razaoSocialReclamada} /> : '-'}</TableCell>
            <TableCell><StatusPeticao status={row.status} /></TableCell>
            <TableCell><ChipLocal isClienteLocal={row.isClienteLocal} /></TableCell>
            <TableCell><Button startIcon={<PageviewIcon />} onClick={handleDetalhe(row)} size='small' variant='contained'>Ver Detalhe</Button></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}


function StatusPeticao({ status }) {
  if (!status) return <Chip size='small' label="Não definido" />

  const backgroundColor = status.color;

  function getContrastColor(backgroundColor) {
    const r = parseInt(backgroundColor.slice(1, 3), 16);
    const g = parseInt(backgroundColor.slice(3, 5), 16);
    const b = parseInt(backgroundColor.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    const textColor = brightness > 128 ? '#000' : '#fff';
    return textColor;
  }

  const textColor = getContrastColor(backgroundColor);

  return (
    <Chip
      size='small'
      sx={{
        bgcolor: backgroundColor,
        color: textColor,
      }}
      label={status.nome}
    />
  );

}

function ChipLocal({ isClienteLocal }) {

  return (
    <Chip
      size='small'
      label={isClienteLocal ? "Sim" : "Não"}
      variant={isClienteLocal ? "outlined" : "filled"}
    />
  );

}






