import * as React from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  List,
  ListSubheader,
  Pagination,
  Skeleton,
  Stack,
} from "@mui/material";
import { TitlePage } from "../../TitlePage";
import { ListFormularios } from "../../ListFormularios";
import useFetchGetConfigFormularios from "../../../queries/configFormularios";
import FormularioDialogs from "../../FormularioEditar/FormularioDialogs";
import AddIcon from '@mui/icons-material/Add';

export const Formularios = () => {
  const [page, setPage] = React.useState(1);
  const [formularioDialogSettings, setFormularioDialogSettings] = React.useState(null);

  const openNewForm = (descricao) => {
    setFormularioDialogSettings({ editar: false })
  }

  const { data, isLoading } = useFetchGetConfigFormularios(page)

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Skeleton variant="rounded" width="100%" height="100%" />
          <CircularProgress style={{ position: 'absolute' }} />
        </Box>
      ) : (
        <Card sx={{ height: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
          >
            <TitlePage title="Formulários" />
            <Button onClick={openNewForm} startIcon={<AddIcon />} variant="contained"> Adicionar </Button>
          </Stack>
          <Box py={1}>
            <Divider />
          </Box>
          <List
            sx={{ width: "100%", bgcolor: "#bcd7f330" }}
            subheader={<ListSubheader>Lista</ListSubheader>}
          >
            {data && data.data.map((current, index, arr) => (
              <ListFormularios
                key={current.id}
                id={current.id}
                title={current.descricao}
                borderBottom={index === arr.length - 1 ? 0 : 1}
              />
            ))}
          </List>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            my={2}
          >
            {!isLoading && data && data.totalPages > 1 && <Pagination color="primary" count={data.totalPages} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} />}
          </Stack>
        </Card>
      )}
      {formularioDialogSettings && <FormularioDialogs dialogSettings={formularioDialogSettings} setDialogSettings={setFormularioDialogSettings} />}

    </React.Fragment>
  );
};
