import * as React from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListSubheader,
  Pagination,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import { TitlePage } from "../../TitlePage";
import usePlataforma from "../../../hooks/usePlataforma";
import { ListUserDeatlhe } from "../../ListUserDeatlhe";
import Modal from "../../Modal";
import useFetchGetConfigUser from "../../../queries/configUser";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import NumberFormat from "react-number-format";
import AddIcon from '@mui/icons-material/Add';
import PasswordIcon from '@mui/icons-material/Password';
import { ModalPermissaoUser } from "../ModalPermissaoUser";
import { ModalGrupoPermissao } from "../ModalGrupoPermissao";


export const Usuarios = () => {
  const {
    criarUserConfig,
    editarUserConfig,
    chanceStatusConfigUser,
    addPermissaoUser,
    deletePermissaoUser,
    addGrupoPermissaoUser,
    deleteGrupoPermissaoUser
  } = usePlataforma();
  const [page, setPage] = React.useState(1);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("Novo Usuário");
  const [carregando, setCarregando] = React.useState(false);
  const [editar, setEditar] = React.useState(false);
  const [editarSenha, setEditarSenha] = React.useState(false);
  const [modalPermissao, setModalPermissao] = React.useState(false);
  const [userIDPermissao, setUserIDPermissao] = React.useState(null);

  const [ModalOpenGrupoPermissao, setModalOpenGrupoPermissao] = React.useState(false);
  const [userIDGrupoPermissao, setUserIDGrupoPermissao] = React.useState(null);

  const { data, isLoading } = useFetchGetConfigUser(page)

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const formik = useFormik({
    initialValues: {
      nome: "",
      email: "",
      senha: "",
      cpf: "",
      id: ""
    },
    validationSchema: Yup.object({
      cpf: Yup.string().required('Este campo é obrigatório.'),
      nome: Yup.string().required('Este campo é obrigatório.'),
      email: Yup.string().email('Deve ser um e-mail válido').required('E-mail é obrigatório')
    }),
    onSubmit: async (values) => {
      setCarregando(true)
      try {
        if (editar) {
          await editarUserConfig(values)
        } else {
          await criarUserConfig(values)
        }
      } catch (error) {
        setCarregando(false)
      }
      handleFechar()
    }
  });

  function handleAdicionar() {
    setModalTitle("Novo Usuário")
    setModalOpen(true)
  }

  function handleEditarUsuario(values) {
    setEditar(true)
    formik.setValues({
      nome: values.nome,
      email: values.email,
      cpf: values.cpf,
      id: values.id,
      senha: ""
    })
    setModalTitle("Editar Usuário")
    setModalOpen(true)
  }

  function handleFechar() {
    setCarregando(false)
    formik.resetForm()
    setEditar(false)
    setEditarSenha(false)
    setModalOpen(false)
  }

  function handleFecharModalPermissao() {
    setModalPermissao(false)
    setUserIDPermissao(null)
  }

  function handleAbrirModalPermissao(id) {
    setModalPermissao(true)
    setUserIDPermissao(id)
  }

  function handleAbrirModalGrupoPermissao(id) {
    setModalOpenGrupoPermissao(true)
    setUserIDGrupoPermissao(id)
  }

  function handleFecharModalGrupoPermissao() {
    setModalOpenGrupoPermissao(false)
    setUserIDGrupoPermissao(null)
  }


  return (
    <React.Fragment>
      {isLoading ? (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Skeleton variant="rounded" width="100%" height="100%" />
        <CircularProgress style={{ position: 'absolute' }} />
      </Box>
      ) : (
        <Card sx={{ height: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            px={2}
          >
            <TitlePage title="Usuários" />
            <Box pr={2}>
              {!modalOpen && <Button onClick={handleAdicionar} startIcon={<AddIcon />} variant="contained"> Adicionar </Button>}
            </Box>
          </Stack>
          <Box py={1}>
            <Divider />
          </Box>
          <List
            sx={{ width: "100%", bgcolor: "#bcd7f330" }}
            subheader={<ListSubheader>Lista</ListSubheader>}
          >
            {data && data.data.map((current, index, arr) => (
              <ListUserDeatlhe
                key={current.id}
                id={current.id}
                current={current}
                title={`${current.nome} - ${current.email}`}
                borderBottom={index === arr.length - 1 ? 0 : 1}
                isActivated={current.isActivated}
                handleEditarUsuario={handleEditarUsuario}
                chanceStatusConfigUser={chanceStatusConfigUser}
                handleAbrirModalPermissao={handleAbrirModalPermissao}
                handleAbrirModalGrupoPermissao={handleAbrirModalGrupoPermissao}
              />
            ))}
          </List>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            my={2}
          >
            {!isLoading && data && data.totalPages > 1 && <Pagination color="primary" count={data.totalPages} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} />}
          </Stack>
        </Card>
      )}
      <Modal handleCancelar={handleFechar} handleSubmit={formik.handleSubmit} isLoading={carregando} open={modalOpen} setOpen={setModalOpen} title={modalTitle}>
        <Grid container spacing={2} >
          <Grid item lg={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Nome"
              name="nome"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.nome}
              helperText={formik.touched.nome && formik.errors.nome}
              error={Boolean(formik.touched.nome && formik.errors.nome)}
            />
          </Grid>
          <Grid item lg={6}>
            <NumberFormat
              format="###.###.###-##"
              customInput={TextField}
              mask="_"
              fullWidth
              variant="outlined"
              label="CPF"
              name="cpf"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.cpf}
              helperText={formik.touched.cpf && formik.errors.cpf}
              error={Boolean(formik.touched.cpf && formik.errors.cpf)}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              name="email"
              type="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              helperText={formik.touched.email && formik.errors.email}
              error={Boolean(formik.touched.email && formik.errors.email)}
            />
          </Grid>
          <Grid item lg={6}>
            {(!editar || editarSenha) ?  <TextField
              fullWidth
              variant="outlined"
              label="Senha"
              name="senha"
              type="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.senha}
              helperText={formik.touched.senha && formik.errors.senha}
              error={Boolean(formik.touched.senha && formik.errors.senha)}
            /> : <Button sx={{height: 55}} fullWidth onClick={() => setEditarSenha(true)} startIcon={<PasswordIcon />} variant="outlined"> Editar senha</Button>}
          </Grid>

        </Grid>
      </Modal>
     {userIDPermissao && (
     <ModalPermissaoUser
     userID={userIDPermissao}
     open={modalPermissao}
     funcaoHandleClose={handleFecharModalPermissao} 
     addPermissaoUser={addPermissaoUser}
     deletePermissaoUser={deletePermissaoUser}
     /> 
    )}
     {userIDGrupoPermissao && (
     <ModalGrupoPermissao
     userID={userIDGrupoPermissao}
     open={ModalOpenGrupoPermissao}
     funcaoHandleClose={handleFecharModalGrupoPermissao} 
     addGrupoPermissao={addGrupoPermissaoUser}
     deleteGrupoPermissao={deleteGrupoPermissaoUser}
     /> 
    )}
     </React.Fragment>
  );
};
