import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { TitlePage } from "../../TitlePage";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import useFetchGetConfigContratosTemplates from "../../../queries/configContratosTemplates";
import DescriptionIcon from '@mui/icons-material/Description';
import api from "../../../service/api";
import ModalUI from "../../ModalUI";
import { useQueryClient } from "react-query";
import Swal from "sweetalert2";

export const Contratos = () => {

  const { isLoading, data } = useFetchGetConfigContratosTemplates(1)

  return (
    <React.Fragment>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Skeleton variant="rounded" width="100%" height="100%" />
          <CircularProgress style={{ position: 'absolute' }} />
        </Box>
      ) : (
        <Card sx={{ height: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
          >
            <TitlePage title="Contratos" />
            <ButtonUploadModelo />
          </Stack>

          <Box py={1}>
            <Divider />
          </Box>

          <List >
            {data?.data.map((item) => (
              <ListItem
                sx={{ bgcolor: "#bcd7f330", my: 0.2 }}
                key={item.id}
                secondaryAction={<ButtonDownloadModelo filename={item.nome} idModelo={item.id} />}
              >
                <ListItemAvatar>
                  <Avatar>
                    <DescriptionIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText secondary={item.nome} primary={<Typography sx={{ fontWeight: 500, fontSize: '1rem' }}>{item.label}</Typography>} />
              </ListItem>
            ))}
          </List>

        </Card>
      )}

    </React.Fragment>
  );
};

function ButtonDownloadModelo({ idModelo, filename }) {
  const [isLoading, setIsLoading] = React.useState(false);

  async function handleDownloadModelo() {
    setIsLoading(true);
    try {
      const response = await api.get(`/api/contratos/upload-templates/download/${idModelo}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      console.error('Erro ao baixar o modelo:', error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button
      onClick={handleDownloadModelo}
      disabled={isLoading}
      size="small"
      startIcon={isLoading ? <CircularProgress size={18} color="inherit" /> : <FileDownloadIcon />}
      variant="contained"
    >
      {isLoading ? "AGUARDE.." : "BAIXAR MODELO"}
    </Button>
  );
}

function ButtonUploadModelo() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [label, setLabel] = React.useState('');
  const [file, setFile] = React.useState(null);
  const queryClient = useQueryClient();

  async function handleUploadModelo() {
    if (!file || !label) {
      console.error('Nome do modelo e arquivo são obrigatórios.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setIsLoading(true);

      await api.post(`/api/contratos/upload-templates?nome=${label}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      queryClient.invalidateQueries(['configContratosTemplates'])
      Swal.fire({
        icon: 'success',
        title: 'Atenção',
        text: "Modelo enviado com sucesso!",
    });
      setOpenModal(false);
      setLabel('');
      setFile(null);

    } catch (error) {
      console.error('Erro ao enviar o modelo:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <React.Fragment>
      <Button
        onClick={() => setOpenModal(true)}
        startIcon={isLoading ? <CircularProgress size={18} color="inherit" /> : <DriveFolderUploadIcon />}
        size="small"
        disabled={isLoading}
        variant="contained"
      >
        {isLoading ? "ENVIANDO..." : "ENVIAR MODELO"}
      </Button>

      <ModalUI isLoading={isLoading} open={openModal} onClose={() => setOpenModal(false)} title='Enviar modelo'>
        <Box my={2}>
          <Typography sx={{ fontWeight: 500, fontSize: 14, mb: 0.5 }}>Nome modelo</Typography>
          <TextField
            id="label"
            placeholder="Nome modelo"
            size="small"
            variant="outlined"
            fullWidth
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </Box>

        <Box mb={2}>
          <Typography sx={{ fontWeight: 500, fontSize: 14, mb: 0.5 }}>Word modelo</Typography>
          <TextField
            id="file"
            type="file"
            placeholder="Escolha o arquivo"
            size="small"
            variant="outlined"
            fullWidth
            onChange={handleFileChange}
          />
        </Box>

        <Box mb={2}>
          <Button disabled={isLoading || (!label || !file)} onClick={handleUploadModelo} variant="contained" fullWidth>
            ENVIAR MODELO
          </Button>
        </Box>
      </ModalUI>
    </React.Fragment>
  );
}