import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import useToken from '../providers/useToken';

export function ButtonUpload({ fileList, setFileList }) {

    const props = {
        maxCount: 1,
        onRemove() {
            setFileList([])
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList
    };

    return (
        <Upload {...props}>
            <Button icon={<UploadOutlined />}>Adicionar anexo</Button>
        </Upload>
    );
}
