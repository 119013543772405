import { useQuery } from "react-query";
import api from "../../service/api";

async function getPeticao(ctx) {
  const [, page, idAdvogado, idCliente] = ctx.queryKey
  const { data } = await api.get('/api/peticao', { params: { page, idAdvogado, idCliente } })
  return data
}

export default function useFetchGetPeticao(page, idAdvogado, idCliente) {
    return useQuery(['peticao', page, idAdvogado, idCliente], getPeticao, { refetchOnWindowFocus: false})
}