import { Stack, Typography } from "@mui/material";

export const TitlePage = ({ title }) => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 4, mt: 3 }}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
            >
                <Typography sx={{fontWeight: 700, fontSize: 24}}>
                    {title}
                </Typography>
            </Stack>
        </Stack>
    );
}
