import * as React from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Pagination,
  Skeleton,
  Stack,
} from "@mui/material";
import { TitlePage } from "../../TitlePage";
import usePlataforma from "../../../hooks/usePlataforma";
import { ListEnderecos } from "../ListEnderecos";
import AddIcon from '@mui/icons-material/Add';
import { NovoEndereco } from "../NovoEndereco";
import useFetchGetConfigEndereco from "../../../queries/configEndereco";

export const Endereco = () => {
  const { excluirEndereco, criarEndereco, atualizarEndereco } = usePlataforma()
  const [page, setPage] = React.useState(1);
  const [adicionar, setAdicionar] = React.useState(false);

  const { data, isLoading } = useFetchGetConfigEndereco(page)

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Skeleton variant="rounded" width="100%" height="100%" />
        <CircularProgress style={{ position: 'absolute' }} />
      </Box>
      ) : (
        <Card sx={{ height: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
          >
            <TitlePage title="Endereço" />
            <Box pr={2}>
              {/* {!adicionar && <Button onClick={() => setAdicionar(true)} startIcon={<AddIcon />} variant="contained"> Adicionar </Button>} */}
            </Box>
          </Stack>
          <Box py={1}>
            <Divider />
          </Box>
          {adicionar && <NovoEndereco criarEndereco={criarEndereco} setAdicionar={setAdicionar} />}
          {data && data.data.map((current, index, arr) => (
            <ListEnderecos
              key={current.id}
              borderBottom={index === arr.length - 1 ? 0 : 1}
              current={current}
              funcaoExcluir={excluirEndereco}
              funcaoAtualizar={atualizarEndereco}
            />
          ))}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            my={2}
          >
           {!isLoading && data && data.totalPages > 1 && <Pagination color="primary" count={data.totalPages} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} />}
          </Stack>
        </Card>
      )}
    </React.Fragment>
  );
};
