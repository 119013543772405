import * as React from 'react';
import { Avatar, Box, Button, Stack } from "@mui/material";
import MenuNavBar from "./MenuNavBar";
import useUser from '../providers/useUser';
import { getInitials } from '../utils/get-initials';
import { useLocation, useNavigate } from 'react-router-dom';
import NotificationComponent from './NotificationComponent';
import useFetchGetConfigs from '../queries/cliente';
import useToken from "../providers/useToken";


export const NavBar = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const user = useUser((state) => state.user)
    const shortId = useToken((state) => state.shortIdAdvogado)
    let navigate = useNavigate();
    const location = useLocation();
    const podeVisualizarComponente = useUser((state) => state.podeVisualizarComponente)
    const { isLoading: isLoadingConfig, data: configs } = useFetchGetConfigs(shortId)

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClickLink = (path) => {
        navigate(path);
    };

    const handleClickhome = () => {
        navigate("/");
    };

    var menuLink = [
        { nome: "Dashboard", path: "/", showTo: ["advogado", "user", "client"] },
        { nome: "Parceiros", path: "/parceiros", showTo: ["user"] },
        { nome: "Configurações", path: "/configuracoes", showTo: ["user"] },
        { nome: "Financeiro", path: "/financeiro", showTo: ["advogado", "user"] },
    ]

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
            sx={{ width: "100%", height: 72, bgcolor: "#fff", borderBottom: 1, borderColor: "#D8D8D8" }}
        >

            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
                spacing={1}
                sx={{ ml: 2 }}
            >
                {!isLoadingConfig &&
                    <Box onClick={handleClickhome} sx={{ textAlign: "center", maxWidth: 170, cursor: "pointer" }}>
                        <img
                            alt="logo"
                            src={configs ? configs.logo || "/logo.png" : "./logo.png"}
                            style={{
                                marginLeft: '10px',
                                display: "inline-block",
                                width: '100%',
                            }}
                        />
                    </Box>
                }
                <Box sx={{ width: 60 }} />
                {menuLink.map((current, i) => (
                    podeVisualizarComponente(current.showTo) ? (
                        <Box key={current.path}>
                            <Button
                                size="large"
                                fullWidth
                                onClick={() => handleClickLink(current.path)}
                                sx={{
                                    borderBottom: location.pathname === current.path ? '2px solid' : "none",
                                    borderColor: '#000',
                                    height: '100%',
                                    borderRadius: '0px',
                                    color: location.pathname === current.path ? '#000000' : '#A0A0A0',
                                    '&:hover': {
                                        color: 'inherit',
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                {current.nome}
                            </Button>
                        </Box>
                    ) : null
                ))}
            </Stack>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ pr: 4, height: "100%" }}
            >
                <NotificationComponent />
                <Avatar onClick={handleOpenMenu} sx={{ cursor: "pointer", width: 39, height: 39, bgcolor: "#D9D9D9", color: '#545454', fontSize: 16, fontWeight: 500 }}>{getInitials(user.nome)}</Avatar>
                <MenuNavBar navigate={navigate} anchorEl={anchorEl} handleOpenMenu={handleOpenMenu} handleCloseMenu={handleCloseMenu} />
            </Stack>

        </Stack>
    );
}
