import { Box } from "@mui/material";
import { NavBar } from "../components/NavBar";
import ViewPeticao from "../components/ViewPeticao";
import { useLocation, useParams } from "react-router-dom";
import React from "react";
import useUser from "../providers/useUser";

export const Peticao = () => {
    const location = useLocation();
    const { state } = location;
    const { id } = useParams();
    const user = useUser((state) => state.user)

    return (
        <Box>
            <NavBar />
            <ViewPeticao user={user} type={user.type} viewData={id ? state : null} />
        </Box>
    );
}
