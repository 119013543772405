
import * as React from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import usePlataforma from '../hooks/usePlataforma';
import { Carregando } from '../components/Carregando';

export const RecuperarSenha = () => {
  const location = useLocation();
  const { state } = location;
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const { forgotPasswordByEmail, requestCodeEmail } = usePlataforma()

  React.useEffect(() => {
    if (!state) {
      navigate(-1, { replace: true });
    }
  }, [state, navigate]);


  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Deve ser um e-mail válido').required('E-mail é obrigatório')
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const data =  await forgotPasswordByEmail(values.email, state.type)
        await requestCodeEmail(data.access_token)
        setIsLoading(false)
        navigate("/nova-senha", { replace: true, state: { linkLogin: state.linkLogin, access_token: data.access_token } });
      } catch (error) {
        setIsLoading(false)
      }
    },
  });



  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1.5}
      sx={{ height: '100vh', '& > :not(style) + :not(style)': { marginTop: 0 } }}
    >

      <Box sx={{ textAlign: "center", maxWidth: { xs: 200, lg: 250 }, mb: 2 }}>
        <img
          alt="logo"
          src="/logo.png"
          style={{
            display: "inline-block",
            width: '100%',
          }}
        />
      </Box>

      <Box sx={{ px: { xs: 4 } }}>
        <Typography sx={{ fontWeight: 600, fontSize: { lg: 22, xs: 19 }, color: "#636166" }}>
        Recuperar senha
        </Typography>
        <Typography sx={{ fontWeight: 300, fontSize: { lg: 16, xs: 12 }, color: "#636166" }}>
        Você receberá um e-mail para configurar uma nova senha.
        </Typography>
      </Box>

      <Box sx={{ width: "100%", px: { xs: 4, lg: 60 } }}>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box mt={1}>
            <TextField
              fullWidth
              variant="outlined"
              label='Email'
              name="email"
              type='email'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              helperText={formik.touched.email && formik.errors.email}
              error={Boolean(formik.touched.email && formik.errors.email)}
            />
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%", mt: 2 }}

          >
            <Button type="submit" fullWidth variant="contained">Próximo</Button>
          </Stack>
        </form>
      </Box>
      <Carregando isLoading={isLoading} />
    </Stack>
  );
}
