import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { LinearProgress, ListSubheader, Pagination, Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import useFetchGetConfigFormularios from '../queries/configFormularios';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export function ModalSelectForm({ open, setOpen }) {
    const [page, setPage] = React.useState(1);

    const { data, isLoading } = useFetchGetConfigFormularios(page)

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Formulários
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            {isLoading && <LinearProgress />}
            <DialogContent dividers>
                <CheckboxListSecondary formularios={data?.data} />
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    my={2}
                >
                    {!isLoading && data && data.totalPages > 1 && <Pagination color="primary" count={data.totalPages} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} />}
                </Stack>
            </DialogContent>
        </BootstrapDialog>
    );
}

function CheckboxListSecondary({ formularios }) {
    let navigate = useNavigate();

    async function handleClickForm(id) {
        navigate(`/formulario/${id}`)
    }

    return (
        <List
        subheader={<ListSubheader>Selecione uma opção</ListSubheader>}
         dense sx={{ width: '100%', minWidth: 400 }}>
            {formularios && formularios.map((row) => {
                const labelId = `checkbox-list-secondary-label-${row.id}`;
                return (
                    <ListItem
                        key={row.id}
                        secondaryAction={
                            <IconButton aria-label="selecionar" onClick={() => handleClickForm(row.id)}>
                                <RadioButtonUncheckedIcon color="disabled" />
                            </IconButton>
                        }
                        disablePadding
                    >
                        <ListItemButton onClick={() => handleClickForm(row.id)}>
                            <ListItemAvatar>
                                <Avatar sx={{bgcolor: "#29a404", width: 16, height: 16 }}> </Avatar>
                            </ListItemAvatar>
                            <ListItemText id={labelId} primary={row.descricao} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}