import React from "react";
import { Skeleton, Stack } from "@mui/material";

export const TableLoading = ({isLoading, data}) => {
    return (
        <React.Fragment>
            {isLoading && !data && (
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    sx={{ height: "100%", border: 1, borderColor: "#D8D8D8" }}
                >
                    <Skeleton variant="rectangular" width="100%" height={50} />
                    <Skeleton variant="rectangular" width="100%" height={50} />
                    <Skeleton variant="rectangular" width="100%" height={50} />
                    <Skeleton variant="rectangular" width="100%" height={50} />
                    <Skeleton variant="rectangular" width="100%" height={50} />
                </Stack>
            )}
        </React.Fragment>
    );
}
