import { useQuery } from "react-query";
import api from "../../service/api";

async function getConfigFormulariosID(ctx) {
  const [, id] = ctx.queryKey
  if (id) {
    const { data } = await api.get(`/api/form/${id}`)
    return data
  }
  return null
}

export default function useFetchGetConfigFormulariosID(id) {
  return useQuery(['configFormularios[id]', id], getConfigFormulariosID, { refetchOnWindowFocus: false })
}