import { Chip } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export const StatusSolicitacao = ({ status }) => {

  const options = {
    "PENDENTE": {icon: <ErrorIcon />, color: "warning", label: status },
    "EM_PROCESSAMENTO": {icon: <SwapHorizontalCircleIcon />, color: "info", label: status },
    "PAGO": {icon: <CheckCircleIcon />, color: "success", label: status },
    "RECUSADO": {icon: <CancelIcon />, color: "error", label: status },
  }

  return <Chip sx={{minWidth: 110}} size="small" icon={options[status].icon} label={options[status].label} color={options[status].color} />
}
