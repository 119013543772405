import { useQuery } from "react-query";
import api from "../../service/api";

async function getSatusPeticao(ctx) {
  const [, page, limit] = ctx.queryKey
  const { data } = await api.get('/api/listStatus', { params: { page, limit: limit ? limit : 100 } })
  return data
}

export default function useFetchGetStaugetSatusPeticao(page = 1, limit) {
  return useQuery(['getSatusPeticao', page, limit], getSatusPeticao, { refetchOnWindowFocus: false })
}