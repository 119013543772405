/**
 * Processa um objeto, transformando valores de acordo com as regras definidas.
 *
 * @param {Object} obj - O objeto a ser processado.
 * @returns {Object} - Um novo objeto com os valores transformados:
 * - Se o valor for um objeto com uma propriedade `id`, transforma-o na string do `id`.
 * - Se o valor for uma string vazia, retorna `null`.
 * - Se o valor for uma string não vazia, mantém a string original.
 */
export function processObject(obj) {
    const result = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            if (typeof value === 'object' && value !== null && 'id' in value) {
                result[key] = String(value.id);
            } else if (value === '') {
                result[key] = null;
            } else if (typeof value === 'string') {
                result[key] = value;
            }
        }
    }

    return result;
}