import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Backdrop, Box, Breadcrumbs, Chip, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ControlledCheckbox from '../CheckboxForm';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import api from '../../service/api';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { slugify } from '../../utils/slugify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CustomizedDialogs({ setDialogSettings, dialogSettings, tipoCampo }) {
    const queryClient = useQueryClient();
    const [carregando, setCarregando] = React.useState(false);

    const handleClose = () => {
        setDialogSettings(null)
    };

    const formik = useFormik({
        initialValues: {
            nome: dialogSettings.editar ? dialogSettings.campo.campo.nome : "",
            nome_id: dialogSettings.editar ? dialogSettings.campo.nome_id : "",
            prioridade: dialogSettings.editar ? dialogSettings.campo.prioridade : 1,
            tamanho_label: dialogSettings.editar ? dialogSettings.campo.tamanho_label : 12,
            qtd_max_caracter: dialogSettings.editar ? dialogSettings.campo.qtd_max_caracter : 999,
            qtd_min_caracter: dialogSettings.editar ? dialogSettings.campo.qtd_min_caracter : 1,
            value_depends: dialogSettings.editar ? dialogSettings.campo.campo.value_depends : "",
            depends_on: dialogSettings.editar ? dialogSettings.campo.campo.depends_on : "",
            tipo_campo_id: dialogSettings.editar ? dialogSettings.campo.campo.tipo_campo_id : "",
            search: dialogSettings.editar ? dialogSettings.campo.campo.search : false,
            activated: dialogSettings.editar ? dialogSettings.campo.activated : true,
            obrigatorio: dialogSettings.editar ? dialogSettings.campo.obrigatorio : false,
            grupo_campo_formulario_id: dialogSettings.grupo.id,
            formulario_id: dialogSettings.formulario_id,
            campo_id: dialogSettings.editar ? dialogSettings.campo.campo_id : "",
            options: dialogSettings.editar ? dialogSettings.campo.campo.options : [],
            result_search: dialogSettings.editar ? dialogSettings.campo.result_search : []
        },
        validationSchema: Yup.object({
            nome: Yup.string().required('Este campo é obrigatório.'),
            nome_id: Yup.string().required('Este campo é obrigatório.').matches(/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/, 'Este campo deve ter um formato de slug.'),
            prioridade: Yup.number().required('Este campo é obrigatório.'),
            tamanho_label: Yup.number().required('Este campo é obrigatório.').min(1, 'O tamanho mínimo é 1.').max(12, 'O tamanho máximo é 12.'),
            qtd_max_caracter: Yup.number().required('Este campo é obrigatório.').min(1, 'O tamanho mínimo é 1.').max(9999, 'O tamanho máximo é 9999.'),
            qtd_min_caracter: Yup.number().required('Este campo é obrigatório.').min(1, 'O tamanho mínimo é 1.').max(9999, 'O tamanho máximo é 9999.'),
            tipo_campo_id: Yup.string().required('Este campo é obrigatório.'),
        }),
        onSubmit: async (values) => {
            try {
                setCarregando(true)

                if (dialogSettings.editar) {

                    await api.put("/api/formularios/campos", {
                        id: values.campo_id,
                        nome: values.nome,
                        tipo_campo_id: values.tipo_campo_id,
                        search: values.search,
                        value_depends: values.value_depends,
                        depends_on: values.depends_on,
                        options: values.options
                    })

                    await api.put("/api/formularios/campo-form", {
                        id: dialogSettings.campo.id,
                        campo_id: values.campo_id,
                        nome_id: values.nome_id,
                        prioridade: values.prioridade,
                        tamanho_label: values.tamanho_label,
                        formulario_id: values.formulario_id,
                        grupo_campo_formulario_id: values.grupo_campo_formulario_id,
                        qtd_max_caracter: values.qtd_max_caracter,
                        qtd_min_caracter: values.qtd_min_caracter,
                        obrigatorio: values.obrigatorio,
                        activated: values.activated,
                        result_search: values.result_search,
                    })

                    toast.success("Campo editado com sucesso!")

                } else {

                    const responseCampos = await api.post("/api/formularios/campos", {
                        nome: values.nome,
                        tipo_campo_id: values.tipo_campo_id,
                        search: values.search,
                        value_depends: values.value_depends,
                        depends_on: values.depends_on,
                        options: values.options
                    })


                    await api.post("/api/formularios/campo-form", {
                        campo_id: responseCampos.data.id,
                        nome_id: values.nome_id,
                        prioridade: values.prioridade,
                        tamanho_label: values.tamanho_label,
                        formulario_id: values.formulario_id,
                        grupo_campo_formulario_id: values.grupo_campo_formulario_id,
                        qtd_max_caracter: values.qtd_max_caracter || null,
                        qtd_min_caracter: values.qtd_min_caracter || null,
                        obrigatorio: values.obrigatorio,
                        result_search: values.result_search
                    })

                    toast.success("Campo criado com sucesso!")

                }

                setCarregando(false)

                queryClient.invalidateQueries(['configFormularios[id]'])

                handleClose()

            } catch (error) {
                toast.error("Erro ao alterar o campo.")
                setCarregando(false)
                console.log(error)
            }
        },
    });

    React.useEffect(() => {
        if (!dialogSettings.editar) {
            formik.setFieldValue("nome_id", slugify(formik.values.nome))
        }
    }, [formik.values.nome]);

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={Boolean(dialogSettings)}
        >
            {carregando && <LinearProgress />}
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Typography key="1" color="text.primary">
                        Grupos
                    </Typography>
                    <Typography key="2" color="text.primary">
                        {dialogSettings.grupo.nome_label}
                    </Typography>
                </Breadcrumbs>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid container spacing={2} sx={{ minWidth: 300 }}>
                    <Grid item xs={6}>
                        <InputForm formik={formik} id="nome" label="Nome" />
                    </Grid>
                    <Grid item xs={6}>
                        <InputForm formik={formik} id="nome_id" label="Nome SLUG" />
                    </Grid>
                    <Grid item xs={3}>
                        <InputForm formik={formik} type="number" label="Prioridade" id="prioridade" />
                    </Grid>
                    <Grid item xs={3}>
                        <InputForm formik={formik} type="number" label="Tamanho Label" id="tamanho_label" />
                    </Grid>
                    <Grid item xs={3}>
                        <InputForm formik={formik} type="number" label="Qtd. mín. caract." id="qtd_min_caracter" />
                    </Grid>
                    <Grid item xs={3}>
                        <InputForm formik={formik} type="number" label="Qtd. máx. caract." id="qtd_max_caracter" />
                    </Grid>
                    <Tooltip arrow placement="right" title="Informe o nome do campo cujo valor será verificado. Deixe em branco se não houver essa dependência.">
                        <Grid item xs={12}>
                            <InputForm formik={formik} label="Depende do campo" id="depends_on" />
                        </Grid>
                    </Tooltip>
                    <Tooltip arrow placement="right" title="Informe o valor necessário para que este campo seja exibido. Preencha o campo 'Depende do campo' e adicione aqui o valor correspondente.">
                        <Grid item xs={12}>
                            <InputForm formik={formik} label="Depende do valor" id="value_depends" />
                        </Grid>
                    </Tooltip>
                    {tipoCampo && <Grid item xs={12}>
                        <FormControl error={Boolean(formik.touched["tipo_campo_id"] && formik.errors["tipo_campo_id"])} size='small' fullWidth>
                            <InputLabel id="tipo-campo-label">Tipo de campo</InputLabel>
                            <Select
                                size='small'
                                labelId="tipo-campo-label"
                                id="tipo_campo_id"
                                name="tipo_campo_id"
                                value={formik.values["tipo_campo_id"]}
                                label="Tipo de campo"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                {tipoCampo?.data.map(tipo => (
                                    <MenuItem key={tipo.id} value={tipo.id}>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                            key={tipo.id}
                                        >
                                            <Chip size='small' sx={{ fontSize: 8, textTransform: 'uppercase', m: 0, p: 0, height: 15 }} label={tipo.tipo} />
                                            <Typography sx={{
                                                fontSize: 13,
                                                fontWeight: 400,
                                                textTransform: 'uppercase'
                                            }}>{tipo.nome}</Typography>
                                        </Stack>

                                    </MenuItem>
                                ))}
                            </Select>
                            {Boolean(formik.touched["tipo_campo_id"] && formik.errors["tipo_campo_id"]) && <FormHelperText>{formik.touched["tipo_campo_id"] && formik.errors["tipo_campo_id"]}</FormHelperText>}
                        </FormControl>
                    </Grid>}
                    <Tooltip arrow placement="left" title="Habilite esta opção se você deseja que o campo realize uma pesquisa. Isso pode ser útil para campos como CEP, CPF, CNPJ. Desabilite se não deseja que seja feita essa pesquisa.">
                        <Grid item xs={3}>
                            <ControlledCheckbox formik={formik} id="search" label="Pesquisa" />
                        </Grid>
                    </Tooltip>
                    <Grid item xs={3}>
                        <ControlledCheckbox formik={formik} id="obrigatorio" label="Obrigatório" />
                    </Grid>
                    <Grid item xs={3}>
                        <ControlledCheckbox formik={formik} id="activated" label="Ativo" />
                    </Grid>
                    {formik.values.tipo_campo_id === "bfe3168b-9601-4dc2-954d-b8c0c6e6e6fa" && <Grid item xs={12}>
                        <InpultOptions formik={formik} options={formik.values.options} />
                    </Grid>}
                    {formik.values.search && <Grid item xs={12}>
                        <InpultResultSearch formik={formik} options={formik.values.result_search} />
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={carregando} color='error' onClick={handleClose}>
                    Cancelar
                </Button>
                <Button disabled={carregando} autoFocus onClick={formik.handleSubmit}>
                    Salvar
                </Button>
            </DialogActions>
            <Backdrop
                open={carregando}
                sx={{
                    color: 'common.white',
                    zIndex: theme => theme.zIndex.mobileStepper - 1
                }}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
        </BootstrapDialog>
    );
}

export const InputForm = ({ id, label, type, maxLength, minLength = 0, formik, disabled = false, inputProps }) => {
    const inputRef = React.useRef(null);

    const handleTypographyClick = () => {
        inputRef.current?.focus();
    };

    return (
        <Box>
            <Typography
                sx={{ fontWeight: 500, fontSize: 12, cursor: "default" }}
                onClick={handleTypographyClick}
                component="div"
                role="button"
                tabIndex={-1}
            >
                {label}
            </Typography>
            <TextField
                fullWidth
                size='small'
                variant="outlined"
                id={id}
                name={id}
                type={type ? type : "text"}
                value={formik.values[id]}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={Boolean(formik.touched[id] && formik.errors[id])}
                helperText={(formik.touched[id] && formik.errors[id]) || (minLength && formik.values[id].length < minLength ? `Insira pelo menos ${minLength} caracteres.` : '')}
                disabled={disabled}
                inputRef={inputRef}
            />
        </Box>
    );
};

function InpultOptions({ formik, options = [] }) {


    const novaOpcao = () => formik.setFieldValue("options", [...options, { label: "", value: "" }])

    const apagarOpcao = (array, index) => {
        const newOptions = array.filter((_, i) => i !== index)
        formik.setFieldValue("options", newOptions)
    }

    return (
        <Box >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0.5}
                sx={{ width: "100%" }}
            >
                <Typography
                    sx={{ fontWeight: 500, fontSize: 12, cursor: "default" }}
                    component="div"
                    role="button"
                    tabIndex={-1}
                >
                    Options
                </Typography>
                <Tooltip title="Nova opção.">
                    <IconButton onClick={novaOpcao} size='small' aria-label="novo">
                        <AddCircleOutlineIcon color="primary" sx={{ fontSize: 16 }} />
                    </IconButton>
                </Tooltip>
            </Stack>

            <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
                mt={1}
                sx={{ width: "100%", border: options.length === 0 ? 0 : 1, borderColor: "#D8D8D8", borderRadius: 1, p: 1 }}
            >
                {options.map((item, index, array) => (
                    <CampoOptions setFieldValue={formik.setFieldValue} array={array} apagarOpcao={apagarOpcao} key={index} label={item.label} value={item.value} index={index} />
                ))}
            </Stack>

        </Box>
    )
}

function InpultResultSearch({ formik, options = [] }) {

    const novaOpcao = () => formik.setFieldValue("result_search", [...options, { response: "", campo_preencher: "" }])

    const apagarOpcao = (array, index) => {
        const newOptions = array.filter((_, i) => i !== index)
        formik.setFieldValue("result_search", newOptions)
    }

    return (
        <Box >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0.5}
                sx={{ width: "100%" }}
            >
                <Typography
                    sx={{ fontWeight: 500, fontSize: 12, cursor: "default" }}
                    component="div"
                    role="button"
                    tabIndex={-1}
                >
                    Resultado Pesquisa
                </Typography>
                <Tooltip title="Adicione um campo para preencher">
                    <IconButton onClick={novaOpcao} size='small' aria-label="novo">
                        <AddCircleOutlineIcon color="primary" sx={{ fontSize: 16 }} />
                    </IconButton>
                </Tooltip>
            </Stack>

            <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
                mt={1}
                sx={{ width: "100%", border: options.length === 0 ? 0 : 1, borderColor: "#D8D8D8", borderRadius: 1, p: 1 }}
            >
                {options.map((item, index, array) => (
                    <CampoResultSearch setFieldValue={formik.setFieldValue} array={array} apagarOpcao={apagarOpcao} key={index} label={item.response} value={item.campo_preencher} index={index} />
                ))}
            </Stack>

        </Box>
    )
}

function CampoOptions({ label, value, apagarOpcao, index, array, setFieldValue }) {

    const [editar, setEditar] = React.useState(false);
    const [labelInpult, setLabelInpult] = React.useState(label);
    const [valueInpult, setValueInpult] = React.useState(value);

    const handleEditar = () => setEditar(true)
    const handleApagar = () => apagarOpcao(array, index)
    const handleAlterar = () => {
        array[index] = { label: labelInpult, value: valueInpult };
        setFieldValue('options', array);
        setEditar(false)
    }

    React.useEffect(() => {
        setLabelInpult(label)
        setValueInpult(value)
    }, [label, value, index]);

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            mt={1}
            py={0.2}
            px={0.2}
            bgcolor="rgb(20 50 99 / 4%)"
            sx={{ borderRadius: '16px' }}
        >

            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ width: "100%" }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    px={1}
                    sx={{ borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px', width: "100%" }}
                >
                    <Typography
                        sx={{ fontWeight: 400, fontSize: 10, cursor: "default", color: "#577edb" }}
                        component="div"
                        role="button"
                        tabIndex={-1}
                    >
                        Label:
                    </Typography>
                    <TextField onChange={(event) => {
                        setLabelInpult(event.target.value);
                    }} size='small' variant="standard" value={labelInpult} disabled={!editar} />
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    px={1}
                    sx={{ width: "100%" }}
                >
                    <Typography
                        sx={{ fontWeight: 400, fontSize: 10, cursor: "default", color: "#577edb" }}
                        component="div"
                        role="button"
                        tabIndex={-1}
                    >
                        Value:
                    </Typography>
                    <TextField onChange={(event) => {
                        setValueInpult(event.target.value);
                    }} size='small' variant="standard" value={valueInpult} disabled={!editar} />
                </Stack>
            </Stack>

            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                {editar ? <Tooltip title="Salvar"><IconButton onClick={handleAlterar} size='small' aria-label="Editar">
                    <CheckCircleIcon color='primary' sx={{ fontSize: 16 }} />
                </IconButton></Tooltip> : <Tooltip title="Editar"><IconButton onClick={handleEditar} size='small' aria-label="Editar">
                    <EditIcon sx={{ fontSize: 16 }} />
                </IconButton></Tooltip>}
                <Tooltip title="Apagar"><IconButton onClick={handleApagar} size='small' aria-label="Apagar">
                    <DeleteForeverIcon sx={{ fontSize: 16 }} />
                </IconButton></Tooltip>
            </Stack>

        </Stack>
    )
}

function CampoResultSearch({ label, value, apagarOpcao, index, array, setFieldValue }) {

    const [editar, setEditar] = React.useState(false);
    const [labelInpult, setLabelInpult] = React.useState(label);
    const [valueInpult, setValueInpult] = React.useState(value);

    const handleEditar = () => setEditar(true)
    const handleApagar = () => apagarOpcao(array, index)
    const handleAlterar = () => {
        array[index] = { response: labelInpult, campo_preencher: valueInpult };
        setFieldValue('result_search', array);
        setEditar(false)
    }

    React.useEffect(() => {
        setLabelInpult(label)
        setValueInpult(value)
    }, [label, value, index]);

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            mt={1}
            py={0.2}
            px={0.2}
            bgcolor="rgb(20 50 99 / 4%)"
            sx={{ borderRadius: '16px' }}
        >

            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ width: "100%" }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    px={1}
                    sx={{ borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px', width: "100%" }}
                >
                    <Typography
                        sx={{ fontWeight: 400, fontSize: 10, cursor: "default", color: "#577edb" }}
                        component="div"
                        role="button"
                        tabIndex={-1}
                    >
                        response:
                    </Typography>
                    <TextField onChange={(event) => {
                        setLabelInpult(event.target.value);
                    }} size='small' variant="standard" value={labelInpult} disabled={!editar} />
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    px={1}
                    sx={{ width: "100%" }}
                >
                    <Typography
                        sx={{ fontWeight: 400, fontSize: 10, cursor: "default", color: "#577edb" }}
                        component="div"
                        role="button"
                        tabIndex={-1}
                    >
                        campo_preencher:
                    </Typography>
                    <TextField onChange={(event) => {
                        setValueInpult(event.target.value);
                    }} size='small' variant="standard" value={valueInpult} disabled={!editar} />
                </Stack>
            </Stack>

            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                {editar ? <Tooltip title="Salvar"><IconButton onClick={handleAlterar} size='small' aria-label="Editar">
                    <CheckCircleIcon color='primary' sx={{ fontSize: 16 }} />
                </IconButton></Tooltip> : <Tooltip title="Editar"><IconButton onClick={handleEditar} size='small' aria-label="Editar">
                    <EditIcon sx={{ fontSize: 16 }} />
                </IconButton></Tooltip>}
                <Tooltip title="Apagar"><IconButton onClick={handleApagar} size='small' aria-label="Apagar">
                    <DeleteForeverIcon sx={{ fontSize: 16 }} />
                </IconButton></Tooltip>
            </Stack>

        </Stack>
    )
}