import { useQuery } from "react-query";
import api from "../../service/api";

async function getConfigProfissao(ctx) {
  const [, page] = ctx.queryKey
  const { data } = await api.get('/api/profissao', { params: { page, limit: 20 } })
  return data
}

export default function useFetchGetConfigProfissao(page) {
    return useQuery(['configProfissao', page], getConfigProfissao, { refetchOnWindowFocus: false})
}