import Swal from 'sweetalert2';
import api from "../service/api"
import { useQueryClient } from "react-query";
import useToken from "../providers/useToken";
import { useNavigate } from 'react-router-dom';
// import useUser from '../providers/useUser';
import { processValue } from '../utils/processValue';
import { stringToNumber } from '../utils/stringToNumber';
import { toast } from 'react-toastify';
import moment from "moment/moment";
import { baseURL } from '../components/constants';
import useUser from '../providers/useUser';

const usePlataforma = () => {
    const queryClient = useQueryClient();
    const setToken = useToken((state) => state.setToken)
    const setUser = useToken((state) => state.setUser)
    const setShortIdAdvogado = useToken((state) => state.setShortIdAdvogado)
    const access_token = useToken((state) => state.access_token);
    const user = useUser((state) => state.user)

    let navigate = useNavigate();

    const loginUser = async (values) => {
        try {
            const { email, password } = values
            const params = { type: "user" };
            const { data } = await api.post("/auth/login", { email, password }, { params })
            api.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
            setToken(data.access_token, "/")
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const loginParceiro = async (values) => {
        try {
            const { email, password } = values
            const params = { type: "advogado" };
            const { data } = await api.post("/auth/login", { email, password }, { params })
            if (data.isAccepted) {
                api.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
                setToken(data.access_token, "/login-parceiro")
                setUser(data.user)
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Atenção',
                    text: "Aguarde a confirmação do seu cadastro na plataforma.",
                });
            }

            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const getAccess_tokenAdvogado = async (values) => {
        try {
            const { email, senha } = values
            const params = { type: "advogado" };
            const { data } = await api.post("/auth/login", { email, password: senha }, { params })
            return data.access_token
        } catch (error) {
            throw error;
        }
    }

    const requestCode = async (access_token) => {
        try {
            api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
            const params = { metodo: "whatsapp" };
            await api.get("/auth/requestCode", { params })
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const requestCodeEmail = async (access_token) => {
        try {
            const params = { metodo: "email" };
            api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
            await api.get("/auth/requestCode", { params })
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Email de recuperação enviado com sucesso.",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const forgotPasswordByEmail = async (email, type) => {
        try {
            const params = { email, type };
            const { data } = await api.get("/auth/forgotPasswordByEmail", { params })
            return data
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const passwordRecover = async (values, access_token) => {
        try {
            api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
            await api.post("/auth/password/recover", values)
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Senha alterada com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const loginCliente = async (cpf, dataNascimento, shortIdAdvogado = false) => {
        try {
            const { data } = await api.post("/auth/login/cliente", { cpf, dataNascimento })
            api.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
            setShortIdAdvogado(shortIdAdvogado)
            if (data.isVerifiedCode) {
                setToken(data.access_token, "/login-cliente")
                return
            }
            requestCode(data.access_token)
            navigate("/verificacao-cliente", { replace: true, state: { access_token: data.access_token } });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const verifyCode = async (codigo, access_token) => {
        try {
            api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
            const { data } = await api.post("/auth/login/verifyCode", { codigo })
            if (!data.error) {
                setToken(access_token)
            }
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const addCliente = async (values, idEnderecoReclamante) => {
        try {
            var body = {
                nome: values.nomeReclamante,
                cpf: values.cpfReclamante.replace(/[^0-9]/g, ''),
                dataNascimento: values.dataNascimento,
                dddCelular: stringToNumber(values.dddCelular),
                ddiCelular: stringToNumber(values.ddiCelular),
                telefoneCelular: stringToNumber(values.telefoneCelularReclamante),
                email: values.emailReclamante,
                estadoCivil: values.estadoCivilReclamante,
                idProfissao: values.idProfissao,
                idEndereco: idEnderecoReclamante,
                numeroCasa: values.numeroCasaReclamante,
                complementoEndereco: values.complementoEnderecoReclamante
            }
            const { data } = await api.post("/api/cliente", body)
            return data
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const addEnderecoCliente = async (values) => {
        try {
            var body = {
                cep: values.cepReclamante,
                cidade: values.cidadeReclamante,
                rua: values.ruaReclamante,
                estado: values.estadoReclamante,
                bairro: values.bairroReclamante
            }
            const { data } = await api.post("/api/endereco", body)
            return data
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const addEnderecoEmpresa = async (values) => {
        try {
            var body = {
                cep: values.cepReclamada,
                cidade: values.cidadeReclamada,
                rua: values.ruaReclamada,
                estado: values.estadoReclamada,
                bairro: values.bairroReclamada
            }
            const { data } = await api.post("/api/endereco", body)
            return data
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const addEndereco = async (values) => {
        try {
            var body = {
                cep: values.cep,
                cidade: values.cidade,
                rua: values.rua,
                estado: values.estado,
                bairro: values.bairro
            }
            const { data } = await api.post("/api/endereco", body)
            return data
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const addEmpresa = async (values, idEnderecoReclamada) => {
        try {
            var body = {
                razaoSocial: values.razaoSocialReclamada,
                cnpj: values.cnpjReclamada.replace(/[^0-9]/g, ''),
                telefone: values.telefoneCelularReclamada.replace(/[^0-9]/g, ''),
                idEndereco: idEnderecoReclamada,
                numeroCasa: values.numeroCasaReclamada,
                complementoEndereco: values.complementoEnderecoReclamada,
                email: values.emailReclamada
            }
            const { data } = await api.post("/api/empresa", body)
            return data
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const addPeticao = async (values) => {
        try {
            var body = {
                breveDescricaoFatos: values.breveDescricaoFatos,
                horarioAtendimentoPreferencia: moment(values.horarioAtendimentoPreferencia, 'HH:mm').toISOString(),
                isClienteLocal: values.isLocal,
                informacoesAdicionaisFinais: values.informacoesAdicionaisFinais,
                informacoesAdicionaisJornada: values.informacoesAdicionaisJornada,
                informacoesAdicionaisTrabalho: values.informacoesAdicionaisTrabalho,
                "idEnderecoLocalTrabalho": values.idEnderecoLocalTrabalho,
                "complementoEnderecoLocalTrabalho": values.complementoEnderecoLocalTrabalho,
                "numeroCasaEnderecoLocalTrabalho": values.numeroCasaEnderecoLocalTrabalho,
                "idReclamante": values.idReclamante,
                "nomeReclamante": values.nomeReclamante,
                "idEnderecoReclamante": values.idEnderecoReclamante,
                "cpfReclamante": values.cpfReclamante,
                "estadoCivilReclamante": values.estadoCivilReclamante,
                "idProfissao": values.idProfissao,
                "idEmpresaReclamada": values.idEmpresaReclamada,
                "numeroProcesso": null,
                "cnpjReclamada": values.cnpjReclamada,
                "razaoSocialReclamada": values.razaoSocialReclamada,
                "idEnderecoReclamada": values.idEnderecoReclamada,
                "dataContratacao": values.dataContratacao ? values.dataContratacao : null,
                "contratacaoAssinaturaDivergente": values.contratacaoAssinaturaDivergente,
                "dataAssinatura": processValue(values.dataAssinatura),
                "assinaturaCarteira": values.assinaturaCarteira,
                "funcao": values.funcao,
                "salarioInicial": values.salarioInicial ? values.salarioInicial : null,
                "salarioFinal": values.salarioFinal ? values.salarioFinal : null,
                "dataDispensa": values.dataDispensa ? values.dataDispensa : null,
                "horaEntrada": values.horaEntrada,
                "horaSaida": values.horaSaida,
                "desvioFuncao": values.desvioFuncao,
                "funcaoDiversa": values.funcaoDiversa,
                "remuneracaoFuncaoDiversa": processValue(values.remuneracaoFuncaoDiversa),
                "valorFuncaoDiversa": stringToNumber(values.valorFuncaoDiversa),
                "registroFuncaoDiversa": processValue(values.registroFuncaoDiversa),
                "dataRegistroFuncaoDiversa": processValue(values.dataRegistroFuncaoDiversa),
                "atuacaoAcumuloFuncao": processValue(values.atuacaoAcumuloFuncao),
                "funcaoAcumulada": processValue(values.funcaoAcumulada),
                "recebiaValoresExtra": values.recebiaValoresExtra,
                "valorExtra": stringToNumber(values.valorExtra),
                "intervaloDescansoAlmoco": values.intervaloDescansoAlmoco,
                "tempoIntervalo": processValue(values.tempoIntervalo),
                "horasExtrasHabituais": processValue(values.horasExtrasHabituais),
                "mediaHorasExtrasHabituais": processValue(values.mediaHorasExtrasHabituais),
                "frequenciaHorasExtrasHabituais": stringToNumber(values.frequenciaHorasExtrasHabituais),
                "trabalhoDomingoFolga": values.trabalhoDomingoFolga,
                "frequenciaTrabalhoDomingo": stringToNumber(values.frequenciaTrabalhoDomingo),
                "trabalhoFeriado": values.trabalhoFeriado,
                "frequenciaTrabalhoFeriado": stringToNumber(values.frequenciaTrabalhoFeriado),
                "trabalhoNoturno": values.trabalhoNoturno,
                "mediaHorasTrabalhoNoturno": processValue(values.mediaHorasTrabalhoNoturno),
                "mediaDiasTrabalhoNoturno": stringToNumber(values.mediaDiasTrabalhoNoturno),
                "trabalhoInsalubre": values.trabalhoInsalubre,
                "recebeuEpi": values.recebeuEpi,
                "epiRecebido": processValue(values.epiRecebido),
                "agenteInsalubre": processValue(values.agenteInsalubre),
                "trabalhoPericulosidade": values.trabalhoPericulosidade,
                "recebePericulosidade": values.recebePericulosidade,
                "agentesPericulosos": processValue(values.agentesPericulosos),
                "feriasPeriodoIncorreto": values.feriasPeriodoIncorreto,
                "dataFeriasPeriodoIncorreto": processValue(values.dataFeriasPeriodoIncorreto),
                "dataFeriasPeriodoCorreto": processValue(values.dataFeriasPeriodoCorreto),
                "feriasPagamento": values.feriasPagamento,
                "feriasInicio": processValue(values.feriasInicio),
                "dataFeriasPagamento": processValue(values.dataFeriasPagamento),
                "trabalhoDuranteFerias": values.trabalhoDuranteFerias,
                "trabalhoDuranteFeriasQuantidade": stringToNumber(values.trabalhoDuranteFeriasQuantidade),
                "acidenteTrabalho": values.acidenteTrabalho,
                "emissaoCat": values.emissaoCat,
                "afastamentoBeneficiario": values.afastamentoBeneficiario,
                "danoEstetico": values.danoEstetico,
                "doencaOcupacional": values.doencaOcupacional,
                "doencaOcupacionalAfastamento": processValue(values.doencaOcupacionalAfastamento),
                "assedioMoral": values.assedioMoral,
                "nomeAssediador": processValue(values.nomeAssediador),
                "descricaoFatos": processValue(values.descricaoFatos),
                "assedioSexual": values.assedioSexual,
                "nomeAssediadorSexual": processValue(values.nomeAssediadorSexual),
                "descricaoFatosAssedioSexual": processValue(values.descricaoFatosAssedioSexual),
                "dispensaDiscriminatoria": values.dispensaDiscriminatoria,
                "pagamentoRescisao": values.pagamentoRescisao,
                "dataPagamentoRescisao": processValue(values.dataPagamentoRescisao),
                "entregaDocumentos": values.entregaDocumentos,
                "dataEntregaDocumentos": processValue(values.dataEntregaDocumentos),
                "idDemissaoComumAcordo": processValue(values.idDemissaoComumAcordo),
                "idEscalaTrabalho": values.idEscalaTrabalho ? values.idEscalaTrabalho : null,
                "idInsalubridade": values.idInsalubridade ? values.idInsalubridade : null,
                // "motivoRescisao": processValue(values.motivoRescisao),
                "idAdvogado": processValue(values.idAdvogado)
            }
            await api.post("/api/peticao", body)
            navigate("/")
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Petição salva com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const addAdvogado = async (values, idEndereco) => {
        try {
            var body = {
                nome: values.nome,
                cpf: values.cpf.replace(/[^0-9]/g, ''),
                oab: values.oab,
                email: values.email,
                senha: values.senha,
                idEndereco: idEndereco,
                numeroCasa: values.numeroCasa,
                complementoEndereco: values.complementoEndereco,
                nomeBanco: values.nomeBanco,
                agencia: values.agencia,
                conta: values.conta,
                codigoBanco: values.codigoBanco,
            }
            const { data } = await api.post("/api/advogado", body)
            queryClient.invalidateQueries(['advogado'])
            return data
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            throw error
        }
    }

    const novaContaAdvogado = async (values, idEndereco) => {
        try {
            var body = {
                nome: values.nome,
                cpf: values.cpf.replace(/\D/g, ''),
                oab: values.oab,
                email: values.email,
                senha: values.senha,
                idEndereco: idEndereco,
                numeroCasa: values.numeroCasa,
                complementoEndereco: values.complementoEndereco,
                nomeBanco: values.nomeBanco,
                agencia: values.agencia,
                conta: values.conta,
                codigoBanco: values.codigoBanco,
            }
            const { data } = await api.post("/api/advogado", body)
            return data
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const editAdvogado = async (values, id) => {
        try {
            var body = {
                nome: values.nome,
                cpf: values.cpf.replace(/\D/g, ''),
                oab: values.oab,
                email: values.email,
                senha: values.senha,
                numeroCasa: values.numeroCasa,
                complementoEndereco: values.complementoEndereco,
                nomeBanco: values.nomeBanco,
                agencia: values.agencia,
                conta: values.conta,
                codigoBanco: values.codigoBanco,
                logo: values.logo,
                cor: values.cor
            }
            const { data } = await api.patch("/api/advogado/" + id, body)
            queryClient.invalidateQueries(['advogado'])
            return data
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const changeStatusAdvogado = async (status, id) => {
        try {
            await api.put(`/api/advogado/${status}/${id}`)
            queryClient.invalidateQueries(['advogado'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Advogado editado com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const excluirDemissao = async (id) => {
        try {
            await api.delete(`/api/demissao/${id}`)
            queryClient.invalidateQueries(['configDemissao'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro apagado com sucesso!",
            });
            return
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: "Não foi possível excluir este registro.",
            });
            return
        }
    }

    const criarDemissao = async (opcao) => {
        try {
            var body = { nomeDemissao: opcao }
            await api.post("/api/demissao", body)
            queryClient.invalidateQueries(['configDemissao'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro salvo com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const atualizarDemissao = async (id, value) => {
        try {
            var body = { nomeDemissao: value }
            await api.put(`/api/demissao/${id}`, body)
            queryClient.invalidateQueries(['configDemissao'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro alterado com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const excluirEscalaDeTrabalho = async (id) => {
        try {
            await api.delete(`/api/escala/${id}`)
            queryClient.invalidateQueries(['configEscalaDeTrabalho'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro apagado com sucesso!",
            });
            return
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: "Não foi possível excluir este registro.",
            });
            return
        }
    }

    const criarEscalaDeTrabalho = async (opcao) => {
        try {
            var body = { nomeEscala: opcao }
            await api.post("/api/escala", body)
            queryClient.invalidateQueries(['configEscalaDeTrabalho'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro salvo com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const atualizarEscalaDeTrabalho = async (id, value) => {
        try {
            var body = { nomeEscala: value }
            await api.put(`/api/escala/${id}`, body)
            queryClient.invalidateQueries(['configEscalaDeTrabalho'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro alterado com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const excluirInsalubridade = async (id) => {
        try {
            await api.delete(`/api/insalubridade/${id}`)
            queryClient.invalidateQueries(['configInsalubridade'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro apagado com sucesso!",
            });
            return
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: "Não foi possível excluir este registro.",
            });
            return
        }
    }

    const criarInsalubridade = async (opcao) => {
        try {
            var body = { nomeInsalubridade: opcao }
            await api.post("/api/insalubridade", body)
            queryClient.invalidateQueries(['configInsalubridade'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro salvo com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const atualizarInsalubridade = async (id, value) => {
        try {
            var body = { nomeInsalubridade: value }
            await api.put(`/api/insalubridade/${id}`, body)
            queryClient.invalidateQueries(['configInsalubridade'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro alterado com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const excluirProfissao = async (id) => {
        try {
            await api.delete(`/api/profissao/${id}`)
            queryClient.invalidateQueries(['configProfissao'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro apagado com sucesso!",
            });
            return
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: "Não foi possível excluir este registro.",
            });
            return
        }
    }

    const criarProfissao = async (opcao) => {
        try {
            var body = { nomeProfissao: opcao }
            await api.post("/api/profissao", body)
            queryClient.invalidateQueries(['configProfissao'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro salvo com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const atualizarProfissao = async (id, value) => {
        try {
            var body = { nomeProfissao: value }
            await api.put(`/api/profissao/${id}`, body)
            queryClient.invalidateQueries(['configProfissao'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro alterado com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const excluirEndereco = async (id) => {
        try {
            await api.delete(`/api/endereco/${id}`)
            queryClient.invalidateQueries(['configEndereco'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro apagado com sucesso!",
            });
            return
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: "Não foi possível excluir este registro.",
            });
            return
        }
    }

    const criarEndereco = async (values) => {
        try {
            var body = {
                cep: values.cep,
                cidade: values.cidade,
                rua: values.rua,
                estado: values.estado,
                bairro: values.bairro
            }
            await api.post("/api/endereco", body)
            queryClient.invalidateQueries(['configEndereco'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro salvo com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const atualizarEndereco = async (id, values) => {
        try {
            await api.put(`/api/endereco/${id}`, values)
            queryClient.invalidateQueries(['configEndereco'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro alterado com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const criarUserConfig = async (values) => {
        try {
            var body = {
                nome: values.nome,
                email: values.email,
                cpf: values.cpf.replace(/\D/g, ''),
                senha: values.senha === "" ? null : values.senha
            }
            await api.post("/api/user", body)
            queryClient.invalidateQueries(['configUser'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro salvo com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const editarUserConfig = async (values) => {
        try {
            var body = {
                nome: values.nome,
                email: values.email,
                cpf: values.cpf.replace(/\D/g, ''),
            };

            if (values.senha !== "") {
                body.senha = values.senha
            }

            await api.put(`/api/user/${values.id}`, body);
            queryClient.invalidateQueries(['configUser']);
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro Alterado com sucesso!",
            });
            return;
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return;
        }
    };

    const chanceStatusConfigUser = async (type, id) => {
        try {
            await api.put(`/api/user/${type}/${id}`);
            queryClient.invalidateQueries(['configUser']);
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro Alterado com sucesso!",
            });
            return;
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return;
        }
    };

    const addPermissaoUser = async (idUser, idPermissao) => {
        try {
            var body = {
                id_usuario: idUser,
                permissoes: idPermissao,
                grupo_permissao: []
            }
            const params = { type: "user" };
            await api.post("/api/userPermission", body, { params })
            queryClient.invalidateQueries(['userById']);
            toast.success("Permissão adicionada!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const deletePermissaoUser = async (idUser, idPermissao) => {
        try {
            var body = {
                id_usuario: idUser,
                permissoes: idPermissao,
                grupo_permissao: []
            };

            const config = {
                data: body,
                params: { type: "user" }
            };

            await api.delete("/api/userPermission", config);
            queryClient.invalidateQueries(['userById']);
            toast.success("Permissão removida!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const addPermissaoAdvogado = async (idUser, idPermissao) => {
        try {
            var body = {
                id_usuario: idUser,
                permissoes: idPermissao,
                grupo_permissao: []
            }
            const params = { type: "advogado" };
            await api.post("/api/userPermission", body, { params })
            queryClient.invalidateQueries(['advogado']);
            toast.success("Permissão adicionada!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const deletePermissaoAdvogado = async (idUser, idPermissao) => {
        try {
            var body = {
                id_usuario: idUser,
                permissoes: idPermissao,
                grupo_permissao: []
            };

            const config = {
                data: body,
                params: { type: "advogado" }
            };

            await api.delete("/api/userPermission", config);
            queryClient.invalidateQueries(['advogado']);
            toast.success("Permissão removida!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const deleteCoberturaAtendimento = async (id) => {
        try {
            await api.delete("/api/enderecoCoberturaAtendimento/" + id);
            queryClient.invalidateQueries(['configEnderecoCoberturaAtendimento']);
            toast.success("Registro removido!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const deleteEnderecoFilial = async (id) => {
        try {
            await api.delete("/api/enderecoFilial/" + id);
            queryClient.invalidateQueries(['configEnderecoFilial']);
            toast.success("Registro removido!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const deleteStatusPeticao = async (id) => {
        try {
            await api.delete("/api/status/" + id);
            queryClient.invalidateQueries(['getSatusPeticao']);
            toast.success("Registro removido!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const enderecoFindByCep = async (cep) => {
        try {
            const { data } = await api.get("/api/enderecoFindByCep/" + cep)
            return data
        } catch (error) {
            console.log(error);
            return null
        }
    };

    const addCoberturaAtendimento = async (cep) => {
        try {
            var endereco = await enderecoFindByCep(cep)
            if (!endereco[0]) {
                toast.error("Endereço não encontrado.")
                return
            }
            await api.post("/api/enderecoCoberturaAtendimento", { idEndereco: endereco[0].id })
            queryClient.invalidateQueries(['configEnderecoCoberturaAtendimento']);
            toast.success("Endereço adicionado!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const addEnderecoFilial = async (cep) => {
        try {
            var endereco = await enderecoFindByCep(cep)
            if (!endereco[0]) {
                toast.error("Endereço não encontrado.")
                return
            }
            await api.post("/api/enderecoFilial", { idEndereco: endereco[0].id })
            queryClient.invalidateQueries(['configEnderecoFilial']);
            toast.success("Endereço adicionado!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const verificarCoberturaAtendimento = async (cep) => {
        try {
            const { data } = await api.get("/api/verificarCoberturaAtendimento/" + cep)
            return data.diponibilidade
        } catch (error) {
            return false
        }
    };

    const criarGrupoPermissoes = async (opcao) => {
        try {
            var body = { nome_grupo: opcao }
            await api.post("/api/group", body)
            queryClient.invalidateQueries(['configGrupoPermissoes'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro salvo com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const atualizarGrupoPermissoes = async (id, value) => {
        try {
            var body = { nome_grupo: value }
            await api.put(`/api/group/${id}`, body)
            queryClient.invalidateQueries(['configGrupoPermissoes'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro alterado com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const excluirGrupoPermissoes = async (id) => {
        try {
            await api.delete(`/api/group/${id}`)
            queryClient.invalidateQueries(['configGrupoPermissoes'])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro apagado com sucesso!",
            });
            return
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: "Não foi possível excluir este registro.",
            });
            return
        }
    }

    const addPermissaoGrupo = async (id_grupo, permissoes) => {
        try {
            var body = {
                permissoes,
                id_grupo
            }
            await api.post("/api/groupPermission", body)
            queryClient.invalidateQueries(['permissionsByGroup']);
            toast.success("Permissão adicionada!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const deletePermissaoGrupo = async (id_grupo, idPermissao) => {
        try {
            var permissaoSelecionada = idPermissao[0]
            await api.delete(`/api/groupPermission/${id_grupo}/${permissaoSelecionada}`);
            queryClient.invalidateQueries(['permissionsByGroup']);
            toast.success("Permissão removida!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const addPermissaoGrupoByUser = async (idUser, grupo_permissao, type) => {
        try {
            var body = {
                id_usuario: idUser,
                permissoes: [],
                grupo_permissao
            }
            const params = { type };
            await api.post("/api/userPermission", body, { params })
            if (type === 'user') {
                queryClient.invalidateQueries(['userById']);
            } else {
                queryClient.invalidateQueries(['advogado']);
            }
            toast.success("Permissão adicionada!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const deletePermissaoGrupoByUser = async (idUser, grupo_permissao, type) => {
        try {
            var body = {
                id_usuario: idUser,
                permissoes: [],
                grupo_permissao: []
            };

            const config = {
                data: body,
                params: { type }
            };

            await api.delete("/api/userPermission", config);
            if (type === 'user') {
                queryClient.invalidateQueries(['userById']);
            } else {
                queryClient.invalidateQueries(['advogado']);
            }
            toast.success("Permissão removida!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const addGrupoPermissaoUser = async (idUser, idGrupo) => {
        try {
            var body = {
                id_usuario: idUser,
                permissoes: [],
                grupo_permissao: idGrupo
            }
            const params = { type: "user" };
            await api.post("/api/userPermission", body, { params })
            queryClient.invalidateQueries(['userById']);
            toast.success("Grupo adicionado!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const deleteGrupoPermissaoUser = async (idUser, idGrupo) => {
        try {
            var body = {
                id_usuario: idUser,
                permissoes: [],
                grupo_permissao: idGrupo
            };

            const config = {
                data: body,
                params: { type: "user" }
            };

            await api.delete("/api/userPermission", config);
            queryClient.invalidateQueries(['userById']);
            toast.success("Grupo removido!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const addGrupoPermissaoAdvogado = async (idUser, idGrupo) => {
        try {
            var body = {
                id_usuario: idUser,
                permissoes: [],
                grupo_permissao: idGrupo
            }
            const params = { type: "advogado" };
            await api.post("/api/userPermission", body, { params })
            queryClient.invalidateQueries(['parceiroById']);
            toast.success("Grupo adicionado!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const deleteGrupoPermissaoAdvogado = async (idUser, idGrupo) => {
        try {
            var body = {
                id_usuario: idUser,
                permissoes: [],
                grupo_permissao: idGrupo
            };

            const config = {
                data: body,
                params: { type: "advogado" }
            };

            await api.delete("/api/userPermission", config);
            queryClient.invalidateQueries(['parceiroById']);
            toast.success("Grupo removido!")
            return;
        } catch (error) {
            console.log(error);
            toast.error("Ocorreu um Erro, Tente Novamente!")
            return;
        }
    };

    const changeStatusPeticao = async (idStatus, idPeticao) => {
        try {
            await api.post("/api/statusPeticao", { idStatus, idPeticao });
            queryClient.invalidateQueries(['peticao']);
            toast.success("Status Atualizado!")
            return;
        } catch (error) {
            toast.error(error.response.data.message)
            throw error;
        }
    };

    const deleteStorage = async (id) => {
        try {
            await api.delete(`/api/storage/download/${id}`);
            queryClient.invalidateQueries(['peticaoById']);
            queryClient.invalidateQueries(['comentarios']);
            toast.success("Arquivo apagado!")
            return;
        } catch (error) {
            toast.error("Ocorreu um Erro, Tente Novamente!")
            throw error;
        }
    };

    const baixarArquivo = async (idStorage, nome) => {
        try {
            const response = await api.get(`/api/storage/download/${idStorage}`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = nome;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            return;
        } catch (error) {
            toast.error("Ocorreu um Erro, Tente Novamente!");
        }
    };

    const novoComentario = async ({ idAnexo, isAdmin, isAdvogado, idPeticao, visibilidade, comentario, haveFiles }) => {
        try {

            var body = {
                idPeticao,
                comentario,
                visibilidade: "CLIENTE"
            }

            if (isAdmin) {
                body.visibilidade = visibilidade

            }

            if (idAnexo) {
                body.idAnexo = idAnexo
            }

            if (isAdvogado) {
                body.visibilidade = visibilidade
            }

            const { data } = await api.post("/api/comentarios", body);
            if (!haveFiles) {
                queryClient.invalidateQueries(['comentarios']);
            }
            return data;
        } catch (error) {
            toast.error("Ocorreu um Erro, Tente Novamente!")
            throw error;
        }
    };

    const addStatusColor = async (nome, color) => {
        try {
            await api.post("/api/status", { nome, color });
            queryClient.invalidateQueries(['getSatusPeticao']);
            toast.success("Registro salvo!")
            return;
        } catch (error) {
            toast.error(error.response.data.message)
            throw error;
        }
    };

    const atualizarStatusPeticao = async (id, nome, color) => {
        try {
            var body = { nome, color }
            await api.put(`/api/status/${id}`, body)
            queryClient.invalidateQueries(['getSatusPeticao'])
            toast.success("Registro Alterado!")
            return
        } catch (error) {
            console.log(error)
            toast.error("Ocorreu um Erro, Tente Novamente!")
            throw error;
        }
    }

    const handleUploadComantario = async (fileList, idComentarioAnexo) => {
        try {
            const formData = new FormData();
            fileList.forEach((file) => {
                formData.append('files[]', file);
            });

            const response = await fetch(`${baseURL}/api/storage/upload?idComentarioAnexo=${idComentarioAnexo}`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });

            if (!response.ok) {
                throw new Error('Upload failed.');
            }

            const data = await response.json();
            queryClient.invalidateQueries(['comentarios']);
            return data
        } catch (error) {
            console.error('Erro no upload:', error.message);
            throw error;
        }
    };

    const UploadDocumentoOab = async (fileList, access_token) => {
        try {
            const formData = new FormData();
            fileList.forEach((file) => {
                formData.append('files[]', file);
            });

            const response = await fetch(`${baseURL}/api/storage/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });

            if (!response.ok) {
                throw new Error('Upload failed.');
            }

            const data = await response.json();
            return data
        } catch (error) {
            console.error('Erro no upload:', error.message);
            throw error;
        }
    };

    const userUploadDocumentoOab = async (fileList) => {
        try {
            const formData = new FormData();
            fileList.forEach((file) => {
                formData.append('files[]', file);
            });

            const response = await fetch(`${baseURL}/api/storage/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });

            if (!response.ok) {
                throw new Error('Upload failed.');
            }

            const data = await response.json();
            return data
        } catch (error) {
            console.error('Erro no upload:', error.message);
            throw error;
        }
    };


    const verificarIsLocal = async (cep) => {
        try {
            const { data } = await api.get("/api/verificarClienteLocal/" + cep)
            return data.isLocal
        } catch (error) {
            return false
        }
    };

    const advogadoSaveOab = async (idAdvogado, idOabFile, access_token) => {
        try {
            const headers = {
                Authorization: `Bearer ${access_token}`
            };
            const { data } = await api.post("/api/advogado/saveOab", { idAdvogado, idOabFile }, { headers })
            return data
        } catch (error) {
            throw error;
        }
    };

    const userSaveOab = async (idAdvogado, idOabFile) => {
        try {
            const headers = {
                Authorization: `Bearer ${access_token}`
            };
            const { data } = await api.post("/api/advogado/saveOab", { idAdvogado, idOabFile }, { headers })
            queryClient.invalidateQueries(['advogado'])
            return data
        } catch (error) {
            throw error;
        }
    };

    const getUrlCliente = async (idAdvogado) => {
        try {
            const { data } = await api.get(`/api/advogado/url?idAdvogado=${idAdvogado}`)
            return data
        } catch (error) {
            throw error;
        }
    }

    const postAdiantamento = async (idPeticao, valorSolicitado) => {
        try {
            await api.post("/api/financeiro/adiantamento", { idAdvogado: user.id, idPeticao, valorSolicitado })
            queryClient.invalidateQueries(['listas-adiantamento'])
            return
        } catch (error) {
            throw error;
        }
    };

    const postPagarAdiantamento = async (adiantamentoID, valorPago) => {
        try {
            await api.post(`/api/financeiro/pagar-adiantamentos/${adiantamentoID}`, { valorPago })
            queryClient.invalidateQueries(['listas-adiantamento'])
            return
        } catch (error) {
            throw error;
        }
    };

    const postRecusarAdiantamento = async (adiantamentoID, motivoRecusada) => {
        try {
            await api.post(`/api/financeiro/recusar-adiantamentos/${adiantamentoID}`, { motivoRecusada })
            queryClient.invalidateQueries(['listas-adiantamento'])
            return
        } catch (error) {
            throw error;
        }
    };

    const postAprovarAdiantamento = async (adiantamentoID, dataPrevisaoPagamento) => {
        try {
            await api.post(`/api/financeiro/aprovar-adiantamento/${adiantamentoID}`, { dataPrevisaoPagamento: new Date(dataPrevisaoPagamento) })
            queryClient.invalidateQueries(['listas-adiantamento'])
            return
        } catch (error) {
            throw error;
        }
    };

    const postNotificaoMensagens = async ({ action, type, text, subject }) => {

        var body = {
            action,
            type,
            text,
            subject
        }

        try {
            await api.post("/api/notificao/mensagens", body)
            queryClient.invalidateQueries(['notificao-actions'])
            return
        } catch (error) {
            throw error;
        }
    };

    const editNotificaoMensagens = async ({ mensagemID, action, type, text, subject }) => {

        var body = {
            action,
            type,
            text,
            subject
        }

        try {
            await api.post("/api/notificao/mensagens/" + mensagemID, body)
            queryClient.invalidateQueries(['notificao-actions'])
            return
        } catch (error) {
            throw error;
        }
    };

    const downloadContrato = async ({ idPeticao, idParceiro, path }) => {
        try {

            const response = await api.post(`/api/contratos/${path}`, {
                idPeticao, idParceiro
            }, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = "contrato.docx";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            throw error
        }
    }

    const enviarContratoAssinatura = async (idParceiro) => {
        try {
            await api.post("/api/d4sign/upload/parceiro", { idParceiro })
            toast.success("Contrato enviado!")
        } catch (e) {
            toast.error("Erro ao enviar contrato!")
        }
    }

    const downloadContratoAssinado = async (idContrato) => {
        try {

            const { data } = await api.get(`/api/d4sign/download?idContrato=${idContrato}`, {
                idContrato
            });

            const a = document.createElement("a");
            a.style.display = "none";
            a.href = data.url;
            a.download = "contrato.pdf";
            a.target = "_blank"
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(data.url);

        } catch (error) {
            console.log(error)
            toast.error("Erro ao baixar contrato!")
        }
    }

    const editarResposta = async (data) => {
        try {
            await api.put("/api/formularios/resposta", data)
            queryClient.invalidateQueries(['getFormularioResposta', data.id_resposta])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro alterado com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    const editarPermitirEdicao = async ({ peticao_id, permitir_edicao, id_resposta }) => {
        try {
            await api.put(`/api/peticao/${peticao_id}`, { permitir_edicao })
            queryClient.invalidateQueries(['getFormularioResposta', id_resposta])
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: "Registro alterado com sucesso!",
            });
            return
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response.data.message,
            });
            return
        }
    }

    return {
        loginUser,
        loginParceiro,
        loginCliente,
        verifyCode,
        requestCode,
        addCliente,
        addEnderecoCliente,
        addEnderecoEmpresa,
        addEmpresa,
        addPeticao,
        addEndereco,
        addAdvogado,
        editAdvogado,
        changeStatusAdvogado,
        novaContaAdvogado,
        forgotPasswordByEmail,
        requestCodeEmail,
        passwordRecover,
        excluirDemissao,
        criarDemissao,
        atualizarDemissao,
        excluirEscalaDeTrabalho,
        criarEscalaDeTrabalho,
        atualizarEscalaDeTrabalho,
        excluirInsalubridade,
        criarInsalubridade,
        atualizarInsalubridade,
        excluirProfissao,
        criarProfissao,
        atualizarProfissao,
        excluirEndereco,
        criarEndereco,
        atualizarEndereco,
        criarUserConfig,
        editarUserConfig,
        chanceStatusConfigUser,
        addPermissaoUser,
        deletePermissaoUser,
        addPermissaoAdvogado,
        deletePermissaoAdvogado,
        deleteCoberturaAtendimento,
        addCoberturaAtendimento,
        verificarCoberturaAtendimento,
        criarGrupoPermissoes,
        atualizarGrupoPermissoes,
        excluirGrupoPermissoes,
        addPermissaoGrupo,
        deletePermissaoGrupo,
        addPermissaoGrupoByUser,
        deletePermissaoGrupoByUser,
        addGrupoPermissaoUser,
        deleteGrupoPermissaoUser,
        addGrupoPermissaoAdvogado,
        deleteGrupoPermissaoAdvogado,
        changeStatusPeticao,
        deleteStorage,
        baixarArquivo,
        novoComentario,
        deleteEnderecoFilial,
        addEnderecoFilial,
        addStatusColor,
        deleteStatusPeticao,
        atualizarStatusPeticao,
        handleUploadComantario,
        verificarIsLocal,
        getAccess_tokenAdvogado,
        UploadDocumentoOab,
        advogadoSaveOab,
        userSaveOab,
        userUploadDocumentoOab,
        postAdiantamento,
        postPagarAdiantamento,
        postRecusarAdiantamento,
        postAprovarAdiantamento,
        postNotificaoMensagens,
        editNotificaoMensagens,
        downloadContrato,
        enviarContratoAssinatura,
        downloadContratoAssinado,
        getUrlCliente,
        editarResposta,
        editarPermitirEdicao
    };
}

export default usePlataforma;
