import { useQuery } from "react-query";
import api from "../../service/api";

async function getPeticaoFinanceiro(ctx) {
  const [, page] = ctx.queryKey
  const { data } = await api.get('/api/peticao', { params: { page, limit: 100 } })
  return data
}

export default function useFetchGetPeticaoFinanceiro(page = 1) {
  return useQuery(['peticaoFinanceiro', page], getPeticaoFinanceiro, { refetchOnWindowFocus: false })
}