import * as React from "react";
import {
    Dialog,
    Grid,
    IconButton,
    Pagination,
    Stack,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"
import useFetchGetListPermissoes from "../../queries/listPermissoes";
import List from "@mui/material/List";
import { ListItemPermissao } from "./ListItemPermissao";
import { ListItemPermissaoLoading } from "./ListItemPermissaoLoading";
import useFetchGetPermissionsByGroup from "../../queries/permissionsByGroup";

export const ModalPermissaoGrupo = ({ open, grupoID, funcaoHandleClose, addPermissaoUser, deletePermissaoUser }) => {
    const [page, setPage] = React.useState(1)
    const [permissionsList, setPermissionsList] = React.useState([]);
    const [listaAtualizada, setListaAtualizada] = React.useState(true);
    
    const { data, isLoading } = useFetchGetPermissionsByGroup(grupoID);
    const { data: dataPermissao, isLoading: isLoadingPermissao } = useFetchGetListPermissoes(page);

    const handleClose = () => {
        funcaoHandleClose();
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };


    React.useEffect(() => {
        if (data && dataPermissao) {
          const permissoesAtual = data.map(current => current.permissao);
          const listPermissao = dataPermissao.data.map(current => current);
    
          const newlist = listPermissao.map(current => {
            const isActived = permissoesAtual.some(item => item.id === current.id);
            return { isActived, ...current };
          });
    
          setPermissionsList(newlist);
          setListaAtualizada(false)
        }
      }, [data, dataPermissao]);


    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            onClose={handleClose}
            maxWidth="lg"
            PaperProps={{
                style: {
                    width: "100%",
                },
            }}
        >
            <Grid container p={2}>
                <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "#D8D8D8"  }}>
                    <Stack
                        sx={{ width: "100%" }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        p={1}
                    >
                        <Typography sx={{fontSize: 18, fontWeight: 500}}>Lista de permissões</Typography>
                        <IconButton
                            size="small"
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="Fechar"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>

                </Grid>
                <Grid item p={1} xs={6} sx={{ borderRight: 1, borderColor: "#D8D8D8" }}>
                    <List dense aria-label="dataPermissao">
                        {!isLoadingPermissao && permissionsList.slice(0, 8).map((current) => (
                                <ListItemPermissao
                                    key={current.id}
                                    method={current.method}
                                    title={current.nome_permissao}
                                    isActived={current.isActived}
                                    idPermissao={current.id}
                                    idUser={grupoID}
                                    addPermissaoUser={addPermissaoUser}
                                    deletePermissaoUser={deletePermissaoUser}
                                />
                            ))}
                            {(isLoadingPermissao || isLoading || listaAtualizada) && [1, 2, 3, 4, 5, 6, 7, 8].map(current => (
                            <ListItemPermissaoLoading key={current} />
                        ))}
                    </List>
                </Grid>
                <Grid item p={1} xs={6}>
                    <List dense aria-label="dataPermissaoDesativada">
                        {!isLoadingPermissao && permissionsList.slice(8).map((current) => (
                                <ListItemPermissao
                                    key={current.id}
                                    method={current.method}
                                    title={current.nome_permissao}
                                    isActived={current.isActived}
                                    idPermissao={current.id}
                                    idUser={grupoID}
                                    addPermissaoUser={addPermissaoUser}
                                    deletePermissaoUser={deletePermissaoUser}
                                />
                            ))}
                        {(isLoadingPermissao || isLoading || listaAtualizada) && [1, 2, 3, 4, 5, 6, 7, 8].map(current => (
                            <ListItemPermissaoLoading key={current} />
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12}>
                <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        py={2}
                        sx={{width: '100%'}}
                    >
                        {dataPermissao && <Pagination page={page} onChange={handleChangePage} size="small" count={dataPermissao.totalPages} color="primary" />}
                    </Stack>
                </Grid>
            </Grid>
        </Dialog>
    );
};
