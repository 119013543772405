import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Backdrop, Box, Breadcrumbs, CircularProgress, Grid, LinearProgress, TextField } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import api from '../../service/api';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function FormularioDialogs({ setDialogSettings, dialogSettings }) {
    const queryClient = useQueryClient();
    const [carregando, setCarregando] = React.useState(false);

    const handleClose = () => {
        setDialogSettings(null)
    };

    const formik = useFormik({
        initialValues: {
            descricao: dialogSettings.editar ? dialogSettings.formulario.descricao : "",
        },
        validationSchema: Yup.object({
            descricao: Yup.string().required('Este campo é obrigatório.'),
        }),
        onSubmit: async (values) => {
            try {
                setCarregando(true)

                if (dialogSettings.editar) {

                    await api.put(`/api/form/${dialogSettings.formulario.id}`, {
                        descricao: values.descricao
                    })

                    toast.success("Editado com sucesso!")
                    queryClient.invalidateQueries(['configFormularios[id]'])

                } else {

                    await api.post(`/api/form`, {
                        capa: "0x",
                        descricao: values.descricao
                    })

                    queryClient.invalidateQueries(['configFormularios'])

                    toast.success("Criado com sucesso!")

                }

                setCarregando(false)


                handleClose()

            } catch (error) {
                setCarregando(false)
                console.log(error)
            }
        },
    });

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={Boolean(dialogSettings)}
        >
            {carregando && <LinearProgress />}
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Typography key="1" color="text.primary">
                        {dialogSettings.editar ? "Editar" : "Novo"}
                    </Typography>
                </Breadcrumbs>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid container spacing={2} sx={{ minWidth: 300 }}>
                    <Grid item xs={12}>
                        <InputForm formik={formik} id="descricao" label="Nome" />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={carregando} color='error' onClick={handleClose}>
                    Cancelar
                </Button>
                <Button disabled={carregando} autoFocus onClick={formik.handleSubmit}>
                    Salvar
                </Button>
            </DialogActions>
            <Backdrop
                open={carregando}
                sx={{
                    color: 'common.white',
                    zIndex: theme => theme.zIndex.mobileStepper - 1
                }}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
        </BootstrapDialog>
    );
}

export const InputForm = ({ id, label, type, maxLength, minLength = 0, formik, disabled = false, inputProps }) => {
    const inputRef = React.useRef(null);

    const handleTypographyClick = () => {
        inputRef.current?.focus();
    };

    return (
        <Box>
            <Typography
                sx={{ fontWeight: 500, fontSize: 12, cursor: "default" }}
                onClick={handleTypographyClick}
                component="div"
                role="button"
                tabIndex={-1}
            >
                {label}
            </Typography>
            <TextField
                fullWidth
                size='small'
                variant="outlined"
                id={id}
                name={id}
                type={type ? type : "text"}
                value={formik.values[id]}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={Boolean(formik.touched[id] && formik.errors[id])}
                helperText={(formik.touched[id] && formik.errors[id]) || (minLength && formik.values[id].length < minLength ? `Insira pelo menos ${minLength} caracteres.` : '')}
                disabled={disabled}
                inputRef={inputRef}
            />
        </Box>
    );
};