import React from 'react';
import { Box, Button, Chip, Divider, Grid, Pagination, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { NavBar } from "../components/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import useFetchGetPeticaoById from "../queries/peticaoById";
import moment from "moment/moment";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useUser from "../providers/useUser";
import usePlataforma from "../hooks/usePlataforma";
import { Carregando } from "../components/Carregando";
import { useState } from "react";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ButtonUpload } from '../components/ButtonUpload';
import { typeUser } from '../utils/typeUser';
import useFetchGetComentarios from '../queries/comentarios';
import { CardArquivo } from '../components/CardArquivo';
import useFetchFormularioResposta from '../queries/getFormularioResposta';

export const Comentarios = () => {
    let navigate = useNavigate();

    const handleBack = () => navigate(-1);

    return (
        <Box>
            <NavBar />
            <Grid container spacing={2} p={2}>
                <Grid item xs={12}>
                    <Button onClick={handleBack} startIcon={<KeyboardBackspaceIcon />} variant="contained">VOLTAR</Button>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ border: 1, borderColor: "#D8D8D8", borderRadius: 1 }}>
                        <TelaInfo />
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Box sx={{ border: 1, borderColor: "#D8D8D8", borderRadius: 1 }}>
                        <TelaChat />
                    </Box>
                </Grid>
            </Grid>
            <Box mt={4} />
        </Box >
    );
}

function TelaChat() {
    const { id } = useParams();
    const user = useUser((state) => state.user)
    const type = user ? typeUser(user.type) : null
    const { novoComentario, handleUploadComantario } = usePlataforma()
    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [page, setPage] = React.useState(1);
    const podeVisualizarComponente = useUser((state) => state.podeVisualizarComponente)

    const formik = useFormik({
        initialValues: {
            comentario: "",
            visibilidade: "TODOS"
        },
        validationSchema: Yup.object({
            comentario: Yup.string().required('O comentário é obrigatória')
        }),
        onSubmit: async (values, { resetForm, setFieldValue }) => {
            try {
                setIsLoading(true)
                var haveFiles = fileList.length > 0
                const data = await novoComentario({ ...values, ...type, idPeticao: id, haveFiles })
                if (haveFiles) {
                    await handleUploadComantario(fileList, data.id)
                }
                setIsLoading(false)
                resetForm()
                setFileList([])
                setFieldValue("visibilidade", "TODOS")
            } catch (error) {
                setIsLoading(false)
            }
        },
    });

    const { data, isLoading: isLoadindComents } = useFetchGetComentarios(id, page)

    const handleChangePage = (event, value) => {
        setPage(value);
        window.scrollTo({
            top: 280,
            behavior: 'smooth'
        });
    };


    return (
        <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
                <form onSubmit={formik.handleSubmit}>
                    <Typography sx={{ fontSize: 17, color: "#000", mb: 0.5 }}>Mensagem</Typography>
                    <TextField
                        autoFocus
                        multiline
                        rows={4}
                        fullWidth
                        name="comentario"
                        onChange={formik.handleChange}
                        value={formik.values.comentario}
                        helperText={formik.touched.comentario && formik.errors.comentario}
                        error={Boolean(formik.touched.comentario && formik.errors.comentario)}
                    />

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        spacing={2}
                        mt={2}
                        mb={3}
                    >
                        <ButtonUpload fileList={fileList} setFileList={setFileList} />
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}

                        >
                            {podeVisualizarComponente(["advogado", "user"]) && <FormControl size="small" sx={{ width: 130 }}>
                                <InputLabel id="demo-simple-select-label">mostrar para</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="mostrar para"
                                    name="visibilidade"
                                    onChange={formik.handleChange}
                                    value={formik.values.visibilidade}
                                >
                                    <MenuItem value="TODOS">TODOS</MenuItem>
                                    <MenuItem value="ADVOGADO">ADVOGADO</MenuItem>
                                    <MenuItem value="CLIENTE">CLIENTE</MenuItem>
                                </Select>
                            </FormControl>}
                            <Button type="submit" variant="contained">ENVIAR</Button>
                        </Stack>
                    </Stack>

                </form>
                <Divider />
            </Grid>
            {data && data.data.map((row, i) => (
                <Grid key={row.id} item xs={12} >
                    <Box px={1}>
                        <CaixaMensagemComanterio row={row} />
                    </Box>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    my={2}
                >
                    {!isLoadindComents && data && data.totalPages > 1 && <Pagination color="primary" count={data.totalPages} variant="outlined" shape="rounded" page={page} onChange={handleChangePage} />}
                </Stack>
            </Grid>
            <Grid item xs={12} />
            <Carregando isLoading={isLoading || isLoadindComents} />
        </Grid>
    )
}

function CaixaMensagemComanterio({ row }) {
    const { comentario, createdAt, Arquivos } = row
    const [isLoading, setIsLoading] = useState(false);

    var { nome } = row.advogado ? row.advogado : row.admin ? row.admin : row.cliente

    return (
        <Grid p={2} container sx={{ border: 1, borderColor: "#D8D8D8", borderRadius: 1, bgcolor: "#f8f8f8" }}>
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    width='100%'
                >
                    <Chip size="small" label={nome} />
                    <Typography sx={{ fontSize: 12, color: "#5f5f5f" }}>{createdAt ? moment(createdAt).format("DD/MM/YYYY HH:mm") : "-"}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} py={2}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 14, color: "#5f5f5f" }}>{comentario}</Typography>
            </Grid>
            {Arquivos && Arquivos.length > 0 && <>
                <Grid item xs={12} py={2}>
                    <Divider />
                </Grid>
                {Arquivos.map(arquivo => (
                    <Grid key={arquivo.id} item xs={6}>
                        <CardArquivo setDeleteLoading={setIsLoading} idStorage={arquivo.id} createdAt={arquivo.createdAt} mimeType={arquivo.mimeType} nome={arquivo.nome} />
                    </Grid>
                ))}
            </>}
            <Grid item xs={12}>
                <Carregando isLoading={isLoading} />
            </Grid>
        </Grid>
    )
}

function TelaInfo() {

    const { id } = useParams();
    const { data, isLoading } = useFetchGetPeticaoById(id)
    // const { data, isLoading } = useFetchGetPeticaoById(id)
    var user = data?.data[0]

    return (
        <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 15, color: "#000", fontWeight: 500 }}>Protocolo</Typography>
                <CaixaInfo isLoading={isLoading}>{user && user.protocolo}</CaixaInfo>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 15, color: "#000", fontWeight: 500 }}>Criado em</Typography>
                <CaixaInfo isLoading={isLoading}>{user && moment(user.createdAt).format("DD/MM/YYYY HH:mm")}</CaixaInfo>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 15, color: "#000", fontWeight: 500 }}>Status</Typography>
                <CaixaInfo isLoading={isLoading}>{user && user.status ? user.status.nome : "-"}</CaixaInfo>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 15, color: "#000", fontWeight: 500 }}>Cliente</Typography>
                <CaixaInfo isLoading={isLoading}>{user && user.nomeReclamante}</CaixaInfo>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 15, color: "#000", fontWeight: 500 }}>CPF</Typography>
                <CaixaInfo isLoading={isLoading}>{user && user.cpfReclamante.slice(0, 3) + "." + user.cpfReclamante.slice(3, 6) + "." + user.cpfReclamante.slice(6, 9) + "-" + user.cpfReclamante.slice(9)}</CaixaInfo>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 15, color: "#000", fontWeight: 500 }}>Telefone</Typography>
                <CaixaInfo isLoading={isLoading}>{user && `${user?.reclamante?.dddCelular || "" } ${user?.reclamante?.telefoneCelular || "-"}`}</CaixaInfo>
            </Grid>
        </Grid>
    )
}

function CaixaInfo({ children, isLoading }) {
    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{ borderLeft: 6, borderRadius: 1, bgcolor: "#e3e2e2", borderColor: "#ababab" }} p={1}
        >
            {isLoading ? <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: "100%" }} /> : <Typography sx={{ fontSize: 16, color: "#000" }}> {children} </Typography>}
        </Stack>
    )
}




