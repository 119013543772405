import { useQuery } from "react-query";
import api from "../../service/api";

async function getConfigTipoCampo(ctx) {
  const [, page] = ctx.queryKey
  const { data } = await api.get('/api/formularios/tipoCampo', { params: { limit: 100 } })
  return data
}

export default function useFetchGetConfigTipoCampo(page) {
  return useQuery(['configTipoCampo', page], getConfigTipoCampo, { refetchOnWindowFocus: false })
}