import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/material';
import { StatusParceiro } from './StatusParceiro';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupsIcon from '@mui/icons-material/Groups';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import OutboxIcon from '@mui/icons-material/Outbox';
import ArticleIcon from '@mui/icons-material/Article';
import { StatusContrato } from './StatusContrato';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

export default function TableParceiro({ rows = [], handleDowloadContratoAssinado, handleViewDetalhe, handleAbrirModalPermissao, handleAbrirModalGrupoPermissao, handleDownloadContrato, handleEnviarContratoAssinatura }) {
  return (
    <Table sx={{ width: "100%" }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Aceito</TableCell>
          <TableCell>Ativo</TableCell>
          <TableCell>Contrato</TableCell>
          <TableCell>Nome</TableCell>
          <TableCell>CPF</TableCell>
          <TableCell>Email</TableCell>
          <TableCell align='center'>Ações</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <TableRow
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            hover
          >
            <TableCell><StatusParceiro status={row.isAccepted} /></TableCell>
            <TableCell><StatusParceiro status={row.isActivated} /></TableCell>
            <TableCell><StatusContrato status={row.isContratoAssinado} /></TableCell>
            <TableCell>{row.nome}</TableCell>
            <TableCell>{row.cpf}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0.5}
              >
                <Tooltip title="Ver Detalhe">
                  <IconButton onClick={() => handleViewDetalhe(row)} color='primary'>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Permissões" color='primary'>
                  <IconButton onClick={() => handleAbrirModalPermissao(row.id, row.permissoes)}>
                    <ManageAccountsIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Grupo" color='primary'>
                  <IconButton onClick={() => handleAbrirModalGrupoPermissao(row.id)}>
                    <GroupsIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download Contrato .docx" color='primary'>
                  <IconButton onClick={() => handleDownloadContrato({ idParceiro: row.id, path: "parceiros" })}>
                    <ArticleIcon />
                  </IconButton>
                </Tooltip>
                {row.isContratoAssinado ?
                  <Tooltip title="Download Contrato .pdf" color='primary'>
                    <IconButton onClick={() => handleDowloadContratoAssinado(row.idContrato)}>
                      <SimCardDownloadIcon />
                    </IconButton>
                  </Tooltip>
                  :
                  <Tooltip title="Solicitar assinatura" color='primary'>
                    <IconButton onClick={() => handleEnviarContratoAssinatura(row.id)}>
                      <OutboxIcon />
                    </IconButton>
                  </Tooltip>
                }
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table >
  );
}
